export enum PhoneField {
    CODE = "code",
    NUMBER = "number"
}

export function friendlyNumber(phone: PhoneNumber) {
    const { code, number } = phone;
    return code?.toString() + number;
}

export type PhoneNumber = {
    [PhoneField.CODE]: string,
    [PhoneField.NUMBER]: string
}