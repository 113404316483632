import React from "react";
import FormSelect, { FormSelectProps } from "react-bootstrap/FormSelect"
import { Grade, strGrades } from "../../db/objects/grade";
import { FormLabel } from "./FormLabel";
import { BsPrefixRefForwardingComponent } from "react-bootstrap/helpers";
import FormGroup from "react-bootstrap/FormGroup";
import { mergeClasses } from "@hashimukh/stardust";
import { NONE, None, onSelectCallback } from "./PostControl";

const GradeControl: BsPrefixRefForwardingComponent<"select", GradeControlProps> = ({ 
    className,
    controlClassName, 
    onChange,
    onGradeSelect,
    required,
    ...rest
}) => {

    return <FormGroup className={mergeClasses("mb-3", className)}>
        <FormLabel required={required}>Grade</FormLabel>
        <FormSelect 
            className={controlClassName}
            aria-label="select the class you are studying in"
            onChange={evt => {
                onChange?.(evt);
                onSelectCallback(evt, onGradeSelect);
            }}
            required={required}
            {...rest}
        >
            <option value={NONE} disabled={required}>Select your grade</option>
            {Object.values(Grade).map(grade => <option key={grade} value={grade}>{strGrades[grade]}</option>)}
        </FormSelect>
    </FormGroup>
}

export default GradeControl;

export interface GradeControlProps extends FormSelectProps {
    controlClassName?: string,
    defaultValue?: Grade | None,
    onGradeSelect?: (grade: Grade | undefined) => unknown,
}