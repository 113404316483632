import { FeedbackType, FormDescription, FormFeedbackProps, FormProgress, FormStatus, Link, SimpleForm } from "@hashimukh/stardust";
import { arrayUnion, getDoc, serverTimestamp, setDoc } from "firebase/firestore";
import React, { useCallback, useState } from "react";
import Form from "react-bootstrap/Form";
import { getSubscription, SubscriptionData, SubscriptionField } from "../../db/models/Subscription";
import { DBObject } from "../../db/objects/common";
import { EmailField } from "../../db/objects/email";
import { SubscribtionService } from "../../db/objects/subscriptionServices";
import { Backend } from "../../utils/backend";
import { EmailField as EmailInput } from "./EmailField";

export const Subscribtion: React.FunctionComponent<SubscribtionProps> = ({ services, description, onSubscribed }) => {
	const [address, setAddress] = useState("");
	const [status, setStatus] = useState<FormStatus>(FormStatus.NONE)
	const [feedback, setFeedback] = useState<FormFeedbackProps>();

	const handleChange = useCallback((str: string) => {
		setAddress(str);
	}, []);

	const submit = useCallback(async () => {
		setStatus(FormStatus.SUBMITTING);
		
		const data: DBObject<SubscriptionData> = {
			[SubscriptionField.EMAIL]: { [EmailField.ADDRESS]: address },
		}

		const ref = getSubscription(Backend.HANDOUT, address);
		let _services: SubscribtionService[] | undefined;
		try {
			const existing = await getDoc<SubscriptionData>(ref);
			const subData = existing.data();
			if (existing.exists() && subData) {
				const subServices = subData[SubscriptionField.SERVICES];
				_services = services.filter(v => !subServices?.includes(v));

				if (!_services.length) {
					onSubscribed?.();
					setStatus(FormStatus.SUBMITTED);
					setFeedback({
						type: FeedbackType.INFO,
						message: `Great, you are already subscribed.`,
					});

					return;
				}
			} else {
				data[SubscriptionField.DATE] = serverTimestamp();
			}
		} catch (err) {
			console.warn(`error getting existing subscriptions from user [cause: ${err}]`);
		}

		if (!_services) _services = services;
		data[SubscriptionField.SERVICES] = arrayUnion(..._services);

		try {
			await setDoc(ref, data, { merge: true });
			onSubscribed?.();
			
			setStatus(FormStatus.SUBMITTED);
			setFeedback({
				type: FeedbackType.SUCCESS,
				message: `You've been subscribed successfully. Unsubscribe anytime using the link from our emails. Thanks.`,
			})
		} catch (error) {
			console.error(`error saving subscription [cause: ${error}]`);
			setStatus(FormStatus.NONE);
			setFeedback({
				type: FeedbackType.ERROR,
				message: `Something unexpected has happened. Sorry for the inconvenience. Please try again later.`,
			})
		}
	}, [address, onSubscribed, services]);

	return (
		<Form className="mb-3">
			{description && <FormDescription description={description} />}
			<SimpleForm gap={1} hintRequired={false}>
				<EmailInput 
					defaultValue={address} 
					onEmailChanged={handleChange} 
					disabled={status !== FormStatus.NONE} 
				/>
			</SimpleForm>
			<FormProgress 
				currentPos={0}
				sectionCount={1}
				hideProgressIndicator
				info={<p>Information you provide here will be used according to our <Link href="/policies#newsletter-policies" newTab>newsletter policies</Link>.</p>}
				submitText="Subscribe"
				onSubmit={submit}
				status={status}
				feedback={feedback}
				disableNext={!address.length || status !== FormStatus.NONE}
			/>
		</Form>
	)
}

export interface SubscribtionProps {
	services: SubscribtionService[],
	description?: string | JSX.Element,
	onSubscribed?: () => unknown,
}