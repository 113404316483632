import { performQuery, QueryPerformResult, QueryStatus, useDoc, useQueryLite } from "@hashimukh/firebase-js";
import { Button, Icon, InputField, LazyListProps, ListStatus } from "@hashimukh/stardust";
import { getApp } from "firebase/app";
import { FieldPath, getDocs, getFirestore, limit as limitResults, orderBy, Query, query as queryDB, where } from "firebase/firestore";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Collapse from "react-bootstrap/Collapse";
import Form from "react-bootstrap/Form";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { AppPage, PageMeta } from "./AppPage";
import { BlogPost } from "./BlogPost";
import { MembershipInvitation } from "./components/common/MembershipInvitation";
import { PostList, PostListPlaceholder } from "./components/PostList";
import { BlogPostData, BlogPostField, getBlogPosts } from "./db/models/BlogPost";
import { getUser, UserField } from "./db/models/User";
import { NameField } from "./db/objects/name";
import "./res/styles/blog.scss";
import { Backend } from "./utils/backend";
import { getConfigValue, getFeaturedTopics } from "./utils/configs";
import { useRouter } from "./utils/useRouter";

export function makePostLink(pid: string) {
	return "https://hashimukh.org/blog/" + pid;
}

const metas: PageMeta = {
	title: "Blog - Hashimukh",
	description: "See how Hashimukh is working to empower the underprivileged.",
	image: "https://hashimukhstorage.blob.core.windows.net/public/cover_web_prod_04_1080.JPG",
}

const loadButtonProps: LazyListProps["loadMoreButtonProps"] = {
	className: "mt-3",
}

const LabelAuthor: React.FunctionComponent<{ uid: string | undefined | null }> = ({ uid }) => {
	const ref = useRef(uid ? getUser(uid, getFirestore(getApp(Backend.LOGCAT))) : undefined);
	const { snapshot } = useDoc(ref.current);

	const data = useMemo(() => snapshot?.data(), [snapshot]);
	const name = data?.[UserField.NAME]?.[NameField.DISPLAY];

	return <Collapse in={!!uid && !!name}><div className="mt-n2 mb-2">
		<small><span className="text-muted">Posts by </span>{name}</small>
	</div></Collapse>
}

const BlogPostList: React.FunctionComponent = () => {
	const router = useRouter();

	const featuredTopics = useRef(getFeaturedTopics());
	const limit = useRef(getConfigValue("limit_query_list_item").asNumber());
	const query = useRef<Query<BlogPostData>>(queryDB(getBlogPosts(), orderBy(BlogPostField.PUBLISH_DATE, "desc")));

	const mainQueryResult = useQueryLite(query.current, limit.current);

	// searches will be performed using parameter objects
	const params = useMemo(() => router.getParams(), [router]);
	const author = params.get("author");
	const fields = params.get("fields");
	const rawTopics = params.get("topics");
	const text = params.get("text") || "";

	const topics = useMemo(() => rawTopics?.split(",").slice(0, 10) || [], [rawTopics]);

	// internal tracking of search texts. search is not performed unless pressed explicitly
	const [queryText, setQueryText] = useState(text);
	const [queryStatus, setQueryStatus] = useState<QueryStatus>()
	const [queryDocs, setQueryDocs] = useState<QueryPerformResult<BlogPostData>["docs"]>([]);
	
	// merge result of main query and user driven query
	const status = queryStatus || mainQueryResult.status;
	const snapshots = queryStatus !== undefined ?  queryDocs : mainQueryResult.snapshots;

	const lstate: ListStatus = snapshots.length % limit.current !== 0 || queryDocs.length
		? "complete" 
		: status !== "fetching" 
		? "partial" 
		: "loading";

	const handleQueryChange = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.currentTarget.value;
		setQueryText(value.trim());
	}, []);

	const applyQuery = useCallback((_evt?: React.MouseEvent<HTMLButtonElement, MouseEvent>, str?: string | null, ts?: string[] | null, by?: string | null) => {
		const txt = str === undefined ? queryText : str === null ? "" : str;
		if (txt) params.set("text", txt);
		else params.delete("text");

		const publisher = by === undefined ? author : by === null ? "" : by;
		if (publisher) params.set("author", publisher);
		else params.delete("author");

		if (ts !== undefined) {
			if (ts?.length) params.set("topics", ts.join(","));
			else params.delete("topics");
		}

		router.setParams(params.toString());
	}, [author, params, queryText, router]);

	const handleKeyPress = useCallback((evt: React.KeyboardEvent<HTMLElement>) => {
		switch (evt.key) {
			case "Enter":
				evt.preventDefault();
				if (!evt.repeat) applyQuery();
				break;
			case "Esc":
			case "Escape":
				evt.preventDefault();
				if (!evt.repeat) applyQuery(undefined, null, null, null);
				break;
		}
	}, [applyQuery]);

	const handleTopicsClick = useCallback((evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		const id = evt.currentTarget.getAttribute("data-topic");
		if (!id) return;

		const newTopics = [...topics];

		const index = topics.findIndex(t => t === id);
		if (index !== -1) newTopics.splice(index, 1);
		else newTopics.push(id);

		applyQuery(undefined, undefined, newTopics);
	}, [topics, applyQuery]);

	useEffect(() => {
		setQueryDocs([]);

		if (!text && !topics.length && !author) {
			setQueryStatus(undefined)
			return;
		}
		setQueryStatus("fetching");

		let baseQuery = getBlogPosts(author);
		const strFields: (string | FieldPath)[] = [];
		const arrFields: (string | FieldPath)[] = [];

		if (topics.length) {
			baseQuery = queryDB(baseQuery, where(BlogPostField.TOPICS, "array-contains-any", topics));
		}

		if (fields) {
			const segments = fields.split(",");
			segments.forEach(segment => {
				switch (segment) {
					case "topic": arrFields.push(BlogPostField.TOPICS); break;
					case "publish_date": strFields.push(BlogPostField.PUBLISH_DATE); break;
					case "id": strFields.push(BlogPostField.ID); break;
					case "heading": strFields.push(BlogPostField.HEADLINE); break;
					case "subheading": strFields.push(BlogPostField.SUBHEADLINE); break;

					default: console.warn(`invalide query constraint: ${segment}`); return;
				}
			})
		}

		if (text && !strFields.length && !arrFields.length) {
			strFields.push(BlogPostField.HEADLINE, BlogPostField.SUBHEADLINE, BlogPostField.ID);
			arrFields.push(BlogPostField.SEARCH_TERMS, BlogPostField.TOPICS);
		}

		if (topics.length) arrFields.splice(0, arrFields.length); // can not contain multiple 'array-contains-any'

		const fetch = text ? performQuery(text, { base: baseQuery, stringFields: strFields, arrayFields: arrFields }, 4) 
			: getDocs(queryDB(baseQuery, limitResults(limit.current)));

		fetch.then(res => {
			setQueryDocs(res.docs);
			setQueryStatus("listening");
		}).catch(err => {
			console.info(`text: ${text}; fields: ${fields}; topics: ${topics}`);
			console.error(`error performing query [cause: ${err}]`);
			
			setQueryDocs([]);
			setQueryStatus("failed");
		})
	}, [text, fields, topics, author]);

	useEffect(() => {
		if (!queryText) applyQuery(undefined, null);
	}, [applyQuery, queryText, rawTopics]);

	useEffect(() => {
		featuredTopics.current = Array.from(new Set([...featuredTopics.current, ...topics]));
	}, [topics]);

	return <AppPage
		heading="Latest" 
		subheading="Stories, works, updates and everything from Hashimukh."
		metas={metas}
	>
		<LabelAuthor uid={author} />
		<Form>
			{featuredTopics.current && <div className="d-flex flex-wrap mt-n1 mb-3">{featuredTopics.current?.map((topic) => {
				const selected = topics.includes(topic)
				return <Button 
					key={topic} 
					className="badge mt-1 me-2 d-inline-flex text-uppercase"
					variant={selected ? "danger" : "secondary"}
					onClick={handleTopicsClick}
					data-topic={topic}
				>
					{selected && <Icon className="ic-topic me-1" name="clear" />}
					{topic}
				</Button>
			})}</div>}
			<InputField 
				controlId="blog_search_input"
				className="mb-4"
				type="text"
				placeholder="Search posts..."
				maxLength={55}
				defaultValue={text}
				onChange={handleQueryChange}
				onKeyDown={handleKeyPress}
				append={<Button 
					variant="outline-secondary" 
					onClick={applyQuery} 
					disabled={!queryText}
				>
					Search
				</Button>}
			/>
		</Form>
		{(snapshots.length && <PostList 
			posts={snapshots} 
			status={lstate} 
			onLoadMore={mainQueryResult.fetchNext} 
			loadMoreButtonProps={loadButtonProps} 
		/>) || (status === "fetching" && <PostListPlaceholder 
			count={limit.current} 
			status="complete"
			loadMoreButtonProps={loadButtonProps}
		/>) || (status === "failed" && <Alert 
			variant="danger"
		>
			Oops! Something went wrong. Please try again later.
		</Alert>) || <Alert variant="info">{text || topics.length || author
			? "We couldn't find anything relevant. Try searching with different words or spellings." 
			: "We're all cleared for now!"}</Alert>}
		{snapshots.length > 0 && <MembershipInvitation />}
	</AppPage>
}

export const Blog: React.FunctionComponent = () => {
	const { path } = useRouteMatch();
	
	return <Switch>
		<Route exact path={path} render={() => <BlogPostList />} />
		<Route exact path={`${path}/:postId`} render={() => <BlogPost />}/>
	</Switch>
}