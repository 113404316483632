import { onEmulator } from "@hashimukh/core-js";
import { ConfigData as BaseConfigData, ConfigParameter } from "@hashimukh/firebase-js";
import { hookConfig, initHooks } from "@hashimukh/react-hooks";
import { getApp } from "firebase/app";
import { fetchAndActivate, getRemoteConfig as get, getValue, RemoteConfig, Value, ValueSource } from "firebase/remote-config";
import { Backend } from "./backend";

export const DEF_QUERY_LIMIT = 6;

let hasInit = false;
let activateCallbacks: ((config: RemoteConfig) => void)[] | null = [];

class ValueMocker implements Value {
	private actual: unknown;

	constructor(actual: unknown) {
		console.debug(`mocking value [actual: ${actual}]`);
		this.actual = actual;
	}

	asBoolean(): boolean {
		return (["1", true, "true", "t", "yes", "y", "on"] as unknown[]).includes(this.actual);
	}

	asNumber(): number {
		return Number(this.actual) || 0;
	}

	asString(): string {
		return `${this.actual}`;
	}
	
	getSource(): ValueSource {
		return "default";
	}

}

const defaultData: ConfigData = {
	cover_images_prod: JSON.stringify(hookConfig.coverPhotos.list),
	query_resolve_delay_millis: 500,
	theme_dynamic: false,
	limit_query_list_item: DEF_QUERY_LIMIT,
	accept_new_membership: true,
	featured_topics: `["winter","self reliant","meal"]`
};

function hasActivated() {
	return !activateCallbacks;
}

export function getRemoteConfig() {
	return get(getApp(Backend.LOGCAT));
}

export function getConfigValue(key: ConfigParam): Value {
	return !onEmulator && hasActivated() ? getValue(getRemoteConfig(), key) : new ValueMocker(defaultData[key]);
}

export function getFeaturedTopics(): string[] {
	const strTopics = getConfigValue("featured_topics").asString();
	return JSON.parse(strTopics);
}

export async function initRemoteConfig() {
	const config = getRemoteConfig();

	if (hasActivated()) return config;
	if (hasInit) {
		console.debug(`awaiting config activate from another call`);
		await new Promise(res => activateCallbacks?.push(res));
		return config;
	}
	hasInit = true;

	config.defaultConfig = defaultData;

	try {
		await fetchAndActivate(config);
	} catch (error) {
		console.error(`error initializing remote config. continuing with default data [cause: ${error}]`);
	}
	
	initHooks({
		coverPhotos: {
			list: JSON.parse(getConfigValue(ConfigParameter.COVER_IMAGES).asString()),
		},
	});
	
	const callbacks = activateCallbacks;
	activateCallbacks = null;
	callbacks?.forEach(callback => callback(config));

	return config;
}

export type ConfigParam = ConfigParameter | "limit_query_list_item" | "accept_new_membership" | "featured_topics";

export type ConfigData = BaseConfigData<ConfigParam> & {
    limit_query_list_item: number,
}