import { useDoc } from "@hashimukh/firebase-js";
import { ParsedToken, User } from "firebase/auth";
import { useEffect, useMemo, useState } from "react";
import { getUserClaims } from "../db/models/UserMetadata";

const KEY_CURRENT_VERSION_CLAIMS = "version_user_claims";

function makeRefreshVersion(uid: string, version: number) {
	return `${uid}_${version.toString().padStart(2, "0")}`;
}

export const useUserClaims = (user: User | null) => {
	const metadataRef = useMemo(() => user?.uid ? getUserClaims(user.uid) : undefined, [user?.uid]);
	const metadata = useDoc(metadataRef);

	const [claims, setClaims] = useState<UserClaims>();
	
	const refreshVersion = metadata.snapshot?.data()?.version;
	useEffect(() => {
		if (user) {
			const currentVersion = localStorage.getItem(KEY_CURRENT_VERSION_CLAIMS) || makeRefreshVersion(user.uid, 0);
			const requestedVersion = makeRefreshVersion(user.uid, refreshVersion || 0);

			const upgrade = !currentVersion?.startsWith(user.uid) || currentVersion < requestedVersion;
			console.debug(`refreshing user claims [force: ${upgrade}]`);

			user.getIdTokenResult(upgrade).then(result => {
				setClaims(result.claims);
			}).catch(err => {
				console.error(`error getting user claims [cause: ${err}]`);
			});

			if (upgrade) localStorage.setItem(KEY_CURRENT_VERSION_CLAIMS, requestedVersion);
		}

		return () => setClaims(undefined);
	}, [user, refreshVersion]);
		
	return claims;
}

export interface UserClaims extends ParsedToken {
	mid?: string,
	scopes?: string[],
}