import { isValidEmail } from "@hashimukh/core-js";
import { InputField, InputFieldProps } from "@hashimukh/stardust";
import React, { useCallback, useMemo, useState } from "react";
import { BsPrefixRefForwardingComponent } from "react-bootstrap/helpers";

export const EmailField: BsPrefixRefForwardingComponent<"input", EmailFieldProps> = (props) => {
	const {
		value: _value,
		defaultValue,
		onChange,
		onEmailChanged,
		...rest
	} = props;

	const [value, setValue] = useState(defaultValue?.toString() || "");
	const effectiveValue = _value !== undefined ? _value : value;

	const error = useMemo(() => {
		if (!effectiveValue.length && !props.required) return undefined;
		return !isValidEmail(effectiveValue) && "Invalid email address";
	}, [effectiveValue, props.required]);

	const handleChange = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
		const input = evt.currentTarget.value;
		setValue(input);
		
		const trimmed = input.trim();
		if (isValidEmail(trimmed)) onEmailChanged?.(trimmed);
		else onEmailChanged?.("");

		onChange?.(evt);
	}, [onChange, onEmailChanged]);

	return <InputField
		type="email"
		label="Email address"
		value={effectiveValue}
		errorMessage={error}
		onChange={handleChange}
		{...rest}
	/>
}

export interface EmailFieldProps extends Omit<InputFieldProps, "type" | "value" | "defaultValue"> {
	value?: string,
	defaultValue?: string,
	onEmailChanged?: (addreess: string) => unknown,
}