import React from "react";
import { mergeClasses } from "@hashimukh/stardust";
import { BsPrefixRefForwardingComponent } from "react-bootstrap/helpers";
import FormControl, { FormControlProps } from "react-bootstrap/FormControl";
import FormGroup from "react-bootstrap/FormGroup";
import { predefinedFieldOfStudies } from "../../db/objects/fieldOfStudy";
import { FormLabel } from "./FormLabel";

const StudyFieldControl: BsPrefixRefForwardingComponent<"input", StudyFieldControlProps> = ({
    className,
    controlClassName,
    controlId,
    label = "Field of Study",
    required,
    ...rest
}) => {
    return <FormGroup className={mergeClasses("mb-3", className)} controlId={controlId}>
        <FormLabel required={required}>{label}</FormLabel>
        <FormControl 
            className={mergeClasses(controlClassName)} 
            type="text"
            list="field-of-study-options"
            {...rest} 
        />
        <datalist id="field-of-study-options">
            {predefinedFieldOfStudies.map(field => <option key={field} value={field}/>)}
        </datalist>
    </FormGroup>
}

export default StudyFieldControl;

export interface StudyFieldControlProps extends FormControlProps {
    label?: string,
    required?: boolean,
    controlClassName?: string,
    controlId?: string,
}