import { RouteContextInterface } from "@hashimukh/stardust";
import { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

export const useRouter = (): Router => {
	const { push, goBack } = useHistory();
	const { pathname, search, state } = useLocation();
	
	return useMemo(() => ({
		push: push,
		back: goBack,
		getParams: () => new URLSearchParams(search),
		setParams: (params) => push({ search: params }),
		pathname: pathname,
		query: search,
		state: state,
	}), [goBack, pathname, search, state, push]);
}

export interface Router extends RouteContextInterface {
	push: (href: string, state?: unknown) => unknown,
	back: () => void,
	getParams: () => URLSearchParams,
	setParams: (params: string | "" | undefined) => void,
	query: string,
	state: unknown,
}