import { FieldValue } from "firebase/firestore";

export enum RegistrationMethod {
    ONLINE = "WEB_APP",
    OFFLINE = "OFFLINE",
}

export function transformValue<T>(value: T | undefined): T extends FieldValue ? null : (T | undefined) {
    return value instanceof FieldValue ? null : value as any;
}

export interface DocumentMatch {
    id: string,
    data: any
}
export type DBObject<T> = {
	[K in keyof T]?: (Record<string, unknown> extends T[K] 
        ? DBObject<T[K]>
        : T[K]) 
    | FieldValue
}

export type PrivacyOption = "public" | "restricted"