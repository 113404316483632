import { queryToHandout, Region } from '@hashimukh/firebase-js';
import { getApp } from 'firebase/app';
import { collection, doc, getFirestore, runTransaction, serverTimestamp, Transaction, writeBatch } from 'firebase/firestore';
import { deleteObject, getStorage, list, ref as storageRef, uploadBytes, UploadResult } from 'firebase/storage';
import { Backend } from '../../../utils/backend';
import { GS_PREFIX, HandoutBucket } from '../../../utils/bucket';
import { makeId } from '../../../utils/ids';
import { AvatarSize, generateAvatarName, MEMBER_CURRENT_AVATAR_DIR } from '../../objects/avatar';
import { DBObject, RegistrationMethod, transformValue } from '../../objects/common';
import { PhoneField } from '../../objects/phoneNumber';
import { Post } from '../../objects/post';
import { Institution } from '../Institution';
import { lockOperation, OperationScope } from '../Lock';
import { MemberAcademicData, MemberAcademicField, MEMBER_ACADEMIC_DOC_ID } from './Academic';
import { MemberContactData, MemberContactField, MEMBER_CONTACT_DOC_ID } from './Contact';
import { MemberPersonalData, MemberPersonalField, MEMBER_PERSONAL_DOC_ID } from './Personal';
import { MemberPublicData, MemberPublicField } from './Public';

export const MEMBER_STORAGE_ID = "member";
export const MEMBER_COLLECTION_ID = "members";
export const MEMBERS_RESTRICTED_INFO_COLLECTION_GROUP_ID = "members-restricted-info";

export const MIN_LEN_MID = 6;
export const MAX_LEN_MID = 127;

export const MAX_SIZE_AVATAR = 25 * 1024 * 1024;

const ERROR_CREATE_ID_COLLISION = "mid-collision";
const ERROR_CREATE_EXHAUSTED = "exhausted";

export enum MemberSnapshotField {
    ID = "id",
    NAME = "name",
    POST = "post"
}

export function makeMID(name?: string | null, hash?: number): string {
    return makeId(MIN_LEN_MID, name, hash);
}

export function uploadMemberAvatar<T extends Blob | undefined>(mid: string, avatar: T): T extends Blob ? Promise<UploadResult> : undefined {
	let avatarName: string | undefined;
	if (!avatar) {
		return undefined as any;
	} else {
		avatarName = generateAvatarName(avatar);
	}

    const avatars = Member.getAvatars();
	const uploadPath = storageRef(avatars, `${MEMBER_STORAGE_ID}/${mid}/${MEMBER_CURRENT_AVATAR_DIR}/${avatarName}`);
	
    return uploadBytes(uploadPath, avatar) as any;
}

export async function removeMemberAvatar(mid: string) {
    const avatars = Member.getAvatars();
	const ref = storageRef(avatars, `${MEMBER_STORAGE_ID}/${mid}/${MEMBER_CURRENT_AVATAR_DIR}`);
    
    const res = await list(ref, { maxResults: 30 });
    const queues = res.items.map(value => {
        console.debug(`deleting [path: ${value.fullPath}]`);
        return deleteObject(value);
    });

    return Promise.all(queues);
}

let fieldProps: Record<MemberField, FieldProps | undefined>;
function getFieldProps(): NonNullable<typeof fieldProps> {
    if (fieldProps) return fieldProps;

    const publicFields = Object.values(MemberPublicField);
    const personalFields = Object.values(MemberPersonalField);
    const contactFields = Object.values(MemberContactField);
    const academicFields = Object.values(MemberAcademicField);

    fieldProps = { } as typeof fieldProps;
    publicFields.forEach(field => fieldProps[field] = { parent: "doc_public" });
    personalFields.forEach(field => fieldProps[field] = { parent: "doc_personal" });
    contactFields.forEach(field => fieldProps[field] = { parent: "doc_contact" });
    academicFields.forEach(field => fieldProps[field] = { parent: "doc_academic" });

    return fieldProps;
}

export class Member {
    private db = Member.getDB();
    private members = collection(this.db, MEMBER_COLLECTION_ID);

    publicData: DBObject<MemberPublicData> = { };
    personalData: DBObject<MemberPersonalData> = { };
    contactData: DBObject<MemberContactData> = { };
    academicData: DBObject<MemberAcademicData> = { };

    public setName = (value?: MemberFieldValue<MemberPublicField.NAME>) => {
        this.set(MemberPublicField.NAME, value);
    }

    public static getDB() {
        return getFirestore(getApp(Backend.HANDOUT));
    }

    public static getCollection() {
        return collection(this.getDB(), MEMBER_COLLECTION_ID);
    }

    public static createRef(mid: string) {
        return doc(this.getCollection(), mid);
    }

    public static getAvatars() {
        return getStorage(getApp(Backend.HANDOUT), GS_PREFIX + HandoutBucket.AVATARS);
    }

    public static getAvatar(mid: string, size: AvatarSize) {
        return storageRef(this.getAvatars(), `${MEMBER_STORAGE_ID}/${mid}/${MEMBER_CURRENT_AVATAR_DIR}/avatar_${size}.jpeg`);
    }

    public set<K extends MemberField>(field: K, value?: MemberFieldValue<K>) {
        const parent = this.getParent(field);
        this.setOrDel(parent, field, value);
    }

    public get<K extends MemberField>(field: K): MemberFieldValue<K> | undefined {
        const parent = this.getParent(field);
        return parent?.[field];
    }

    private setOrDel<T, K extends keyof T>(parent: T, field: K, value: T[K] | undefined) {
        if (value !== undefined && value !== null) parent[field] = value;
        else delete parent[field];
    }

    getParent(field: MemberField): any {
        const props = getFieldProps()[field];
        if (!props) return undefined;

        switch (props.parent) {
            case "doc_public": return this.publicData;
            case "doc_personal": return this.personalData;
            case "doc_contact": return this.contactData;
            case "doc_academic": return this.academicData;
            default: return undefined;
        }
    }

    public reset() {
        this.publicData = { };
        this.personalData = { };
        this.contactData = { };
        this.academicData = { };
    }

    prepareTransaction(uid: string, uniqueLevel?: number): (t: Transaction) => Promise<MemberCreateResult> {
        return async (t) => {
            const displayName = transformValue(this.get(MemberPublicField.NAME));

            const mid = makeMID(displayName, uniqueLevel);
            
            const docRef = doc(this.members, mid);
            const existing = await t.get(docRef);

            if (existing && existing.exists()) {
                console.debug(`Commit failed [cause: mid collision detected]`);
                return Promise.reject(ERROR_CREATE_ID_COLLISION);
            }

            lockOperation(uid, OperationScope.MEMBER, this.db, t);

            t.set(docRef, this.publicData);
            t.set(doc(collection(docRef, MEMBERS_RESTRICTED_INFO_COLLECTION_GROUP_ID), MEMBER_PERSONAL_DOC_ID), this.personalData);
            t.set(doc(collection(docRef, MEMBERS_RESTRICTED_INFO_COLLECTION_GROUP_ID), MEMBER_CONTACT_DOC_ID), this.contactData);
            t.set(doc(collection(docRef, MEMBERS_RESTRICTED_INFO_COLLECTION_GROUP_ID), MEMBER_ACADEMIC_DOC_ID), this.academicData);
            
            return {
                alreadyExists: false,
                id: mid,
            }
        }
    }

    public async apply(mid: string) {
        if (!Object.keys(this.publicData).length && 
            !Object.keys(this.personalData).length && 
            !Object.keys(this.contactData).length &&
            !Object.keys(this.academicData).length) {

                return;
        }

        const ref = doc(this.members, mid);
        const personalRef = doc(ref, MEMBERS_RESTRICTED_INFO_COLLECTION_GROUP_ID, MEMBER_PERSONAL_DOC_ID);
        const contactRef = doc(ref, MEMBERS_RESTRICTED_INFO_COLLECTION_GROUP_ID, MEMBER_CONTACT_DOC_ID);
        const academicRef = doc(ref, MEMBERS_RESTRICTED_INFO_COLLECTION_GROUP_ID, MEMBER_ACADEMIC_DOC_ID);

        const school = transformValue(this.get(MemberAcademicField.INSTITUTION));
        if (school) {
            try {
                await Institution.ensure(school);
            } catch (error) {
                throw new Error(`couldn't ensure institution [cause: ${error}]`);
            }
        }

        const batch = writeBatch(Member.getDB());
        batch.set(ref, this.publicData, { merge: true });
        batch.set(personalRef, this.personalData, { merge: true });
        batch.set(contactRef, this.contactData, { merge: true });
        batch.set(academicRef, this.academicData, { merge: true });

        return batch.commit();
    }

    public async create(uid: string): Promise<MemberCreateResult> {
        this.set(MemberPublicField.JOIN_TIME, serverTimestamp());
        this.set(MemberPublicField.IS_VERIFIED, false);
        this.set(MemberPublicField.REG_METHOD, RegistrationMethod.ONLINE);

        [this.publicData, this.personalData, this.contactData, this.academicData]
            .forEach(data => data[MemberPublicField.UID] = uid);

        const institutionData = transformValue(this.get(MemberAcademicField.INSTITUTION));
        if (institutionData) {
            try {
                await Institution.ensure(institutionData);
            } catch (error) {
                throw new Error(`couldn't ensure institution [cause: ${error}]`);
            }
        }

        const phone = transformValue(this.get(MemberContactField.PHONE));
        if (phone) {
            try {
                const code = encodeURIComponent(phone[PhoneField.CODE]);
                const number = encodeURIComponent(phone[PhoneField.NUMBER]);
                const res = await queryToHandout(`query-v1_asia/members/?use=phone&code=${code}&number=${number}`, Region.HONG_KONG);
                if (res.ok) {
                    const content = JSON.parse(await res.text());
                    return {
                        alreadyExists: true,
                        id: content.id,
                    }
                } else if (res.status !== 404) {
                    return Promise.reject(`Unknown status when checking if already exists: ${res.statusText}`);
                }
            } catch (error) {
                return Promise.reject(`Error checking if already exists: ${error}`)
            }
        }

        let result: MemberCreateResult | undefined;
        let attempt = 0;
        do {
            try {
                result = await runTransaction(this.db, this.prepareTransaction(uid, attempt === 0 ? 0 : Math.ceil(attempt / 2)));
            } catch (err) {
                if (err !== ERROR_CREATE_ID_COLLISION) throw err;
            }

            attempt++;
        } while (result === undefined && attempt <= 6);

        return result ? result : Promise.reject(ERROR_CREATE_EXHAUSTED);
    }
}

export type MemberFieldValue<K extends string> = K extends keyof MemberPublicData ? DBObject<MemberPublicData>[K] 
    : K extends keyof MemberPersonalData ? DBObject<MemberPersonalData>[K]
    : K extends keyof MemberContactData ? DBObject<MemberContactData>[K]
    : K extends keyof MemberAcademicData ? DBObject<MemberAcademicData>[K]
    : never;

type MemberField = MemberPublicField | MemberPersonalField | MemberContactField | MemberAcademicField;

interface FieldProps {
    parent: "doc_public" | "doc_personal" | "doc_contact" | "doc_academic" | MemberField,
}

export type MemberData = MemberPublicData | MemberPersonalData | MemberContactData | MemberAcademicData;

export interface MemberSnapshotData {
    [MemberSnapshotField.ID]?: string,
    [MemberSnapshotField.NAME]?: string,
    [MemberSnapshotField.POST]?: Post,
}

export interface MemberCreateResult {
    alreadyExists: boolean,
    id: string
}