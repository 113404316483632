import { getApp } from "firebase/app";
import { collection, CollectionReference, doc, getFirestore, Timestamp } from "firebase/firestore";
import { v5 as v5UUID } from "uuid";
import { Backend } from "../../utils/backend";
import { Email } from "../objects/email";
import { SubscribtionService } from "../objects/subscriptionServices";

export const NAMESPACE_SUBSCRIPTION = "57e7589e-d3ad-46e6-9c67-e8535f82efa4";

export const SUBSCRIPTION_COLLECTION_ID = "subscriptions";

export function getSubscriptions(backend: Backend): CollectionReference<SubscriptionData> {
	return collection(getFirestore(getApp(backend)), SUBSCRIPTION_COLLECTION_ID);
}

export function getSubscriptionById(backend: Backend, id: string) {
	return doc(getSubscriptions(backend), id);
}

export function getSubscription(backend: Backend, emailAddress: string) {
	const id = v5UUID(emailAddress, NAMESPACE_SUBSCRIPTION);
	return getSubscriptionById(backend, id);
}

export enum SubscriptionField {
	SERVICES = "services",
	EMAIL = "email",
	DATE = "subscribe_date",
}

export interface SubscriptionData {
	[SubscriptionField.SERVICES]?: SubscribtionService[],
	[SubscriptionField.EMAIL]?: Email,
	[SubscriptionField.DATE]?: Timestamp,
}