import { Icon, mergeClasses } from "@hashimukh/stardust";
import { fromStream } from "file-type/browser";
import Reducer from "image-blob-reduce";
import Pica from "pica";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import AvatarEditor, { AvatarEditorProps, Position } from "react-avatar-editor";
import Button, { ButtonProps } from "react-bootstrap/Button";
import DropdownItem, { DropdownItemProps } from "react-bootstrap/DropdownItem";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import FormRange from "react-bootstrap/FormRange";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Spinner from "react-bootstrap/Spinner";
import SplitButton from "react-bootstrap/SplitButton";
import Stack from "react-bootstrap/Stack";
import "../../res/styles/photo-editor.scss";
import { getImageDimension } from "../../utils/files";
import { Loading } from "./Loading";

const THRESHOLD_REDUCE = 1024 * 1024 * 2;

const DEF_SCALE = 100;
const DEF_COOR_X = .5;
const DEF_COOR_Y = .5;
const DEF_ROTATION = 0;

let reducer: Reducer;

function getReducer() {
    if (!reducer) {
        const pica = new Pica({ features: ["js", "wasm", "cib"] });
        reducer = new Reducer({ pica: pica });
    }

    return reducer;
}

export async function reduceImage(image: Blob, max = 2048) {
    if (image.size <= THRESHOLD_REDUCE) return image;
    return getReducer().toBlob(image, { max });
}

const RotationItem: React.FunctionComponent<RotationButtonProps> = (props) => {
	const { className, iconName, label, ...rest } = props;
	
	return <DropdownItem className={mergeClasses("d-flex d-row", className)} {...rest}>
		<Icon name={iconName} />
		<span>{label}</span>
	</DropdownItem>
}

export const PhotoEditor: React.FunctionComponent<PhotoEditorProps> = (props) => {
	const {
		image,
		onDone: _onDone,
		onCancel: _onCancel,
		doneButtonProps,
		cancelButtonProps,
		editorProps,
		...rest
	} = props;

	const editorRef: React.LegacyRef<AvatarEditor> = useRef(null);

    const [status, setStatus] = useState<"none" | "applying">("none");
	const [scale, setScale] = useState(DEF_SCALE); // in centies
	const [xCoor, setXCoor] = useState(DEF_COOR_X);
	const [yCoor, setYCoor] = useState(DEF_COOR_Y);
	const [rotate, setRotate] = useState(DEF_ROTATION);

	useEffect(() => {
		setScale(DEF_SCALE);
		setXCoor(DEF_COOR_X);
		setYCoor(DEF_COOR_Y);
		setRotate(DEF_ROTATION);
	}, [image]);

	const apply = async () => {
        setStatus("applying");

        // we can not use the original image even if no changes are made by the user
        // because, squared cropping is resolved at this point.

        if (image instanceof Blob && 
            scale === DEF_SCALE && 
            xCoor === DEF_COOR_X && 
            yCoor === DEF_COOR_Y && 
            rotate === DEF_ROTATION) {
                
                try {
                    const [w, h] = await getImageDimension(image);
                    if (w === h) {
                        setStatus("none");
                        _onDone?.(image);
                        return;
                    }
                } catch (error) {
                    console.warn(`error getting image dimension [cause: ${error}]`);
                }
        }

		const img = editorRef.current?.getImage();

		let type: string | undefined;
		if (typeof image === "string") {
			try {
				const response = await fetch(image);
				if (response.body) type = (await fromStream(response.body))?.mime;
			} catch (err) {
				console.warn(`unable to determine avatar mime type [cause: ${err}]`);
			}
		} else {
			type = image?.type;
		}

		img?.toBlob((o) => {
            setStatus("none");
            _onDone?.(o || undefined);
		}, type, .9);
	};

	const cancel = useCallback(() => {
		_onCancel?.();
	}, [_onCancel]);

	const handleZoomChange = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
		setScale(+evt.currentTarget.value);
	}, []);

	const onPosChanged = useCallback((pos: Position) => {
		setXCoor(pos.x);
		setYCoor(pos.y);
	}, []);

	const rotateImage = useCallback((amount: number) => {
		setRotate(rotate + amount);
	}, [rotate]);

	const rotateLeft = useCallback(() => rotateImage(-90), [rotateImage]);
	const rotateRight = useCallback(() => rotateImage(90), [rotateImage]);

	const rotaions = useMemo(() => {
		return <SplitButton
			id="editor-photo-rotation"
			variant="outline-secondary"
			title={<Icon className="d-flex" name="rotate_right">rotate_right</Icon>}
			size="sm"
			onClick={rotateRight}
		>
			<RotationItem eventKey="itm-rotate-right" iconName="rotate_right" label="Right" onClick={rotateRight}/>
			<RotationItem eventKey="itm-rotate-left" iconName="rotate_left" label="Left" onClick={rotateLeft}/>
		</SplitButton>
	}, [rotateLeft, rotateRight]);

	const zooms = useMemo(() => {
		return <FormGroup className="me-3 flex-grow-1">
			<FormLabel>Zoom</FormLabel>
			<FormRange min={100} max={400} value={scale} onChange={handleZoomChange}/>
		</FormGroup>
	}, [handleZoomChange, scale]);

	return <Modal size="sm" fullscreen="sm-down" onHide={cancel} {...rest}>
		<ModalBody>
            {!image ? <Loading className="h-328_4 my-auto" /> : <Stack gap={3}>
				<AvatarEditor 
					ref={editorRef}
					image={image}
					scale={scale / 100}
					rotate={rotate}
					position={{x: xCoor, y: yCoor}}
					onPositionChange={onPosChanged}
					{...editorProps}
					className={mergeClasses("mx-auto", editorProps?.className)}
				/>
				<Form className="d-flex flex-row">
					{zooms}
					{rotaions}
				</Form>
			</Stack>}
		</ModalBody>
		<ModalFooter>
            <Button 
                variant="primary" 
                disabled={status === "applying"}
                {...doneButtonProps}
                className={mergeClasses("d-flex flex-row gap-1 align-items-center", doneButtonProps?.className)}
                onClick={doneButtonProps?.onClick ? (evt) => { apply(); doneButtonProps.onClick?.(evt) } : apply}
            >
                {status === "applying" && <Spinner as="span" role="status" animation="border" size="sm" aria-hidden />}
                Done
            </Button>
			<Button 
                variant="secondary" 
                {...cancelButtonProps}
                onClick={cancelButtonProps?.onClick ? (e) => { cancel(); cancelButtonProps.onClick?.(e) } : cancel}
            >
                Cancel
            </Button>
		</ModalFooter>
	</Modal>
}

export interface PhotoEditorProps extends ModalProps {
	image?: File | string,
	show?: boolean,
	onDone?: (output: Blob | undefined) => unknown,
	onCancel?: () => unknown,
	doneButtonProps?: ButtonProps,
	cancelButtonProps?: ButtonProps,
	editorProps?: AvatarEditorProps,
}

interface RotationButtonProps extends DropdownItemProps {
	iconName: string,
	label: string,
}