import { mergeClasses } from "@hashimukh/stardust";
import React from "react";
import FormGroup from "react-bootstrap/FormGroup";
import { DatePicker, DatePickerProps } from "./DatePicker";
import { FormLabel } from "./FormLabel";

function getMaxDob(): Date {
	const date = new Date();
	const currentYear = date.getFullYear();

	date.setFullYear(currentYear - 14);
	return date;
}

function getInitialDateForDob(): Date {
	const date = new Date();
	const currentYear = date.getFullYear();

	date.setFullYear(currentYear - 18);
	return date;
}

export const DobControl: React.FunctionComponent<DobControlProps> = (props) => {
	const {
		className,
		controlClassName,
		required,
		...rest
	} = props;

	return <FormGroup className={mergeClasses("mb-3", className)}>
		<FormLabel required={required}>Date of birth</FormLabel>
		<DatePicker 
			className={controlClassName}
			maxDate={getMaxDob()}
			openToDate={getInitialDateForDob()}
			required={required}
			{...rest}
		/>
	</FormGroup>
}

export interface DobControlProps extends DatePickerProps {
	controlClassName?: string,
}