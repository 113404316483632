export const MAX_LEN_FACEBOOK_USERNAME = 50;
export const MAX_LEN_FACEBOOK_NAME = 255;

export enum FacebookField {
    IDENTIFIER = "identifier",
    USERNAME = "username",
    USER_ID = "user_id",
    NAME = "name",
}

export function friendlyFacebookId(data: FacebookData) {
    return data.name || data.username || data.user_id || data.identifier;
}

export type FacebookData = {
    [FacebookField.IDENTIFIER]?: string,
    [FacebookField.USERNAME]?: string,
    [FacebookField.USER_ID]?: string,
    [FacebookField.NAME]?: string
}