import { formatDate } from "@hashimukh/core-js";
import { useQuery } from "@hashimukh/firebase-js";
import { Button, Icon, LazyListProps, Link } from "@hashimukh/stardust";
import { Query, query as queryDB, where } from "firebase/firestore";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Carousel from "react-bootstrap/Carousel";
import CarouselCaption from "react-bootstrap/CarouselCaption";
import CarouselItem from "react-bootstrap/CarouselItem";
import Collapse from "react-bootstrap/Collapse";
import Placeholder from "react-bootstrap/Placeholder";
import ProgressBar from "react-bootstrap/ProgressBar";
import Stack from "react-bootstrap/Stack";
import ReactMarkdown from "react-markdown";
import { ReactMarkdownProps } from "react-markdown/lib/complex-types";
import { useParams } from "react-router-dom";
import remarkGfm from "remark-gfm";
import { AppPage, PageMeta } from "./AppPage";
import { makePostLink } from "./Blog";
import { CopyableText } from "./components/common/CopyableText";
import { MembershipInvitation } from "./components/common/MembershipInvitation";
import { Shimmer } from "./components/common/Shimmer";
import { Topic, TopicPlaceholder } from "./components/common/Topic";
import { MarkdownImage } from "./components/markdown/MarkdownImage";
import { PostList } from "./components/PostList";
import { BlogPostData, BlogPostField, getBlogPost, getBlogPosts } from "./db/models/BlogPost";
import { UserSnapshotField } from "./db/models/User";
import { NameField } from "./db/objects/name";
import "./res/styles/blog-post.scss";
import { createShortLink } from "./utils/shortLinks";
import { findImagesInMarkdown, findUrls } from "./utils/strings";

const loadMoreRelatedProps: LazyListProps["loadMoreButtonProps"] = {
	className: "d-none",
}

const Images: React.FunctionComponent<{ children: React.ReactNode[] }> = (props) => {
	const { children } = props;
	const count = children?.length;

	return <div>
		{count === 1 
		? <MarkdownImage containerClassName="mb-1" {...(children[0] as any)?.props}/>
		: <Carousel className="mt-3">
			{children.map((child, i) => {
				const img = child as any;
				const title = img?.props?.title;

				return <CarouselItem key={`car-itm-${i}`}>
					<div className="carousel-img-container ratio ratio-4x3" data-clean={!title}>{img}</div>
					{title && <CarouselCaption>{title}</CarouselCaption>}
				</CarouselItem>
			})}
		</Carousel>}
	</div>
}

const ParagraphRenderer: React.FunctionComponent<React.ClassAttributes<HTMLParagraphElement> & React.HTMLAttributes<HTMLParagraphElement> & ReactMarkdownProps> = (props) => {
	const {
		children,
	} = props;

	const images: React.ReactNode[] = [];
	const others: React.ReactNode[] = [];

	children.forEach((child) => {
		const isImg = typeof child === "object" && (child as Record<string, any>)?.key?.startsWith?.("img");
		if (isImg) images.push(child);
		else others.push(child);
	});

	const imgCount = images.length;
	const othersCount = others.length;

	return <>
		{imgCount > 0 && <Images>{images}</Images>}
		{othersCount > 0 && <p>{others}</p>}
	</>;
}

const BlogPostPlaceholder: React.FunctionComponent = () => {
	return <div>
		<Shimmer className="mb-3"><TopicPlaceholder /></Shimmer>
		<h1><Shimmer className="mb-3" pattern={[5, 3, 4]} /></h1>
		<p><Shimmer pattern={[4, 5, 3]} size="lg" />
		<Shimmer className="mt-2" pattern={[3, 4, 5]} size="lg" /></p>
		<Shimmer pattern={[3, 3, <Placeholder.Button key="share-btn" className="btn-sm ms-auto" variant="secondary" xs={1} size="sm" />]} />
		<hr />
		<div className="content-post">
			<p className="d-grid gap-2">
				<Shimmer pattern={[5, 4, 3]} />
				<Shimmer pattern={[4, 5, 3]} />
				<Shimmer pattern={[5, 3, 4]} />
				<Shimmer pattern={[4, 3, 5]} />
			</p>
			<h5><Shimmer pattern={[6]} /></h5>
			<p className="d-grid gap-2">
				<Shimmer pattern={[4, 3, 5]} />
				<Shimmer pattern={[5, 3, 4]} />
				<Shimmer pattern={[4, 5, 3]} />
				<Shimmer pattern={[5, 4, 3]} />
			</p>
			<p className="d-grid gap-2">
				<Shimmer pattern={[4, 5, 3]} />
				<Shimmer pattern={[5, 4, 3]} />
				<Shimmer pattern={[4, 3, 5]} />
				<Shimmer pattern={[5, 3, 4]} />
			</p>
			<p className="d-grid gap-2">
				<Shimmer pattern={[4, 3, 5]} />
				<Shimmer pattern={[4, 5, 3]} />
				<Shimmer pattern={[5, 3, 4]} />
				<Shimmer pattern={[5, 4, 3]} />
			</p>
			<p className="d-grid gap-2">
				<Shimmer pattern={[5, 4, 3]} />
				<Shimmer pattern={[4, 3, 5]} />
				<Shimmer pattern={[5, 3, 4]} />
				<Shimmer pattern={[4, 5, 3]} />
			</p>
		</div>
	</div>
}

export const BlogPost: React.FunctionComponent = () => {
	const { postId } = useParams<BlogPostParams>();

	const [shareableLink, setShareableLink] = useState<"creating" | "error" | string>("");
	
	const query = useMemo<Query<BlogPostData>>(() => getBlogPost(postId), [postId]);
	const { snapshot, status } = useQuery(query, "single");
	const data = useMemo(() => snapshot?.data(), [snapshot]);

	const defRelatedQuery = useRef(getBlogPosts());
	const [relatedQuery, setRelatedQuery] = useState<Query<BlogPostData>>();
	const { snapshots: relatedSnapshots } = useQuery(relatedQuery || defRelatedQuery.current, 4);
	const morePosts = useMemo(() => relatedSnapshots.filter(v => v.id !== snapshot?.id), [relatedSnapshots, snapshot?.id]);

	const author = data?.[BlogPostField.AUTHOR];
	const authorName = author?.[UserSnapshotField.NAME]?.[NameField.DISPLAY];
	const authorUid = author?.[UserSnapshotField.ID];

	const headline = data?.[BlogPostField.HEADLINE];
	const subheadline = data?.[BlogPostField.SUBHEADLINE];
	const topics = data?.[BlogPostField.TOPICS];
	const publishDate = data?.[BlogPostField.PUBLISH_DATE];
	const markdown = data?.[BlogPostField.MARKDOWN];

	const images = useMemo(() => markdown ? findImagesInMarkdown(markdown) : undefined, [markdown]);
	const coverUrl = useMemo(() => findUrls(images?.[0]?.element)[0], [images]);
	const metas: PageMeta = {
		title: (headline || (markdown && markdown.substring(0, 50) + (markdown.length > 50 ? "..." : "")) || "Post").trim() + " - Hashimukh",
		description: subheadline,
		image: coverUrl,
	}

	const createShareableLink = async () => {
		setShareableLink("creating");

		const postLink = makePostLink(postId);
		if (!headline || !subheadline || !coverUrl) {
			setShareableLink(postLink);
		} else {
			try {
				const links = await createShortLink(postLink, headline, subheadline, coverUrl);
				setShareableLink(links.shortLink);
			} catch (err) {
				setShareableLink(postLink);
				console.error(`error creating short links: ${err}`);
			}
		}
	};

	useEffect(() => {
		if (!topics?.length) {
			setRelatedQuery(undefined);
			return;
		}

		const q = queryDB(getBlogPosts(), where(BlogPostField.TOPICS, "array-contains-any", topics));
		setRelatedQuery(q);
	}, [topics]);
	
	return <AppPage metas={metas}>
		{(snapshot?.exists() && <>
			<article>
				<header>
					{topics?.length && <div className="mb-2">{topics.map(topic => <Topic key={topic} topicId={topic} />)}</div>}
					{headline && <h1>{headline}</h1>}
					{subheadline && <p className="mt-3">{subheadline}</p>}
					<Stack direction="horizontal">
						{authorName && authorUid && <Stack direction="horizontal" gap={2}>
							<small><address className="d-inline author mb-0">By <Link className="link-secondary" rel="author" href={`/blog?author=${authorUid}`}>{authorName}</Link></address></small>
							{publishDate && <>
								<small className="vr" />
								<small className="text-muted"><time itemProp="datePublished" dateTime={publishDate.toString()}>{formatDate(publishDate.toDate(), "long")}</time></small>
							</>}
						</Stack>}
						<Stack className="ms-auto" direction="horizontal" gap={2}>
							<Button 
								className="d-flex align-items-center" 
								variant="outline-secondary" 
								size="sm" 
								onClick={createShareableLink}
								disabled={!!shareableLink}
							>
								<Icon className="me-1" name="share" size="sm" />
								<span className="d-none d-md-inline-block">Share</span>
							</Button>
						</Stack>
					</Stack>
					<Collapse in={!!shareableLink}><div className="mt-3">
						{!shareableLink || shareableLink === "creating" 
							? <ProgressBar className="progress-copyable-text" now={100} animated />
							: shareableLink === "error"
							? <Alert variant="danger">
								Error creating shareable links.
							</Alert>
							: <CopyableText autoCopy={shareableLink.startsWith("https://")}>{shareableLink}</CopyableText>}
					</div></Collapse>
				</header>
				<hr />
				{markdown && <div className="content-post"><ReactMarkdown 
					linkTarget="_blank"
					components={{
						"p": ParagraphRenderer,
					}}
					plugins={[remarkGfm]}
				>
					{markdown}
				</ReactMarkdown></div>}
			</article>
			{morePosts.length > 0 && <div>
				<hr />
				<h4 className="mb-3">Related</h4>
				<PostList posts={morePosts} previewHorizontal loadMoreButtonProps={loadMoreRelatedProps}/>
			</div>}
			<MembershipInvitation />
		</>) ||
			(status === "fetching" && <BlogPostPlaceholder />) ||
			(status === "failed" && <Alert variant="danger">Oops! Something went wrong from our end. Please try again later.</Alert>) ||
			<Alert variant="warning">Hmm... Looks like the post doesn&apos;t exists.</Alert>}
	</AppPage>
}

interface BlogPostParams {
	postId: string,
}