import { onEmulator } from "@hashimukh/core-js";
import { Region } from "@hashimukh/firebase-js";
import { initializeAnalytics, isSupported as isAnalyticsSupported, setAnalyticsCollectionEnabled } from "firebase/analytics";
import { FirebaseApp, getApp, getApps, initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { isSupported } from "firebase/remote-config";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { handoutConfig, logcatConfig } from "../res/configs";
import { Backend } from "./backend";
import { GS_PREFIX, HandoutBucket } from "./bucket";
import { initRemoteConfig } from "./configs";

let hasInit = false;

export function init() {
    if (hasInit) return;
    hasInit = true;

    if (process.env.NODE_ENV === "production") {
        const noOp = () => { /* no-op */ };
        console.debug = noOp;
        console.log = noOp;
    }

    ensureFirebaseReady();
    isSupported().then(async supported => supported && initRemoteConfig());
    isAnalyticsSupported().then(async supported => supported && initAnalytics())
}

export function ensureFirebaseReady(): [FirebaseApp /* logcat */, FirebaseApp /* handout */] {
    const apps = getApps();
    let defaultApp = apps.find(app => app.name === Backend.LOGCAT);
    let handoutApp = apps.find(app => app.name === Backend.HANDOUT);

    if (!defaultApp || !handoutApp) {
        if (!defaultApp) defaultApp = initializeApp(logcatConfig, Backend.LOGCAT);
        if (!handoutApp) handoutApp = initializeApp(handoutConfig, Backend.HANDOUT);
    
        const auth = getAuth(handoutApp);
        
        if (onEmulator) {
            const defaultDb = getFirestore(defaultApp);
            const handoutDb = getFirestore(handoutApp);
    
            connectFirestoreEmulator(defaultDb, "localhost", 8080);
            connectFirestoreEmulator(handoutDb, "localhost", 8181);
            connectAuthEmulator(auth, "http://localhost:9909", { disableWarnings: true }); // auth emulator may cause trouble in subsequent sign-ins
            
            [Region.IOWA].forEach(r => connectFunctionsEmulator(getFunctions(defaultApp, r), "localhost", 5001));
            [Region.HONG_KONG].forEach(r => connectFunctionsEmulator(getFunctions(handoutApp, r), "localhost", 5101));
    
            Object.values(HandoutBucket)
                .forEach(bucket => connectStorageEmulator(getStorage(handoutApp, GS_PREFIX + bucket), "localhost", 9198));
        }
    
        auth.useDeviceLanguage();
    }

    return [defaultApp, handoutApp];
}

function initAnalytics() {
    const analytics = initializeAnalytics(getApp(Backend.LOGCAT))
    setAnalyticsCollectionEnabled(analytics, !onEmulator)
}