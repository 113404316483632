import React, { useEffect } from 'react';

const getMathJax = () => (window as any).MathJax;

const typeset = (selector: () => HTMLElement) => {
    const mathJax = getMathJax();
    if (!mathJax) return null;

    mathJax.startup.promise = mathJax.startup.promise
        .then(() => {
            selector();
            return mathJax.typesetPromise();
        })
        .catch((err: any) => {
            console.error(`Typeset failed [cause: ${err}]`);
        })

    return mathJax.startup.promise;
};
  

export const Equation: React.FunctionComponent<LatexProps> = (props) => {
    const ref = React.createRef<HTMLSpanElement>();

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        typeset(() => ref.current!)
    })

    return <span ref={ref}>{props.rawLatex}</span>
}

interface LatexProps {
    rawLatex: string;
}