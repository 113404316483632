import { mergeClasses } from "@hashimukh/stardust";
import React from "react";
import { BsPrefixRefForwardingComponent } from "react-bootstrap/helpers";
import FormGroup from "react-bootstrap/FormGroup";
import FormSelect, { FormSelectProps } from "react-bootstrap/FormSelect";
import { BloodType } from "../../db/objects/bloodtype";
import { FormLabel } from "./FormLabel";
import { NONE, None, onSelectCallback } from "./PostControl";

export const BloodTypeControl: BsPrefixRefForwardingComponent<"select", BloodTypeControlProps> = (props) => {
	const {
		className,
		controlClassName,
        onChange,
        onBloodTypeSelect,
		required,
		...rest
	} = props;

	return <FormGroup className={mergeClasses("mb-3", className)}>
		<FormLabel required={required}>Blood type</FormLabel>
		<FormSelect 
			className={controlClassName}
			aria-label="Your blood type"
            onChange={evt => {
                onChange?.(evt);
                onSelectCallback(evt, onBloodTypeSelect);
            }}
			required={required}
			{...rest}
		>
			<option value={NONE} disabled={required}>Select your blood type</option>
			<option value={BloodType.A_POS}>A+</option>
			<option value={BloodType.A_NEG}>A-</option>
			<option value={BloodType.B_POS}>B+</option>
			<option value={BloodType.B_NEG}>B-</option>
			<option value={BloodType.AB_POS}>AB+</option>
			<option value={BloodType.AB_NEG}>AB-</option>
			<option value={BloodType.O_POS}>O+</option>
			<option value={BloodType.O_NEG}>O-</option>
		</FormSelect>
	</FormGroup>
}

export interface BloodTypeControlProps extends FormSelectProps {
	controlClassName?: string,
	defaultValue?: BloodType | None,
    onBloodTypeSelect?: (type: BloodType | undefined) => unknown,
}