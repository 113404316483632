export enum Grade {
    VIII = "VIII",
    IX = "IX",
    X = "X",
    XI = "XI",
    XII = "XII",
    ABOVE_XII = "XII+",
}

export const strGrades: Record<Grade, string> = {
    [Grade.VIII]: "Class 8",
    [Grade.IX]: "Class 9",
    [Grade.X]: "Class 10",
    [Grade.XI]: "Class 11",
    [Grade.XII]: "Class 12",
    [Grade.ABOVE_XII]: "Class 12+",
}