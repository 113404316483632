import { collection, CollectionReference, doc, Timestamp } from "firebase/firestore";
import { getUser } from "./User";

export const USER_METADATA_COLLECTION_GROUP_ID = "metadata";
export const USER_CLAIMS_DOC_ID = "claims";

export function getMedatada(uid: string): CollectionReference<UserMetadata> {
	return collection(getUser(uid), USER_METADATA_COLLECTION_GROUP_ID);
}

export function getUserClaims(uid: string) {
	return doc(getMedatada(uid), USER_CLAIMS_DOC_ID);
}

export interface UserMetadata {
	version?: number,
	refreshTime?: Timestamp,
}