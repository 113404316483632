import React from "react";
import { AppPage, PageMeta } from "./AppPage";
import { RequireSignIn } from "./components/RequireSignIn";

const metas: PageMeta = {
	title: "Sign in - Hashimukh",
	description: "Manage your profile, see reports and more."
}

export const SignIn: React.FunctionComponent<SignInControlProps> = () => {

	return <AppPage 
		heading="Sign in to Hashimukh"
		subheading={metas.description}
		metas={metas}
	>
		<RequireSignIn />
	</AppPage>
}

export interface SignInControlProps {

}