export enum PostLevel {
    PATRON = "PATRON",
    CORE_MEMBER = "CORE_MEMBER",
    MENTOR = "MENTOR",
    ADVISOR = "ADVISOR",
    EXEC = "EXEC"
}

export enum PostField {
    LEVEL = "level",
}

export function getFriendlyPost(post: Post | undefined): string | undefined {
    let postLevel: string | undefined = undefined;
    if (post) {
        switch(post[PostField.LEVEL]) {
            case PostLevel.PATRON:
                postLevel = "Patron";
                break;
            case PostLevel.CORE_MEMBER:
                postLevel = "Core-member";
                break;
            case PostLevel.MENTOR:
                postLevel = "Mentor";
                break;
            case PostLevel.ADVISOR:
                postLevel = "Advisor";
                break;
            case PostLevel.EXEC:
                postLevel = "Executive Officer"
                break;
        }
    }

    return postLevel;
}

export type Post = {
    [PostField.LEVEL]: PostLevel,
}