import { Icon, mergeClasses } from "@hashimukh/stardust";
import React from "react";
import Col from "react-bootstrap/Col";
import Container, { ContainerProps } from "react-bootstrap/Container";
import Placeholder from "react-bootstrap/Placeholder";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import "../../res/styles/cheap-item.scss";

export const CheapItemPlaceholder: React.FunctionComponent<{ flush?: boolean }> = (props) => {
	const {
		flush,
	} = props;

	return <Container className={"itm-cheap"}>
		<Row className="px-3">
			<Col className="d-flex my-auto px-0 justify-content-center" xs={1}>
				<Placeholder className="d-block w-75 h-100" animation="glow">
					<Placeholder className="w-100 h-100 rounded" />
				</Placeholder>
			</Col>
			<Col>
				<Stack className="my-auto text-wrap">
					<Placeholder className="d-block" animation="glow">
						<Placeholder xs={3} size="lg" /> <Placeholder xs={2} size="lg" />
					</Placeholder>
					<Placeholder className="d-block" animation="glow">
						<Placeholder xs={2} size="sm" /> <Placeholder xs={4} size="sm" />
					</Placeholder>
				</Stack>
			</Col>
		</Row>
		{!flush && <Row className="itm-divider">
			<Col xs={1}></Col>
			<Col><hr /></Col>
		</Row>}
	</Container>
}

export const CheapItem: React.FunctionComponent<CheapItemProps> = (props) => {
	const {
		className,
		flush,
		icon,
		primaryText,
		secondaryText,
		optionalText,
		...rest
	} = props;

	return <Container className={mergeClasses("itm-cheap", className)} {...rest}>
		<Row className="px-3">
			<Col className="d-flex my-auto px-0 justify-content-center" xs={1}>
				<Icon className="d-flex ms-auto text-muted" name={icon || "scatter_plot"} />
			</Col>
			<Col>
				<Stack className="my-auto text-wrap">
					<span>{primaryText}</span>
					<small className="text-muted">{secondaryText}</small>
					<small className="text-muted">{optionalText}</small>
				</Stack>
			</Col>
		</Row>
		{!flush && <Row className="itm-divider">
			<Col xs={1}></Col>
			<Col><hr /></Col>
		</Row>}
	</Container>
}

export interface CheapItemProps extends ContainerProps {
	flush?: boolean,
	icon?: string,
	primaryText?: React.ReactNode,
	secondaryText?: React.ReactNode,
	optionalText?: React.ReactNode,
}