import { getCoverImages } from "@hashimukh/firebase-js";
import { CoverPhoto, useCoverPhoto } from "@hashimukh/react-hooks";
import { Background } from "@hashimukh/stardust";
import { useEffect, useState } from "react";
import { initRemoteConfig } from "./configs";

export const useBackground = () => {
	const [imgs, setImgs] = useState<CoverPhoto[]>([]);
	const cover = useCoverPhoto(imgs);

	useEffect(() => {
		initRemoteConfig().then(config => {
			setImgs((current) => current.length ? current : getCoverImages(config.app));
		});
	}, []);

	return cover || Background.LATE;
}