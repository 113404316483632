import { useDoc } from "@hashimukh/firebase-js";
import { DocumentReference } from "firebase/firestore";
import { useMemo } from "react";
import { Member } from "../db/models/member/Member";
import { MemberPublicData } from "../db/models/member/Public";

export const useMember = (mid: string | undefined) => {
	const memberRef = useMemo<DocumentReference<MemberPublicData> | undefined>(() => 
		mid ? Member.createRef(mid) : undefined, [mid]);
	
	return useDoc(memberRef);
}