import { Timestamp } from "firebase/firestore";
import { BloodType } from "../../objects/bloodtype";
import { Gender } from "../../objects/gender";
import { Sex } from "../../objects/sex";

export const MEMBER_PERSONAL_DOC_ID = "personal";

export enum MemberPersonalField {
    BLOOD_TYPE = "blood_type",
    SEX = "sex",
    GENDER = "gender",
    DOB = "date_of_birth",
    UID = "uid",
}

export interface MemberPersonalData extends Partial<Record<MemberPersonalField, unknown>> {
    [MemberPersonalField.BLOOD_TYPE]?: BloodType,
    [MemberPersonalField.SEX]?: Sex,
    [MemberPersonalField.GENDER]?: Gender,
    [MemberPersonalField.DOB]?: Timestamp,
    [MemberPersonalField.UID]?: string,
}