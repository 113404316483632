export enum FormOfDonation {
    CASH = "MONEY",
    CLOTHES = "CLOTHES",
}

export type FundRaiseReport = Record<FormOfDonation, FundRaiseItemReport | undefined>;

export interface FundRaiseItemReport {
    amount_millis?: number,
    expected_amount_millis?: number,
}