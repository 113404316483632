import { CHARS_ALPHABET_LOWER } from "./constants";

export function getQuantitatives(count: number, singular: string, plural: string) {
	return count === 1 ? singular : plural;
}

/**
 * Returns a number within a given range.
 * 
 * @param from Start of the range. Must be <= 1 and >= 0.
 * @param to End of the range. Must be greater than {@code from} and <= 1.
 * The returned number is less than but not equal to {@code to}.
 * @returns A random number from given range.
 */
export function getRand(from: number, to: number) {
	for ( ; ; ) {
		const rand = Math.random();
		if (rand < to && rand >= from) return rand;
	}
}

export function toAbc(num: number) {
    return toBase(num, CHARS_ALPHABET_LOWER);
}

export function toBase(num: number, baseChars: string) {
    const base = baseChars.length;
    let result = "";

	// using "|" will convert quotient to int and loss the upper bits
	for (let quotient = Math.abs(num), remainder; quotient > 0; quotient = Math.floor(quotient / base)) {
		remainder = quotient % base;
		result = baseChars.charAt(remainder) + result;
	}
	if (num < 0) result = "-" + result;

	return result;
}