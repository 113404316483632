export const ANALYTICS_COLLECTION_ID = "analytics";
export const MEMBER_ANALYTICS_DOC_ID = "members";

export const SESSION_COLLECTION_ID = "sessions";

export const CAMPAIGN_ANALYTICS_COLLECTION_ID = "campaign-analytics";
export const PROGRAM_ANALYTICS_COLLECTION_ID = "program-analytics";

export const NEW = "__create_new";

export const MAX_LEN_NAME = 255;
export const MIN_LEN_NAME = 2;

export const MIN_LEN_USERNAME = 6;
export const MAX_LEN_USERNAME = 127;

export const CHARS_DIGITS = "0123456789";
export const CHARS_ALPHABET_LOWER = "abcdefghijklmnopqrstuvwxyz"; 
export const CHARS_ALPHABET_UPPER = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"; 