export function matchString(str1: string, str2: string): boolean {
    if (str1 === str2) return true;

    const regex = /[^a-z0-9]/g;
    const expression1 = str1.toLowerCase().replace(regex, '');
    const expression2 = str2.toLowerCase().replace(regex, '');

    return expression1 === expression2;
}

/**
 * finds images from markdown string.
 * this method assumes that there are no '[' or ']' characters in alt text.
 * 
 * @param markdown the formated text
 * @returns an array of image object
 */
export function findImagesInMarkdown(markdown: string) {
    const result: { element: string }[] = [];
    const images = markdown.match(/!\[[^[]*\]\(([^)"]|[^)'])+(("[^"]*")|('[^']'))*\)/g) || [];

    for (let i = 0, len = images.length; i < len; i++) {
        const image = images[i];
        result.push({ element: image });
    }

    return result;
}

export function findUrls(text: string | undefined) {
    return text?.match(/https:\/\/[^ "')]+/g) || [];
}