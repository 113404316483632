export const MAX_LEN_ADDRESS = 255;
export const MAX_LEN_CITY = 127;
export const MAX_LEN_POST_CODE = 63;

export enum AddressField {
    LINE_ONE = "line_1",
    STREET = "street",
    CITY = "city",
    POST_CODE = "postal_code",
    COUNTRY = "country",
}

export function friendlyAddress({ 
    [AddressField.LINE_ONE]: lineOne,
    [AddressField.STREET]: street,
    [AddressField.CITY]: city,
    [AddressField.POST_CODE]: postCode,
    [AddressField.COUNTRY]: country,
}: AddressData) {
    return [lineOne, street, city, postCode, country].reduce((prev, curr) => curr ? prev ? `${prev}, ${curr}` : curr : prev);
}

export interface AddressData extends Partial<Record<AddressField, unknown>> {
    [AddressField.LINE_ONE]?: string,
    [AddressField.STREET]?: string,
    [AddressField.CITY]?: string,
    [AddressField.POST_CODE]?: string,
    [AddressField.COUNTRY]?: string,
}