import { appConfigs, call, WithCallable } from "@hashimukh/core-js";
import { FeedbackType, FormFeedbackActions, FormFeedbackProps, FormSection, Link, SectionForm, SectionStatus } from "@hashimukh/stardust";
import { Timestamp } from "firebase/firestore";
import { UploadResult } from "firebase/storage";
import React, { useCallback, useMemo, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button, { ButtonProps } from "react-bootstrap/Button";
import FormGroup from "react-bootstrap/FormGroup";
import { MemberAcademicField } from "../db/models/member/Academic";
import { MemberContactField } from "../db/models/member/Contact";
import { Member, MemberCreateResult, uploadMemberAvatar } from "../db/models/member/Member";
import { MemberPersonalField } from "../db/models/member/Personal";
import { MemberPublicField } from "../db/models/member/Public";
import { AddressData } from "../db/objects/address";
import { BloodType } from "../db/objects/bloodtype";
import { EmailField } from "../db/objects/email";
import { FacebookData } from "../db/objects/facebook";
import { Grade } from "../db/objects/grade";
import { PostField, PostLevel } from "../db/objects/post";
import { Sex } from "../db/objects/sex";
import { AddressField as Address } from "./common/AddressField";
import { BloodTypeControl } from "./common/BloodTypeControl";
import { CopyButton } from "./common/CopyButton";
import { DobControl } from "./common/DobControl";
import { EmailField as EmailInputField } from "./common/EmailField";
import { FacebookLinkField } from "./common/FacebookLinkField";
import { FormLabel } from "./common/FormLabel";
import GradeControl from "./common/GradeControl";
import { NameField } from "./common/NameField";
import { PostControl } from "./common/PostControl";
import { SexControl } from "./common/SexControl";
import StudyFieldControl from "./common/StudyFieldControl";
import { InstitutionPicker, InstitutionPickItem, toInstitutionSnapshot } from "./InstitutionPicker";
import Avatar, { AvatarStatus } from "./member/Avatar";
import { MemberPicker, MemberPickItem } from "./MemberPicker";

const TEXT_AVATAR_UPLOAD = "Upload avatar";
const TEXT_AVATAR_UPLOADING = "Uploading";
const TEXT_AVATAR_UPLOADED = "Uploaded";
const TEXT_AVATAR_RE_UPLOAD = "Reupload avatar"

const TEXT_RETRY = "Retry";

export enum Section {
    PUBLIC_DATA = "public-data",
	PERSONAL_DATA = "personal-data",
    CONTACT_DATA = "contact-data",
    ACADEMIC_DATA = "academic-data",
	REFERENCE = "reference"
}

function getAssist() {
	return <p>
		You can always reach us at <Alert.Link href={`mailto:${appConfigs.contacts.email}`}>{appConfigs.contacts.email}</Alert.Link>.
	</p>
}

const RetryButton: React.FunctionComponent<RetryButtonProps> = (props) => {
	const { onClick } = props;

	const [text, setText] = useState(TEXT_RETRY);
	const retry = useCallback(async (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		setText("Please wait...");
		await onClick?.(evt);
		setText(TEXT_RETRY);
	}, [onClick]);

	return <Button variant="outline-secondary" onClick={retry}>{text}</Button>
}

const AvatarUploadButton: React.FunctionComponent<AvatarUploadButtonProps> = (props) => {
	const {
		className,
		mid,
		avatar,
		onUploaded,
		onUploadFailed,
		defaultStatus,
		status: _status,
		...rest
	} = props;

	const [status, setStatus] = useState<AvatarStatus>(defaultStatus || "avatar:rest");
	const text = useMemo(() => {
		switch(_status || status) {
			case "avatar:success": return TEXT_AVATAR_UPLOADED;
			case "avatar:uploading": return TEXT_AVATAR_UPLOADING;
			case "avatar:error": return TEXT_AVATAR_RE_UPLOAD;

			default: return TEXT_AVATAR_UPLOAD;
		}
	}, [_status, status]);
	const errorCallback = onUploadFailed;
	const uploadedCallback = onUploaded;
	
	const upload = useCallback(async () => {
		const id = call(mid);
		const avt = call(avatar);

		if (!avt || !id) {
			errorCallback?.(new Error("Member ID or avatar is undefined"));
			setStatus("avatar:error");
			return;
		}

		setStatus("avatar:uploading");

		try {
			const res = await uploadMemberAvatar(id, avt);
			uploadedCallback?.(res)
			setStatus("avatar:success");
		} catch (error: any) {
			errorCallback?.(error);
			setStatus("avatar:error");
		}
	}, [mid, avatar, uploadedCallback, errorCallback])

	return <Button 
		className={className}
		variant="outline-secondary"
		onClick={upload}
		disabled={status === "avatar:uploading" || status === "avatar:success"}
		{...rest}
	>
		{text}
	</Button>
}

export const titles: Record<Section, string | undefined> = {
    [Section.PUBLIC_DATA]: "Become a part of Hashimukh",
    [Section.CONTACT_DATA]: "Contact information",
    [Section.PERSONAL_DATA]: "Personal information",
    [Section.ACADEMIC_DATA]: "Academic information",
    [Section.REFERENCE]: "References",
};

export const descriptions: Record<Section, string | undefined> = {
    [Section.PUBLIC_DATA]: "We work for a cause.",
    [Section.CONTACT_DATA]: "Communication is a crucial part of working with us. Please provide your preferred contact information to get updates and stay up-to-date.",
	[Section.PERSONAL_DATA]: "These information help us make informed decisions and keep you up-to-date.",
    [Section.ACADEMIC_DATA]: "These information help us make informed decisions.",
	[Section.REFERENCE]: "Please fill-out this section if you were referred by someone from our foundation. Otherwise, you may go ahead and submit the form."
};

const actionInfos: Partial<Record<Section, string | JSX.Element | undefined>> = {
	[Section.REFERENCE]: <p>
			By submitting the form, you understand and agree to <Link href="/policies" newTab>Hashimukh Member Agreement</Link>.
		</p>,
};

export const requires: MemberElement[] = ["name", "avatar", "bloodType", "dob", "sex"];

const sections: Record<MemberElement, Section> = {
    avatar: Section.PUBLIC_DATA,
	name: Section.PUBLIC_DATA,
	post: Section.PUBLIC_DATA,
	email: Section.PERSONAL_DATA,
	facebook: Section.PERSONAL_DATA,
	dob: Section.PERSONAL_DATA,
	sex: Section.PERSONAL_DATA,
	bloodType: Section.PERSONAL_DATA,
	address: Section.PERSONAL_DATA,
	institution: Section.ACADEMIC_DATA,
    grade: Section.ACADEMIC_DATA,
    field_of_study: Section.ACADEMIC_DATA,
	referrers: Section.REFERENCE,
}

export const RegistrationControl: React.FunctionComponent<RegistrationControlProps> = (props) => {
	const {
		uid,
		onRegistered,
		onError,
        onSectionChange,
	} = props;
    
	const [feedback, setFeedback] = useState<FormFeedbackProps>();

	const [name, setName] = useState<string>();
	const [avatar, setAvatar] = useState<Blob>();
	const [post, setPost] = useState<PostLevel>();
	const [email, setEmail] = useState<string>();
	const [address, setAddress] = useState<AddressData>();
	const [bloodType, setBloodType] = useState<BloodType>();
	const [dob, setDob] = useState<Date>();
	const [facebook, setFacebook] = useState<FacebookData>();
	const [institution, setInstitution] = useState<InstitutionPickItem[]>();
	const [sex, setSex] = useState<Sex>();
    const [grade, setGrade] = useState<Grade>();
    const [studyField, setStudyField] = useState<string>();
	const [referrers, setReferrers] = useState<MemberPickItem[]>();

	const [locked, setLocked] = useState(false);

    const nameElement = useMemo(() => {
        const required = requires.includes("name");
        
        return <NameField 
            defaultValue={name}
            onNameChanged={setName}
            disabled={locked}
            required={required}
        />
    }, [locked, name]);

    const avatarElement = useMemo(() => {
        const required = requires.includes("avatar");

        return <><Avatar 
            className="mb-4"
            defaultBlob={avatar}
            onBlobChanged={setAvatar}
            editable
            disabled={locked}
            required={required}
        /><hr /></>
    }, [avatar, locked]);

	const postElement = useMemo(() => {
		const required = requires.includes("post");
		return <PostControl 
			defaultValue={post} 
			onPostSelect={setPost}
			disabled={locked}
			required={required}
		/>
	}, [locked, post]);

	const emailElement = useMemo(() => {
		return <EmailInputField
			defaultValue={email}
			disabled={locked}
			onEmailChanged={setEmail}
			required={requires.includes("email")} />
	}, [email, locked]);

	const facebookElement = useMemo(() => {
		return <FacebookLinkField 
			defaultValue={facebook}
			onDataChanged={setFacebook}
			disabled={locked}
			required={requires.includes("facebook")}
		/>
	}, [facebook, locked]);

	const dobElement = useMemo(() => {
		const required = requires.includes("dob");
		return <DobControl 
			defaultSelected={dob || null}
			onSelect={setDob}
			disabled={locked}
			required={required}
		/>
	}, [dob, locked]);

	const sexElement = useMemo(() => {
		const required = requires.includes("sex");
		return <SexControl 
			defaultValue={sex || "__none"}
            onSexSelect={setSex}
			disabled={locked}
			required={required}
		/>
	}, [locked, sex]);

	const bloodElement = useMemo(() => {
		const required = requires.includes("bloodType");
		return <BloodTypeControl 
			defaultValue={bloodType || "__none"}
            onBloodTypeSelect={setBloodType}
			disabled={locked}
			required={required}
		/>
	}, [bloodType, locked]);

	const institutionElement = useMemo(() => {
		const required = requires.includes("institution");
		return <FormGroup className="mb-3">
			<FormLabel required={required}>Institution</FormLabel>
			<InstitutionPicker 
				max={1}
				disabled={locked}
				defaultSelectedItems={institution}
				onPicked={setInstitution}
				required={required}
			/>
		</FormGroup>
	}, [institution, locked]);

	const addressElement = useMemo(() => {
		return <Address
			aria-label="Your postal address"
			defaultValue={address}
			onAddressChanged={setAddress}
			disabled={locked}
			required={requires.includes("address")}
		/>
	}, [address, locked]);

    const gradeElement = useMemo(() => {
        const required = requires.includes("grade");

        return <GradeControl 
            defaultValue={grade || "__none"}
            onGradeSelect={setGrade}
            disabled={locked}
            required={required}
        />
    }, [grade, locked]);

    const studyFieldElement = useMemo(() => {
        const required = requires.includes("field_of_study");

        return <StudyFieldControl 
            defaultValue={studyField}
            onChange={(evt) => setStudyField(evt.currentTarget.value)}
            disabled={locked}
            required={required}
        />
    }, [locked, studyField]);

	const referrerElement = useMemo(() => {
		const required = requires.includes("referrers");

		return <FormGroup className="mb-3">
			<FormLabel required={required}>Reference</FormLabel>
			<MemberPicker
				max={3}
				defaultSelectedItems={referrers}
				onPicked={setReferrers}
				disabled={locked}
				required={required}
			/>
		</FormGroup>
	}, [locked, referrers]);

	const elementMapping = useMemo<Partial<Record<MemberElement, JSX.Element>>>(() => {
		return {
            avatar: avatarElement,
            name: nameElement,
			address: addressElement,
			bloodType: bloodElement,
			dob: dobElement,
			email: emailElement,
			facebook: facebookElement,
			institution: institutionElement,
			post: postElement,
			referrers: referrerElement,
			sex: sexElement,
            field_of_study: studyFieldElement,
            grade: gradeElement,
		};
	}, [addressElement, avatarElement, bloodElement, dobElement, emailElement, facebookElement, gradeElement, institutionElement, nameElement, postElement, referrerElement, sexElement, studyFieldElement]);

	const validationMapping = useMemo<Record<MemberElement, boolean | unknown>>(() => {
		return {
            name: name,
            avatar: avatar,
			address: address,
			bloodType: bloodType,
			dob: dob,
			email: email,
			facebook: facebook,
			institution: institution,
			post: post,
			referrers: referrers,
			sex: sex,
            field_of_study: studyField,
            grade: grade,
		}
	}, [address, avatar, bloodType, dob, email, facebook, grade, institution, name, post, referrers, sex, studyField]);

	const sectionMapping = useMemo<FormSection<Section>[]>(() => {
		const filterElements = (section: Section) => Object.keys(sections)
			.map(field => sections[field as MemberElement] === section && <React.Fragment key={field}>{elementMapping[field as MemberElement]}</React.Fragment>);

		const computeStatus = (section: Section) => requires.every(r => sections[r] !== section || validationMapping[r]) 
			? SectionStatus.FULFILLED : SectionStatus.INCOMPLETE;
		
        const result: FormSection<Section>[] = [];
		Object.values(Section).forEach(sec => {
            const itms = filterElements(sec);
            if (itms.every(itm => itm === false)) return;

            result.push({
                id: sec,
                actionInfo: actionInfos[sec],
                items: <>{itms}</>,
                status: computeStatus(sec),
            });
        });

        return result;
	}, [elementMapping, validationMapping]);

	const submit = useCallback(async () => {
		setLocked(true);

		const instance = new Member();
		if (name) instance.set(MemberPublicField.NAME, name);
		if (post) instance.set(MemberPublicField.POST, { [PostField.LEVEL]: post });
		if (email) instance.set(MemberContactField.EMAIL, { [EmailField.ADDRESS]: email });
		if (facebook) instance.set(MemberContactField.FACEBOOK, facebook);
		if (dob) instance.set(MemberPersonalField.DOB, Timestamp.fromDate(dob));
		if (sex) instance.set(MemberPersonalField.SEX, sex);
		if (bloodType) instance.set(MemberPersonalField.BLOOD_TYPE, bloodType);
		if (institution?.[0]) instance.set(MemberAcademicField.INSTITUTION, toInstitutionSnapshot(institution[0]));
		if (address) instance.set(MemberContactField.ADDRESS, address);
        if (grade) instance.set(MemberAcademicField.GRADE, grade);
        if (studyField) instance.set(MemberAcademicField.FIELDS, [studyField]);
		if (referrers?.length) instance.set(MemberPublicField.REFERRERS, referrers.map(v => v.id));

		const actions: FormFeedbackActions = [];
		let type: FeedbackType;
		let heading: string;
		let body: JSX.Element | string;

		try {
			if (!uid) throw new Error("uid must be non-null before continuing registration");
			const res = await instance.create(uid);

			let avatarHandled: boolean;
			const onAvatarUploadFailed = (err: Error) => console.error(`error uploading member avatar [cause: ${err}]`);
			try {
				avatarHandled = !avatar || (!res.alreadyExists && await uploadMemberAvatar(res.id, avatar) !== undefined);
			} catch (error: any) {
				onAvatarUploadFailed(error);
				avatarHandled = false;
			}
			
			onRegistered?.(res);

			actions.push(
				<CopyButton key="registration-feedback-action-copy_id" content={res.id}/>,
				{
					variant: "outline-primary",
					key: "registration-feedback-action-visit_fb",
					text: "Visit our Facebook page",
					onClick: () => window.open(`https://fb.me/${appConfigs.platforms.facebook.username}`, "_blank"),
				},
			);

			if (!(res.alreadyExists || avatarHandled)) {
				actions.push(<AvatarUploadButton 
					key="registration-feedback-action-reupload_avatar"
					mid={res.id}
					avatar={avatar}
					defaultStatus="avatar:error"
					onUploadFailed={onAvatarUploadFailed}
				/>);
			}

			if (res.alreadyExists) {
				type = FeedbackType.INFO;
				heading = "Similar response is already in our system";
				body = <p>If that was not expected, please check for typos in your response and submit again. Your <abbr title="Member ID">MID</abbr> was <em>{res.id}</em>.</p>
			} else {
				type = FeedbackType.SUCCESS;
				heading = "We've received your response"
				body = <>
					<p>You&apos;ve been registered successfully.{avatarHandled ? "" : " However, there was a problem saving your photo."} Thanks for your interest.</p>
					<p>Please take a note of your <abbr title="Member ID">MID</abbr>: <em>{res.id}</em>. You should use this ID when making any donations.</p>
				</>
			}
		} catch (err: any) {
			onError?.(err);

			type = FeedbackType.ERROR;
			heading = "Sorry, we couldn't save your response";
			body = <p>Something went wrong internally. Our team has been notified and are already working on to get it right as soon as possible.</p>
			actions.push(<RetryButton key="registration-feedback-action-retry" onClick={submit} />);
		}

		if (type !== FeedbackType.SUCCESS) {
			body = <>
				{body}
				{getAssist()}
			</>
		}

		setFeedback({
			type: type,
			heading: heading,
			message: body,
			actions: actions,
		});

		return undefined;
	}, [address, avatar, bloodType, dob, email, facebook, grade, institution, name, onError, onRegistered, post, referrers, sex, studyField, uid]);

	return <SectionForm 
		sections={sectionMapping}
		onSubmit={submit}
		progressProps={{ 
			prevText: "Back",
			feedback: feedback,
		}}
        onSectionChange={onSectionChange}
	/>
}

export type MemberElement = "name" | "avatar" | "post" | "email" | "facebook" | "address" | "dob" | "sex" | "bloodType" | 
    "institution" | "grade" | "field_of_study" | "referrers";

export interface RegistrationControlProps {
	uid?: string,
	onRegistered?: (result: MemberCreateResult) => unknown,
	onError?: (err: Error) => unknown,
    onSectionChange?: (section: Section) => unknown
}

interface RetryButtonProps extends Omit<ButtonProps, "onClick"> {
	onClick?: ButtonProps["onClick"] | ((evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => PromiseLike<unknown>),
}

interface AvatarUploadButtonProps extends ButtonProps {
	mid?: WithCallable<string | undefined>,
	avatar?: WithCallable<Blob | undefined>,
	onUploaded?: (avatar: UploadResult) => unknown,
	onUploadFailed?: (err: Error) => unknown,
	defaultStatus?: AvatarStatus,
	status?: AvatarStatus,
}