import { ImageLocator, useMediaQuery } from "@hashimukh/react-hooks";
import { Background, PrintContext, PrintContextInterface, RouteContext, ScrollToTop } from "@hashimukh/stardust";
import React, { useMemo, useState } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { About } from "./About";
import { Blog } from "./Blog";
import { DetachProperty } from "./DetachProperty";
import { MessageRequest } from "./MessageRequest";
import { NoMatch } from "./NoMatch";
import { Policies } from "./Policies";
import { Profile } from "./Profile";
import { Registration } from "./Registration";
import "./res/styles/app.scss";
import { SignIn } from "./SignIn";
import { Unsubscribe } from "./Unsubscribe";
import { useBackground } from "./utils/useBackground";
import { useRouter } from "./utils/useRouter";

const Routes = () => {
    const router = useRouter();
    
    return <RouteContext.Provider value={router}>
        <ScrollToTop />
        <Switch>
            <Route path="/blog" render={() => <Blog />} />
            <Route path="/profile" render={() => <Profile />} />
            <Route exact path="/"><Redirect to="/blog" /></Route>
            <Route exact path="/sign-in" render={() => <SignIn />} />
            <Route exact path="/registration" render={() => <Registration />} />
            <Route exact path="/sign-up"> <Redirect to="/registration" /> </Route>
            <Route exact path="/policies" render={() => <Policies />} />
            <Route exact path="/about" render={() => <About />} />
            <Route exact path="/dashboard"><Redirect to="/" /></Route>
            <Route exact path="/unlink" render={() => <DetachProperty />} />
            <Route exact path="/unsubscribe" render={() => <Unsubscribe />} />
            <Route exact path="/contact" render={() => <MessageRequest />} />
            <Route path="*" render={() => <NoMatch />} />
        </Switch>
    </RouteContext.Provider>
}

export const App: React.FunctionComponent = () => {
    const bgPhoto = useBackground();
    const printMediaQuery = useMediaQuery("print");

    const [printing, setPrinting] = useState(printMediaQuery);
	const [displayBgPhoto, setDisplayBgPhoto] = useState(true);
  
    const prints: PrintContextInterface = useMemo(() => ({
      enabled: printMediaQuery || printing,
      setEnabled: setPrinting,
    }), [printMediaQuery, printing]);

    const backgrounds: BackgroundContextInterface = useMemo(() => ({
        image: bgPhoto,
        displayImage: displayBgPhoto,
        setDisplayImage: setDisplayBgPhoto,
    }), [bgPhoto, displayBgPhoto])
    
    return <Router>
        <BackgroundContext.Provider value={backgrounds}>
            <PrintContext.Provider value={prints}>
                <Routes />
            </PrintContext.Provider>
        </BackgroundContext.Provider>
    </Router>
}

export const BackgroundContext = React.createContext<BackgroundContextInterface>({
    displayImage: false,
    setDisplayImage: () => { /* no-op */}
  });
  
export interface BackgroundContextInterface {
  image?: ImageLocator | Background,
  displayImage: boolean,
  setDisplayImage: (bool: boolean) => unknown,
}