import { appConfigs } from "@hashimukh/core-js";
import { FeedbackType, FormFeedbackProps, FormProgress, FormStatus, Link, SimpleForm } from "@hashimukh/stardust";
import { serverTimestamp, setDoc } from "firebase/firestore";
import React, { useCallback, useState } from "react";
import Form from "react-bootstrap/Form";
import { AppPage, PageMeta } from "./AppPage";
import { CopyButton } from "./components/common/CopyButton";
import { EmailField as EmailInput } from "./components/common/EmailField";
import { ExpandableTextField } from "./components/common/ExpandableTextField";
import { NameField as NameInput } from "./components/common/NameField";
import { generateTicketId, getMessageRequest, MessageRequestData, MessageRequestField } from "./db/models/MessageRequest";
import { DBObject } from "./db/objects/common";
import { EmailField } from "./db/objects/email";
import { NameField } from "./db/objects/name";

const metas: PageMeta = {
	title: "Contact - Hashimukh"
}

export const MessageRequest: React.FunctionComponent = () => {
	const [name, setName] = useState<string>();
	const [email, setEmail] = useState<string>();
	const [message, setMessage] = useState<string>();

	const [status, setStatus] = useState(FormStatus.NONE);
	const [feedback, setFeedback] = useState<FormFeedbackProps>();

	const handleSubmit = useCallback(async () => {
		setStatus(FormStatus.SUBMITTING);

		const ref = getMessageRequest();
		const ticket = generateTicketId();
		
		const data: DBObject<MessageRequestData> = {
			[MessageRequestField.TICKET_ID]: ticket,
			[MessageRequestField.TIMESTAMP]: serverTimestamp(),
		};

		if (name) data[MessageRequestField.NAME] = { [NameField.DISPLAY]: name };
		if (email) data[MessageRequestField.EMAIL] = { [EmailField.ADDRESS]: email };
		if (message) data[MessageRequestField.MESSAGE] = message;

		try {
			await setDoc(ref, data);
			setStatus(FormStatus.SUBMITTED);
			setFeedback({
				type: FeedbackType.SUCCESS,
				heading: "Message received",
				message: <>Thanks for contacting us. Our team will look into it as soon as possible. 
					For future references, please use the following ticket ID: <i>{ticket}</i>.</>,
				actions: [
					<CopyButton key="copy-ticket" content={ticket}>Copy Ticket</CopyButton>
				]
			})
		} catch (err) {
			console.error(`error capturing message request [cause: ${err}]`);
			setStatus(FormStatus.NONE);
			setFeedback({
				type: FeedbackType.ERROR,
				heading: "Oops! Something went wrong.",
				message: <>We couldn&apos;t save your message. Please try again later. Alternatively, you can also use any of the options below to reach us.</>,
				actions: [
					{ key: "email", text: "Email us", variant: "outline-secondary", href: `mailto:${appConfigs.contacts.email}` },
					{ key: "call", text: "Call now", variant: "outline-secondary", href: `tel:${appConfigs.contacts.number}` },
				]
			})
		}
	}, [email, message, name]);

	const disableChange = status !== FormStatus.NONE;
	return <AppPage
		heading="Contact us"
		subheading="Got a question, request, or perhapse a complaint against us? Leave a message below."
		metas={metas}
	>
		<Form>
			<SimpleForm>
				<NameInput 
					defaultValue={name}
					onNameChanged={setName}
					disabled={disableChange}
				/>
				<EmailInput
					description="When provided, you're allowing us to reach you regarding contents you mention below."
					defaultValue={email} 
					onEmailChanged={setEmail} 
					disabled={disableChange} 
				/>
				<ExpandableTextField 
					label="Message"
					description={<>Tips: Need to include photos? Use <Link href="https://getlink.vercel.app" newTab>Get Link</Link> or <Link href="https://photos.google.com" newTab>Google Photos</Link> to host and share a link with us.</>}
					defaultValue={message}
					rows={5}
					max={1000}
					onChanged={setMessage}
					disabled={disableChange}
					required
				/>
			</SimpleForm>
			<FormProgress 
				currentPos={0}
				sectionCount={1}
				hideProgressIndicator
				info={<p>Information you provide here will be used according to our <Link href="/policies#contact-policies" newTab>contact policies</Link>.</p>}
				submitText="Submit"
				status={status}
				onSubmit={handleSubmit}
				feedback={feedback}
				disableNext={disableChange || !message}
			/>
		</Form>
	</AppPage>
}