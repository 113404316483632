import { mergeClasses } from "@hashimukh/stardust";
import FormGroup from "react-bootstrap/FormGroup";
import FormSelect, { FormSelectProps } from "react-bootstrap/FormSelect";
import { BsPrefixRefForwardingComponent } from "react-bootstrap/helpers";
import { Sex } from "../../db/objects/sex";
import { FormLabel } from "./FormLabel";
import { NONE, None, onSelectCallback } from "./PostControl";
import React from "react";

export const SexControl: BsPrefixRefForwardingComponent<"select", SexControlProps> = (props) => {
	const {
		className,
		controlClassName,
        onChange,
        onSexSelect,
		required,
		...rest
	} = props;
	
	return <FormGroup className={mergeClasses("mb-3", className)}>
		<FormLabel required={required}>Sex</FormLabel>
		<FormSelect
			className={controlClassName}
			aria-label="Are you male, female or others"
            onChange={evt => {
                onChange?.(evt);
                onSelectCallback(evt, onSexSelect);
            }}
			required={required}
			{...rest}
		>
			<option value={NONE} disabled={required}>Select your sex</option>
			<option value={Sex.MALE}>Male</option>
			<option value={Sex.FEMALE}>Female</option>
		</FormSelect>
	</FormGroup>
}

export interface SexControlProps extends FormSelectProps {
	controlClassName?: string,
	defaultValue?: Sex | None,
    onSexSelect?: (sex?: Sex | undefined) => unknown,
}