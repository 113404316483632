// these are merely unique, non-secret identifier for project objects. So their exposure may be
// inconsequential. That includes the api keys.

import { FirebaseOptions } from "firebase/app";

export const logcatConfig: FirebaseOptions = {
    apiKey: process.env.REACT_APP_API_KEY_LOGCAT,
    authDomain: "project-logcat.firebaseapp.com",
    databaseURL: "https://project-logcat.firebaseio.com",
    projectId: "project-logcat",
    storageBucket: "project-logcat.appspot.com",
    messagingSenderId: "102839130340",
    appId: "1:102839130340:web:bd374099965970b87034f1",
    measurementId: "G-62F27V0N4F"
};

export const handoutConfig: FirebaseOptions = {
    apiKey: process.env.REACT_APP_API_KEY_HANDOUT,
    authDomain: "project-handout.firebaseapp.com",
    databaseURL: "https://project-handout.firebaseio.com",
    projectId: "project-handout",
    storageBucket: "project-handout.appspot.com",
    messagingSenderId: "13593788399",
    appId: "1:13593788399:web:b1129d823155a5fb90801a",
    measurementId: "G-WYRV6Q9XKQ"
};