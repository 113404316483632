import { copyToClipboard } from "@hashimukh/core-js";
import { Button } from "@hashimukh/stardust";
import React, { useCallback, useEffect, useState } from "react";
import FormControl from "react-bootstrap/FormControl";
import InputGroup, { InputGroupProps } from "react-bootstrap/InputGroup";

const states: Record<CopyState, string> = {
	none: "Copy",
	copied: "Copied",
	failed: "Failed",
}

export const CopyableText: React.FunctionComponent<CopyableTextProps> = ({ autoCopy, children, ...rest }) => {
	const [state, setState] = useState<CopyState>("none");

	const copyText = useCallback(async () => {
		if (await copyToClipboard(children)) setState("copied");
		else setState("failed");
	}, [children]);

	useEffect(() => {
		if (autoCopy && state === "none") copyText();
	}, [autoCopy, copyText, state]);

	return <InputGroup {...rest}>
		<FormControl 
			className="user-select-all"
			type="text"
			placeholder={children}
			readOnly
		/>
		<Button variant="outline-secondary" onClick={copyText}>
			{states[state]}
		</Button>
	</InputGroup>
}

type CopyState = "none" | "copied" | "failed";

export interface CopyableTextProps extends InputGroupProps {
	children: string,
	autoCopy?: boolean
}