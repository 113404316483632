import React from "react";
import ListGroup, { ListGroupProps } from "react-bootstrap/ListGroup";
import "../../res/styles/fancy-list.scss";

export const FancyList: React.FunctionComponent<FancyListProps> = (props) => {
	const {
		children,
		heading,
		...rest
	} = props;

	return <>
		{heading && typeof heading === "string" ? <small className="list-heading">{heading}</small> : heading}
		<ListGroup variant="flush" {...rest}>
			{children}
		</ListGroup>
	</>
}

export interface FancyListProps extends ListGroupProps {
	heading?: React.ReactNode,
}