export function toFile(blob: Blob, fileName: string): File {
    return new File([blob], fileName);
}

export async function getImageDimension(src: Blob | string): Promise<[number, number]> {
	if (typeof src === "string") return getImageDimension0(src);

    return new Promise((res, rej) => {
        const fr = new FileReader();
        fr.onload = (async () => {
            try {
                if (!fr.result) throw new Error("FileReader result is null.");
                res(await getImageDimension0(fr.result.toString()));
            } catch (error) {
                rej(error);
            }
        });
        fr.onerror = () => rej(new Error("image could not be read withh FileReader."));
    
        fr.readAsDataURL(src);
    })
}

async function getImageDimension0(src: string): Promise<[number, number]> {
    const image = new Image();
	return new Promise((res, rej) => {
		image.onload = () => {
			const { width, height } = image;
			res([width, height]);
		};
        image.onerror = () => rej(new Error("image could not be loaded"));
		
		image.src = src;
	});
}