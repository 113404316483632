import { Region } from "@hashimukh/firebase-js";
import { getApp } from "firebase/app";
import { getFunctions, httpsCallable, HttpsCallableResult } from "firebase/functions";
import React, { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import { AppPage, PageMeta } from "./AppPage";
import { Loading } from "./components/common/Loading";
import { Backend } from "./utils/backend";
import { useQueryParams } from "./utils/useQueryParams";

const metas: PageMeta = {
	title: "Detach properties - Hashimukh"
}

const InvalidParams: React.FunctionComponent = () => {
	return <Alert variant="danger">
		Invalid query parameters!
	</Alert>
}

const Failure: React.FunctionComponent = () => {
	return <Alert variant="danger">
		Sorry, something went wrong!
	</Alert>
}

export const DetachProperty: React.FunctionComponent = () => {
	const params = useQueryParams();

	const [body, setBody] = useState<JSX.Element>(<Loading />);

	const entity = params.get("from");
	const id = params.get("id");
	const property = params.get("property");
	const value = params.get("value");
	const origin = params.get("origin") || params.get("reference");

	useEffect(() => {
		if (!entity || !id || !property || !origin) {
			setBody(<InvalidParams />);
			return;
		}

        setBody(<Loading />);

        let detachPromise: Promise<HttpsCallableResult<unknown>> | undefined;
        let successMssg: React.ReactNode;

		if (entity === "member") {
			const func = getFunctions(getApp(Backend.HANDOUT), Region.HONG_KONG);
			const unlink = httpsCallable(func, "memberTasks-unlink");
            detachPromise = unlink({ id, property, value: value || undefined, source: origin });

			if (property === "email_address") {
                successMssg = "Email address unlinked successfully!";
			}
		} else if (entity === "mssg_rqst") {
            const func = getFunctions(getApp(Backend.LOGCAT), Region.IOWA);
            const unlink = httpsCallable(func, "messageRequestTasks-unlink");
            detachPromise = unlink({ id, property, value: value || undefined, origin });

            if (property === "email_address") {
                successMssg = "Email address unlinked from message successfully."
            }
        }

        if (!detachPromise) {
            setBody(<InvalidParams />);
        } else {
            detachPromise.then(() => setBody(<Alert>{successMssg || "Unlink successful."}</Alert>)).catch(err => {
                console.error(`unlink operation failed [cause: ${err}]`);
                setBody(<Failure />);
            });
        }
	}, [entity, property, id, origin, value]);

	return <AppPage heading="Unlink property" metas={metas}>
		{body || <Loading />}
	</AppPage>
}