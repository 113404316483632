import { mergeClasses } from "@hashimukh/stardust";
import React from "react";
import ListGroupItem, { ListGroupItemProps } from "react-bootstrap/ListGroupItem";
import { CheapItem, CheapItemPlaceholder, CheapItemProps } from "./CheapItem";

export const FancyItemPlaceholder: React.FunctionComponent = () => {
	return <ListGroupItem className="border rounded mb-3 py-3 px-0">
		<CheapItemPlaceholder flush />
	</ListGroupItem>
}

export const FancyItem: React.FunctionComponent<FancyItemProps> = (props) => {
	const {
		className,
		icon,
		primaryText,
		secondaryText,
		optionalText,
		...rest
	} = props;

	return <ListGroupItem className={mergeClasses("border rounded mb-3 py-3 px-0", className)} {...rest}>
		<CheapItem flush icon={icon} primaryText={primaryText} secondaryText={secondaryText} optionalText={optionalText} />
	</ListGroupItem>
}

export interface FancyItemProps extends ListGroupItemProps {
	icon?: CheapItemProps["icon"],
	primaryText?: CheapItemProps["primaryText"],
	secondaryText?: CheapItemProps["secondaryText"],
	optionalText?: CheapItemProps["optionalText"],
}