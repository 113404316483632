import { Timestamp } from "firebase/firestore";
import { AvatarData } from "../../objects/avatar";
import { RegistrationMethod } from "../../objects/common";
import { FundRaiseReport } from "../../objects/fundReports";
import { Post } from "../../objects/post";

export enum MemberPublicField {
    AVATAR = "avatar",
    NAME = "name",
    REFERRERS = "referrers",
    IS_VERIFIED = "is_verified",
    JOIN_TIME = "joining_time",
    POST = "post",
    REG_METHOD = "registration_method",
    POINT = "contribution_point",
    REFER_COUNT = "refer_count",
    FUND_RAISED = "donations",
    UID = "uid",
}

export interface MemberPublicData extends Partial<Record<MemberPublicField, unknown>> {
    [MemberPublicField.AVATAR]?: AvatarData,
    [MemberPublicField.NAME]?: string,
    [MemberPublicField.REFERRERS]?: string[],
    [MemberPublicField.IS_VERIFIED]?: boolean,
    [MemberPublicField.JOIN_TIME]?: Timestamp,
    [MemberPublicField.POST]?: Post,
    [MemberPublicField.REG_METHOD]?: RegistrationMethod,
    [MemberPublicField.POINT]?: number,
    [MemberPublicField.REFER_COUNT]?: number,
    [MemberPublicField.FUND_RAISED]?: FundRaiseReport,
    [MemberPublicField.UID]?: string,
}