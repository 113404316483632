import { Link } from "@hashimukh/stardust";
import React from "react";
import Carousel from "react-bootstrap/Carousel";
import CarouselCaption from "react-bootstrap/CarouselCaption";
import CarouselItem from "react-bootstrap/CarouselItem";
import { AppPage, PageMeta } from "./AppPage";
import { MembershipInvitation } from "./components/common/MembershipInvitation";
import "./res/styles/about.scss";

const metas: PageMeta = {
	title: "About - Hashimukh",
	description: "Who we are, what we do, and what's our goal.",
	image: "https://hashimukhstorage.blob.core.windows.net/public/IMG_4456_1080.jpg",
}

const carouselItems: { src: string, alt: string, caption: React.ReactNode }[] = [
	{
		src: "https://hashimukhstorage.blob.core.windows.net/public/evt_1_hedzvk.jpg",
		alt: "Foundation president addressing the Madrasha students.",
		caption: <>First event conducted by the newly formed Foundation. <time dateTime="2019-06-01">June, 2019</time>, at Quaderiya Tayabiya Taheriya Sunniya Madrasha, Tangail.</>,
	},
	{
		src: "https://hashimukhstorage.blob.core.windows.net/public/IMG_4456_1080.jpg",
		alt: "Bags of food items for disaster affected people",
		caption: <>Disaster recovery aids for flood affected people of Elasin in <time dateTime="2019-08-05">August, 2019</time>.</>,
	},
	{
		src: "https://hashimukhstorage.blob.core.windows.net/public/IMG_20191226_221236_1080.jpg",
		alt: "Distributing blanket to homeless a woman",
		caption: <>Distributing blanket to the poor in the <time dateTime="2019-12-26">winter of 2019</time>.</>,
	},
	{
		src: "https://hashimukhstorage.blob.core.windows.net/public/DSC_0139_2_1080.jpg",
		alt: "Two row of people in line to get their winter cloths",
		caption: <>Winter clothes (collected from Hashimukh members and people of general) for the poor people of Borni Kishori.</>,
	},
	{
		src: "https://hashimukhstorage.blob.core.windows.net/public/IMG20210504181032_1080.jpg",
		alt: "Bags of garbage collected from pond",
		caption: <>Removing garbage from the banks of Choyani Pukur.</>,
	}
]

export const About: React.FunctionComponent = () => {
	return <AppPage
		heading="About us"
		subheading="We work for a cause."
		metas={metas}
	>
		<Carousel className="mb-3">
			{carouselItems.map((item, i) => <CarouselItem key={`${i}`}>
				<div className="carousel-img-container ratio ratio-4x3">
					<img className="carousel-image img-fluid w-100 rounded" src={item.src} alt={item.alt} />
				</div>
				<CarouselCaption>{item.caption}</CarouselCaption>
			</CarouselItem>)}
		</Carousel>
		<h4>Our story</h4>
		<p>In mid-2019, Hashimukh was founded by a group of students from <Link newTab href="http://www.bindubashinigovboysschool.edu.bd/">Bindu Basini Govt. Boys&apos; High School</Link>{" "}
			with an aim to help people who confront difficulties to recover from disasters, and to improve the 
			quality of their lives. Initially, its&apos; members were from <Link newTab href="http://www.bindubashinigovboysschool.edu.bd/">Bindu Basini Govt. Boys&apos; High School</Link>{" "}
			but soon after then it extended to <Link newTab href="https://plast.edu.bd/">Police Lines Adarsha High School</Link>. Now, we are more than a hundred members 
			from both high school and colleges of all over Bangladesh successfully going towards our goal.</p>
		<h4>Objectives</h4>
		<div>
			<p>Hashimukh Foundation is a humanitarian organization working actively to improve the quality of underprivileged lives regardless of their religious status, or political views. 
				Our objectives are as follows:
			</p>
			<ul>
				<li>Create educational opportunities for the underprivileged children.</li>
				<li>Organize and participate in social welfare events.</li>
				<li>Help people who confront difficulties to recover from disasters.</li>
			</ul>
		</div>
		<MembershipInvitation />
	</AppPage>
}