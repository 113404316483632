import { AddressData } from "../../objects/address";
import { Email } from "../../objects/email";
import { FacebookData } from "../../objects/facebook";
import { PhoneNumber } from "../../objects/phoneNumber";

export const MEMBER_CONTACT_DOC_ID = "contact";

export enum MemberContactField {
    PHONE = "phone",
    EMAIL = "email",
    FACEBOOK = "facebook",
    ADDRESS = "address",
    UID = "uid",
}

export interface MemberContactData extends Partial<Record<MemberContactField, unknown>> {
    [MemberContactField.PHONE]?: PhoneNumber,
    [MemberContactField.EMAIL]?: Email,
    [MemberContactField.FACEBOOK]?: FacebookData,
    [MemberContactField.ADDRESS]?: AddressData,
    [MemberContactField.UID]?: string,
}