import { Button, mergeClasses } from "@hashimukh/stardust";
import React, { useCallback } from "react";
import Placeholder from "react-bootstrap/Placeholder";
import { PlaceholderButtonProps } from "react-bootstrap/PlaceholderButton";
import { useRouter } from "../../utils/useRouter";

export const TopicPlaceholder: React.FunctionComponent<PlaceholderButtonProps> = ({ className, ...rest }) => {
	return <Placeholder 
		className={mergeClasses("badge d-inline-block bg-secondary btn btn-secondary", className)} 
		xs={2} 
		{...rest} />
}

export const Topic: React.FunctionComponent<TopicProps> = ({ topicId, children }) => {
	const router = useRouter();

	const handleTopicsClick = useCallback((evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		const topic = evt.currentTarget.getAttribute("data-topic");
		if (!topic) {
			console.warn(`topic not set. set topic with 'data-topic' attribute`);
			return;
		}

		router.push(`/blog?topics=${topic}`);
	}, [router]);

	return <Button 
		className="badge text-uppercase me-1" 
		variant="secondary"
		onClick={handleTopicsClick}
		data-topic={topicId}
	>
		{children || topicId}
	</Button>
}

export interface TopicProps {
	topicId: string,
}