import { Falsy } from "@hashimukh/core-js";
import { mergeClasses, Required } from "@hashimukh/stardust";
import { getDownloadURL, ref, StorageReference, uploadBytesResumable } from "firebase/storage";
import React, { LegacyRef, useCallback, useEffect, useRef, useState } from "react";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import Image, { ImageProps } from "react-bootstrap/Image";
import Placeholder from "react-bootstrap/Placeholder";
import { MAX_SIZE_AVATAR } from "../../db/models/member/Member";
import { generateAvatarName } from "../../db/objects/avatar";
import { toFile } from "../../utils/files";
import { PhotoEditor, PhotoEditorProps, reduceImage } from "../common/PhotoEditor";
import { Shimmer } from "../common/Shimmer";

import "./../../res/styles/avatar.scss";

export const NO_AVATAR = "https://hashimukhstorage.blob.core.windows.net/public/res/icon/ic_fluent_person_white.svg";

export const AvatarPlaceholder: React.FunctionComponent<React.AllHTMLAttributes<HTMLDivElement>> = ({ className }) => {
    return <div className={mergeClasses("avt d-flex flex-column justify-content-center", className)}>
        <div className="avt-container d-inline-flex mx-auto position-relative">
            <Shimmer pattern={<Placeholder 
                className="avt-img-placeholder rounded-circle" 
            />} />
        </div>
    </div>
}

const Avatar: React.FunctionComponent<AvatarProps> = ({
	className,
	coinClassName,
	defaultValue,
	defaultBlob,
	reference,
	onClick,
	onStatus,
	editable,
	onBlobChanged,
	onUploadCompleted,
	disabled,
	required,
	onLoaded,
	hasAvatar,
	...rest
}) => {
	const pickerHandleRef: LegacyRef<HTMLInputElement> = useRef(null);

	const [avatar, setAvatar] = useState(defaultValue?.toString());
	const [blob, _setBlob] = useState(defaultBlob);
	const [status, _setStatus] = useState<AvatarStatus>();
	const [progress, setProgress] = useState(0);
    const [rawAvatar, setRawAvatar] = useState<File | string>();
    const [loadEditor, setLoadEditor] = useState(false);

	// to ensure upload only happens when blob/reference is changed, we are setting
	// certain props to stateless object
	const setStatus = useRef<AvatarProps["onStatus"]>();
	setStatus.current = s => {
		_setStatus(s);
		onStatus?.(s);
	};

	const afterUpload = useRef<AvatarProps["onUploadCompleted"]>();
	afterUpload.current = onUploadCompleted;

	const setBlob = useCallback((value: Blob | undefined) => {
		_setBlob(value);
		onBlobChanged?.(value);
	}, [onBlobChanged]);

    const onEdited = useCallback<NonNullable<PhotoEditorProps["onDone"]>>(output => {
        setBlob(output);
        setLoadEditor(false);
        setRawAvatar(undefined);
    }, [setBlob]);

    const onEditCancelled = useCallback<NonNullable<PhotoEditorProps["onCancelled"]>>(() => {
        setLoadEditor(false);
        setRawAvatar(undefined);
    }, []);

	useEffect(() => {
		if (!reference || !hasAvatar) return;

		// avatar won't be replaced if one is already chosen
		getDownloadURL(reference).then(url => setAvatar(c => c || url)).catch((err) => {
			if (err.code === "storage/object-not-found") return;
			console.error(`error getting current avatar [cause: ${err}]`);
		});
	}, [hasAvatar, reference]);

	// using effects enable us to allow user to change/cancel uploading avatar on air
	// with the help of auto-cleanup function.
	useEffect(() => {
		if (!blob) return;

		if (blob.size > MAX_SIZE_AVATAR) {
			console.warn(`too large [accepted: ${MAX_SIZE_AVATAR}; actual: ${blob.size}]`);
			setStatus.current?.("avatar:too-large");
			return;
		}

		const url = URL.createObjectURL(blob);
		setAvatar(url);

		if (!reference) {
			setStatus.current?.("avatar:awaiting-upload");
			return;
		}

		const newRef = ref(reference.parent || reference, generateAvatarName(blob));
		const subscription = uploadBytesResumable(newRef, blob);

		setStatus.current?.("avatar:uploading");
		subscription.on("state_changed", snapshot => {
			switch (snapshot.state) {
				case "running":
					setProgress(Math.floor(snapshot.bytesTransferred / snapshot.totalBytes * 100));
					break;
				case "canceled":
					setStatus.current?.("avatar:canceled");
					break;
			}
		}, err => {
			console.error(`error uploading avatar [cause: ${err}]`);
			setStatus.current?.("avatar:error");
		}, () => {
			console.log(`avatar upload complete`);
			setStatus.current?.("avatar:success");
			afterUpload.current?.(blob);
		});

		return () => {
			subscription.cancel();
			URL.revokeObjectURL(url);
			setStatus.current?.("avatar:rest");
		};
	}, [blob, reference]);

	useEffect(() => {
		if (avatar?.startsWith("https://")) { // loaded from server; not a blob chose by user
			onLoaded?.(avatar);
		}
	}, [avatar, onLoaded]);

	return <div className={mergeClasses("avt d-flex flex-column justify-content-center", className)}>
        {loadEditor && <PhotoEditor
            image={rawAvatar}
            onDone={onEdited}
            onCancel={onEditCancelled}
            show
        />}
		<input
			ref={pickerHandleRef}
			className={"d-none"}
			type="file"
			multiple={false}
			accept={"image/jpeg,image/png"}
			disabled={disabled || !editable}
			value=""
			onChange={async (evt) => {
                const picked = evt.target.files?.[0];
                if (!picked) return setBlob(undefined);

                setLoadEditor(true);

                const avatarName = generateAvatarName(picked);
                try {
                    const reduced = await reduceImage(picked);
                    setRawAvatar(toFile(reduced, avatarName));
                } catch (err) {
                    console.warn(`unable to reduce picked file. continuing with original. [cause: ${err}]`);
                    setRawAvatar(toFile(picked, avatarName));
                }
            }}
		/>
		<div className="avt-container d-inline-flex mx-auto position-relative">
			<Image
				role={!disabled ? "button" : "img"}
				className={mergeClasses("avt-img bg-light fit-cover", coinClassName)}
				src={avatar || NO_AVATAR}
				width={192}
				height={192}
				onClick={disabled || !editable ? undefined : (evt) => {
					pickerHandleRef.current?.click();
					onClick?.(evt);
				}}
				alt={avatar ? "selected avatar" : "choose your avatar"}
				roundedCircle
				data-editable={editable}
				data-disabled={disabled}
				{...rest}
			/>
			<Collapse in={status === "avatar:uploading"}><div>
				<Badge className="position-absolute top-0 end-0 mt-3" bg="info">{progress}%</Badge>
			</div></Collapse>
			{editable && <div className="position-absolute bottom-0 start-0 mb-3 w-50">
				<Button
					className=""
					variant="secondary"
					onClick={() => pickerHandleRef.current?.click()}
					size="sm"
					disabled={disabled}
				>
					{status === "avatar:uploading" ? "Uploading" : "Upload"}
				</Button>
				{required && <Required />}
			</div>}
		</div>
	</div>
}

export default Avatar;

export type AvatarStatus = "avatar:uploading" |
	"avatar:awaiting-upload" |
	"avatar:success" |
	"avatar:canceled" |
	"avatar:error" |
	"avatar:too-large" |
	"avatar:rest";

export interface AvatarProps extends Omit<ImageProps, "onError" | "onChange"> {
	coinClassName?: string,
	defaultBlob?: Blob,
	reference?: StorageReference | Falsy,
	editable?: boolean,
	disabled?: boolean,
	required?: boolean,
	onStatus?: (status: AvatarStatus) => unknown,
	onBlobChanged?: (blob: Blob | undefined) => unknown,
	onUploadCompleted?: (blob: Blob) => unknown,
	onLoaded?: (url: string) => unknown,
	hasAvatar?: boolean,
}