import { SignInControl as Base, SignInControlProps as BaseProps } from "@hashimukh/stardust";
import { getAuth } from "firebase/auth";
import React, { useRef } from "react";
import { getAuthApp } from "../utils/authUtils";

const reCaptchaElement = "recaptcha-containr";

export const SignInControl: React.FunctionComponent<SignInControlProps> = (props) => {
	const {
		...rest
	} = props;

	const auth = useRef(getAuth(getAuthApp()));

	return <div>
		<div id={reCaptchaElement}></div>
		<Base 
			auth={auth.current} 
			reCaptchaElement={reCaptchaElement}
			{...rest} />
	</div>
}

export interface SignInControlProps extends Omit<BaseProps, "auth" | "reCaptchaElement"> {

}