import { getMonth, getYear } from 'date-fns';
import range from "lodash.range";
import React, { useCallback, useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import FormControl, { FormControlProps } from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Stack from "react-bootstrap/Stack";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import "../../res/styles/date-picker.scss";

const popperModifiers: ReactDatePickerProps["popperModifiers"] = [
  {
    name: "arrow",
    options: { padding: 10 }
  }
]

const years = range(getYear(new Date()), 1969, -1);
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const YearSelect: React.FunctionComponent<Pick<CustomHeaderProps, "date" | "changeYear">> = (props) => {
  const { date, changeYear } = props;

  const selectedYear = useMemo(() => getYear(date), [date]);
  const yearOptions = useMemo(() => {
    return years.map(y => <Dropdown.Item key={y} eventKey={y} active={selectedYear === y}>{y}</Dropdown.Item>);
  }, [selectedYear]);

  const handleChange = useCallback((key: string | null) => {
	  changeYear(+(key || 0));
  }, [changeYear]);

  return <Dropdown onSelect={handleChange}>
    <Dropdown.Toggle className="flex-grow-1" variant="outline-secondary">
      {selectedYear}
    </Dropdown.Toggle>

    <Dropdown.Menu className="picker-menu">
      {yearOptions}
    </Dropdown.Menu>
  </Dropdown>
}

const MonthSelect: React.FunctionComponent<Pick<CustomHeaderProps, "date" | "changeMonth">> = (props) => {
  const { date, changeMonth } = props;

  const selectedMonth = useMemo(() => getMonth(date), [date]);
  const monthOptions = useMemo(() => {
    return months.map((m, i) => <Dropdown.Item key={i} eventKey={i} active={selectedMonth === i}>{m}</Dropdown.Item>);
  }, [selectedMonth]);

  const handleChange = useCallback((key: string | null) => {
	  changeMonth(+(key || 0));
  }, [changeMonth]);

  return <Dropdown onSelect={handleChange}>
    <Dropdown.Toggle className="flex-grow-1" variant="outline-secondary">
      {months[selectedMonth]}
    </Dropdown.Toggle>

    <Dropdown.Menu className="picker-menu">
      {monthOptions}
    </Dropdown.Menu>
  </Dropdown>
}

const CustomHeader: React.FunctionComponent<CustomHeaderProps> = (props) => {
  const {
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled
  } = props;
  
  return <Stack className="mx-3" direction="horizontal" data-clarity-unmask="true">
    <InputGroup size="sm">
      <Button variant="outline-secondary" size="sm" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>&lt;</Button>
      <YearSelect date={date} changeYear={changeYear}  />
      <MonthSelect date={date} changeMonth={changeMonth} />
      <Button className="ms-auto" variant="outline-secondary" size="sm" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>&gt;</Button>
    </InputGroup>
  </Stack>
}

function renderHeader(props: CustomHeaderProps) {
	return <CustomHeader {...props}/>
}

export const DatePicker: React.FunctionComponent<DatePickerProps> = (props) => {
	const {
		selected: _selected,
		defaultSelected,
		onChange,
		controlProps,
    openToDate,
		...rest
	} = props;

	const [selected, setSelected] = useState<Date | [Date | null, Date | null] | null>(defaultSelected !== undefined ? defaultSelected : new Date());
	const effectiveSelected = _selected !== undefined ? _selected : selected;
  const effectiveDate = Array.isArray(effectiveSelected) ? effectiveSelected[0] : effectiveSelected;

	const handleChange = useCallback<ReactDatePickerProps["onChange"]>((date, evt) => {
		setSelected(date);
		onChange?.(date, evt);
	}, [onChange]);

	return <ReactDatePicker 
    dateFormat="MMM dd, yyyy"
		selected={effectiveDate}
    openToDate={effectiveDate || openToDate}
		onChange={handleChange}
    popperModifiers={popperModifiers}
		customInput={<FormControl {...controlProps}/>}
		renderCustomHeader={renderHeader}
		{...rest}
	/>
}

export interface DatePickerProps extends Omit<ReactDatePickerProps, "onChange" | "customInput" | "renderCustomHeader"> {
	defaultSelected?: ReactDatePickerProps["selected"],
	controlProps?: FormControlProps,
	onChange?: ReactDatePickerProps["onChange"],
}

type CustomHeaderProps = Parameters<NonNullable<ReactDatePickerProps["renderCustomHeader"]>>[0];