const shortLinksEndpoint = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.REACT_APP_API_KEY_WEB}`;
const urlPrefix = "https://hashimukh.org/open";

export async function createShortLink(link: string, title: string, description: string, image: string) {
	const body = {
		dynamicLinkInfo: {
			domainUriPrefix: urlPrefix,
			link: link,
			socialMetaTagInfo: {
				socialTitle: title,
				socialDescription: description,
				socialImageLink: image,
			},
		},
		suffix: {
			option: "SHORT",
		},
	};

	console.debug(`creating shortlink at ${shortLinksEndpoint} endpoint`);

	const res = await fetch(shortLinksEndpoint, {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify(body)
	});
	if (!res.ok)
		throw new Error(`error creating dynamic link [code: ${res.status}, message: ${await res.text()}]`);
	return res.json() as Promise<ShortLinkResult>;
}

export interface ShortLinkResult {
	shortLink: string,
	previewLink: string,
}