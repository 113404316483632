import { InputField, InputFieldProps, mergeClasses } from "@hashimukh/stardust";
import React, { useMemo, useRef, useState } from "react";
import { BsPrefixRefForwardingComponent } from "react-bootstrap/helpers";
import Row from "react-bootstrap/Row";
import { AddressData, AddressField as Field, MAX_LEN_ADDRESS, MAX_LEN_CITY, MAX_LEN_POST_CODE } from "../../db/objects/address";
import { cities } from "../../db/objects/city";

export const AddressField: BsPrefixRefForwardingComponent<"input", AddressFieldProps> = (props) => {
	const {
		value: _value,
		defaultValue,
		onChange,
		onAddressChanged,
        lineOneProps,
        cityProps,
        postalCodeProps,
		...rest
	} = props;

	const [lineOne, setLineOne] = useState(defaultValue?.[Field.LINE_ONE] || "");
    const [city, setCity] = useState(defaultValue?.[Field.CITY] || "");
    const [zip, setZip] = useState(defaultValue?.[Field.POST_CODE] || "");

    const { className: lineOneClassName, ...lineOneRest } = lineOneProps || { };
    const { className: cityClassName, ...cityRest } = cityProps || { };
    const { className: postalCodeClassName, ...postalCodeRest } = postalCodeProps || { };
    
    let effLineOne = lineOne;
    let effCity = city;
    let effZip = zip;
    if (_value) {
        effLineOne = _value[Field.LINE_ONE] || "";
        effCity = _value[Field.CITY] || "";
        effZip = _value[Field.POST_CODE] || "";
    }

    const lineOneError = useRef<string>();
    const cityError = useRef<string>();
    const zipError = useRef<string>();

    const cityOptions = useMemo(() => cities.map(value => <option key={value} value={value} />), []);
    const compute = ({l1 = effLineOne, ct = effCity, zp = effZip}) => {
        const instance: AddressData = { };

        if (l1) {
            if (l1.length > MAX_LEN_ADDRESS) {
                lineOneError.current = `Address must be less than ${MAX_LEN_ADDRESS + 1} characters long.`;
            } else {
                instance[Field.LINE_ONE] = l1;
            }
        }

        if (ct) {
            if (ct.length > MAX_LEN_CITY) {
                cityError.current = `City name must be less than ${MAX_LEN_CITY + 1} characters long.`;
            } else {
                instance[Field.CITY] = ct;
            }
        }

        if (zp) {
            if (zp.length > MAX_LEN_POST_CODE) {
                zipError.current = `Postal code must be less than ${MAX_LEN_POST_CODE + 1} characters long.`;
            } else {
                instance[Field.POST_CODE] = zp;
            }
        }

        onAddressChanged?.(instance);
    };
	
	return <>
        <InputField 
            className={lineOneClassName}
            as="textarea"
            label="Home address"
            rows={1}
            value={effLineOne}
            onChange={evt => {
                const l1 = evt.currentTarget.value;
                setLineOne(l1);
        
                onChange?.(evt);
                compute({ l1 });
            }}
            errorMessage={lineOneError.current}
            {...rest}
            {...lineOneRest}
        />
        <Row xs={1} md={2}>
            <datalist id="city-options">
                {cityOptions}
            </datalist>
            <InputField 
                className={mergeClasses("col-6", cityClassName)}
                type="text"
                list="city-options"
                label="City"
                value={effCity}
                onChange={evt => {
                    const ct = evt.currentTarget.value;
                    setCity(ct);
            
                    onChange?.(evt);
                    compute({ ct });
                }}
                errorMessage={cityError.current}
                {...rest}
                {...cityRest}
            />
            <InputField 
                className={mergeClasses("col-6", postalCodeClassName)}
                type="text"
                label="Postal code"
                value={effZip}
                onChange={evt => {
                    const zp = evt.currentTarget.value;
                    setZip(zp);
            
                    onChange?.(evt);
                    compute({ zp });
                }}
                errorMessage={zipError.current}
                {...rest}
                {...postalCodeRest}
            />
        </Row>
    </>
}

export interface AddressFieldProps extends Omit<InputFieldProps, "id" | "type" | "value" | "defaultValue"> {
	value?: AddressData,
	defaultValue?: AddressData,
	onAddressChanged?: (address: AddressData | undefined) => unknown,
    lineOneProps?: InputFieldProps,
    cityProps?: InputFieldProps,
    postalCodeProps?: InputFieldProps,
}