import { onEmulator } from "@hashimukh/core-js";
import { useUser } from "@hashimukh/firebase-js";
import { Button } from "@hashimukh/stardust";
import React, { useCallback, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Collapse from "react-bootstrap/Collapse";
import Stack from "react-bootstrap/Stack";
import { getAuthApp } from "../../utils/authUtils";
import { getConfigValue } from "../../utils/configs";
import { useRouter } from "../../utils/useRouter";
import { useUserClaims } from "../../utils/useUserClaims";

const KEY_LAST_DISMISS = "dismiss_membership_invitation_timestamp";

export const MembershipInvitation: React.FunctionComponent<MembershipInvitationProps> = () => {
	const accept = getConfigValue("accept_new_membership").asBoolean();
	const lastDismiss = +(localStorage.getItem(KEY_LAST_DISMISS) || 0);

	const user = useUser(getAuthApp());
	const claims = useUserClaims(user);

	const router = useRouter();
	const [_show, setShow] = useState(() => (new Date().getTime() - lastDismiss) > (!onEmulator ? 12 * 60 * 60 * 1000 : 60 * 1000));
	const show = accept && _show && (!user || (claims && !claims.mid));

	const handleJoin = useCallback(() => {
		router.push("/registration");
	}, [router]);

	const handleCancel = useCallback(() => {
		localStorage.setItem(KEY_LAST_DISMISS, new Date().getTime().toString());
		setShow(false);
	}, []);

	return <Collapse in={show}><div><Alert className="border mt-4 mb-3 pe-3" variant="light" onClose={handleCancel} dismissible>
		<h4 className="pe-5">Love what we do?</h4>
		<p>Support our works with financial donations or via voluntary works and become a part of this wholesome family.</p>
		<Stack gap={3}>
			<Button variant="outline-primary" onClick={handleJoin}>Join now</Button>
			<Button variant="outline-secondary" onClick={handleCancel}>Maybe later</Button>
		</Stack>
	</Alert></div></Collapse>
}

export interface MembershipInvitationProps {

}