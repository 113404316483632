import { AvatarStatus } from "../../components/member/Avatar";
import { MAX_SIZE_AVATAR } from "../models/member/Member";

export const MEMBER_CURRENT_AVATAR_DIR = "current";

export const FALLBACK_AVATAR_TYPE = "image/jpeg";

export const imageFileExtMapping: Record<string, string> = {
	"image/jpeg": ".jpg",
	"image/png": ".png",
	"image/bmp": ".bmp",
	"image/heic": ".heic",
}

export enum AvatarField {
	SHOW = "show",
	NAME = "original_file_name",
	VERSION_CODE = "version_code",
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function generateAvatarName(avatar: Blob) {
	const type = imageFileExtMapping[avatar.type] || imageFileExtMapping[FALLBACK_AVATAR_TYPE];
	return `avatar${type}`;
}

export function generateAvatarUploadFeedback(status: AvatarStatus): [string | undefined, string | undefined] {
    switch (status) {
        case "avatar:success": return ["success", "Profile picture set successfully."];
        case "avatar:error": return ["danger", "Profile picture failed to upload."];
        case "avatar:too-large": return ["warning", `Profile picture is too large. Please select a photo of size less than ${Math.floor(MAX_SIZE_AVATAR / (1024 * 1024))} MB.`];
    }

    return [undefined, undefined];
}

export type AvatarSize = "96x96" | "128x128" | "256x256" | "512x512" | "1024x1024";

export interface AvatarData {
	[AvatarField.NAME]?: string,
	[AvatarField.SHOW]?: boolean,
	[AvatarField.VERSION_CODE]?: number,
}