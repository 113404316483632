import { getApp } from "firebase/app";
import { collection, CollectionReference, doc, getFirestore, Timestamp } from "firebase/firestore";
import { customAlphabet } from "nanoid";
import { Backend } from "../../utils/backend";
import { CHARS_ALPHABET_LOWER } from "../../utils/constants";
import { getRand, toAbc } from "../../utils/numbers";
import { Email } from "../objects/email";
import { Name } from "../objects/name";

export const NAMESPACE_MESSAGE_REQUEST = "81cb2fd0-f9f4-4042-9b07-d585da58e5e4";
export const MESSAGE_REQUEST_COLLECTION_ID = "message_requests";

const SHARDS = ['a', 'i'];

export function getMessageRequests(): CollectionReference<MessageRequestData> {
	return collection(getFirestore(getApp(Backend.LOGCAT)), MESSAGE_REQUEST_COLLECTION_ID);
}

export function getMessageRequest(id?: string) {
	const col = getMessageRequests();
	return id ? doc(col, id) : doc(col);
}

export function generateTicketId(time = new Date()) {
    const timestamp = toAbc(Math.floor(time.getTime() / 1000));
    const shard = SHARDS[(getRand(0, 0.2) * 10) | 0];
    
    // 0 and 1 are not included since they are rather visually similar to O and I respectively.
    return shard + timestamp + "-" + customAlphabet("23456789" + CHARS_ALPHABET_LOWER, 4)();
}

export enum MessageRequestField {
	TICKET_ID = "ticket_id",
	NAME = "name",
	EMAIL = "email",
	HAS_ATTACHMENT = "has_attachment",
	MESSAGE = "text",
	TIMESTAMP = "creation_time",
}

export interface MessageRequestData {
	[MessageRequestField.TICKET_ID]?: string,
	[MessageRequestField.NAME]?: Name,
	[MessageRequestField.EMAIL]?: Email,
	[MessageRequestField.MESSAGE]?: string,
	[MessageRequestField.HAS_ATTACHMENT]?: boolean,
	[MessageRequestField.TIMESTAMP]?: Timestamp,
}