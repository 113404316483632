import { getQueryResolveDelay, queryToHandout, Region } from '@hashimukh/firebase-js';
import { ItemPicker, ItemPickerProps, PickChange, PickerSuggestionProps, PickItem } from '@hashimukh/stardust';
import React, { useCallback, useMemo, useState } from 'react';
import { BsPrefixRefForwardingComponent } from 'react-bootstrap/helpers';
import Stack from 'react-bootstrap/Stack';
import { MemberSnapshotData, MemberSnapshotField } from '../db/models/member/Member';
import { MemberPublicField } from '../db/models/member/Public';
import { DocumentMatch } from '../db/objects/common';
import { getFriendlyPost, Post } from '../db/objects/post';

const suggestionProps: PickerSuggestionProps = {
    headingText: 'Suggested members',
    noSuggestionText: 'No results found',
}

export function toMemberSnapshhot(item: MemberPickItem): MemberSnapshotData {
    return {
        [MemberSnapshotField.ID]: item.id,
        [MemberSnapshotField.NAME]: item.name,
        [MemberSnapshotField.POST]: item.post,
    }
}

export function toMemberPickItem(data: Record<string, any>, id?: string): MemberPickItem {
    const _id = id || data[MemberSnapshotField.ID];
    const name = data[MemberPublicField.NAME] || data[MemberSnapshotField.NAME];
    const post = (data[MemberPublicField.POST] || data[MemberSnapshotField.POST]);
    const strPost = getFriendlyPost(post);

    return {
        id: _id,
        name: name || _id,
        post: post,
        content: <Stack>
            <span>{name}</span>
            {strPost && <small className="text-muted">{strPost}</small>}
        </Stack>
    }
}

const resolveSuggestions = async (input: string, currentPersonas?: MemberPickItem[]): Promise<MemberPickItem[]> => {
    console.debug(`Resolving referrer suggestions`);

    try {
        const response: Response = await queryToHandout(`query-v1_asia/members/?use=name&contains=${encodeURIComponent(input)}`, Region.HONG_KONG);
        console.log(`Matched referrer received`);
            const personas: MemberPickItem[] = [];
            
            const matches: DocumentMatch[] = JSON.parse(await response.text());
            matches.forEach(match => {
                if (currentPersonas) {
                    const currentSize = currentPersonas.length;
                    for (let i = 0; i < currentSize; i++) {
                        if (currentPersonas[i].id === match.id) {
                            return;
                        }
                    }
                }

                personas.push(toMemberPickItem(match.data, match.id));
            })

            return personas;
    } catch (error) {
        console.error(`Failed to query handout database [cause: ${error}]`);
        return [];
    }
}

export const MemberPicker: BsPrefixRefForwardingComponent<"input", MemberPickerProps> = (props) => {
    const {
        selectedItems,
        defaultSelectedItems,
        onPicked,
        ...rest
    } = props;

    const [selection, setSelection] = useState(defaultSelectedItems || []);
    const effectiveSelection = useMemo(() => {
        return (selectedItems || selection).map(item => ({
            ...item,
            content: item.name,
        }));
    }, [selectedItems, selection]);

    const handlePicks = useCallback((picks: MemberPickItem[], changes: PickChange<MemberPickItem>[]) => {
        setSelection(picks);
        onPicked?.(picks, changes);
    }, [onPicked]);

    return (
        <ItemPicker 
            selectedItems={effectiveSelection}
            suggestions={resolveSuggestions}
            suggestionProps={suggestionProps}
            max={1}
            onPicked={handlePicks}
            ignoreEmpty
            preserveCache
            delay={getQueryResolveDelay()}
            {...rest}
        />
    );
}

export interface MemberPickItem extends PickItem {
    name: string,
    post?: Post,
}

interface MemberPickerProps extends ItemPickerProps<MemberPickItem> {

}