import { getApp } from "firebase/app";
import { addDoc, collection, collectionGroup, getFirestore, Query, query, Timestamp, where } from "firebase/firestore";
import { Backend } from "../../utils/backend";
import { UserSnapshot, USER_COLLECTION_ID } from "./User";

export const BLOG_POST_COLLECTION_ID = "posts";

export enum BlogPostField {
	ID = "id",
	HEADLINE = "headline",
	SUBHEADLINE = "subheadline",
	PUBLISH_DATE = "publish_date",
	MODIFICATION_DATE = "modification_date",
	MARKDOWN = "markdown",
	AUTHOR = "author",
	TOPICS = "topics",
	SEARCH_TERMS = "searchable_terms",
}

function getDB() {
	return getFirestore(getApp(Backend.LOGCAT));
}

export function getBlogPosts(ofUser?: string | null): Query<BlogPostData> {
	const db = getDB();
	if (ofUser) return collection(db, USER_COLLECTION_ID, ofUser, BLOG_POST_COLLECTION_ID);
	return collectionGroup(db, BLOG_POST_COLLECTION_ID);
}

export function getBlogPost(postId: string) {
	return query(getBlogPosts(), where(BlogPostField.ID, "==", postId));
}

export function createBlogPost(uid: string, data: BlogPostData) {
	const db = getDB();
	const posts = collection(db, USER_COLLECTION_ID, uid, BLOG_POST_COLLECTION_ID)
	return addDoc(posts, data);
}

export interface BlogPostData extends Partial<Record<BlogPostField, unknown>> {
	[BlogPostField.ID]?: string,
	[BlogPostField.AUTHOR]?: UserSnapshot,
	[BlogPostField.HEADLINE]?: string,
	[BlogPostField.SUBHEADLINE]?: string,
	[BlogPostField.MARKDOWN]?: string,
	[BlogPostField.TOPICS]?: string[],
	[BlogPostField.PUBLISH_DATE]?: Timestamp,
	[BlogPostField.MODIFICATION_DATE]?: Timestamp,
}