import { formatDate } from "@hashimukh/core-js";
import { LazyListItem, LazyListItemProps, mergeClasses } from "@hashimukh/stardust";
import React, { useMemo } from "react";
import Badge from "react-bootstrap/Badge";
import Placeholder from "react-bootstrap/Placeholder";
import Row from "react-bootstrap/Row";
import ReactMarkdown from "react-markdown";
import { BlogPostData, BlogPostField } from "../db/models/BlogPost";
import "../res/styles/post-preview.scss";
import { findImagesInMarkdown } from "../utils/strings";
import { Shimmer } from "./common/Shimmer";
import { TopicPlaceholder } from "./common/Topic";
import { NoRender } from "./NoRender";

export const PostPreviewPlaceholder: React.FunctionComponent<PostPreviewPlaceholderPorps> = ({ className, horizontal=false, ...rest }) => {
	return <LazyListItem className={mergeClasses(`post-preview py-3`, className)} data-horizontal={horizontal} action disabled {...rest}>
		<Row className="post">
			<div className={`post-content`}>
				<Shimmer className="text-muted flex-wrap align-items-center mb-3">
					<small className="w-100"><Placeholder xs={2} size="lg" /> &bull; <TopicPlaceholder xs={2} /></small>
				</Shimmer>
				<div className="post-text overflow-hidden mb-3">
					<h2><Shimmer className="post-headline mb-3" pattern={[5, 4, 3]} /></h2>
					<Shimmer className="post-subheadline" pattern={[4, 5, 4]} />
					<Shimmer className="post-subheadline mt-2" pattern={[5, 4, 4]} />
				</div>
			</div>
			<div>
				<Shimmer className="post-cover w-100 rounded ratio ratio-1x1 border">
					<Placeholder className="w-100 h-100" />
				</Shimmer>
			</div>
		</Row>
	</LazyListItem>
}

export const PostPreview: React.FunctionComponent<PostPreviewProps> = ({ className, data, horizontal = false, ...rest }) => {
	const topics = data[BlogPostField.TOPICS];
	const headline = data[BlogPostField.HEADLINE];
	const subheadline = data[BlogPostField.SUBHEADLINE];
	const markdown = data[BlogPostField.MARKDOWN];
	const publishDate = data[BlogPostField.PUBLISH_DATE];

	const images = useMemo(() => markdown !== undefined ? findImagesInMarkdown(markdown) : undefined, [markdown]);

	return <LazyListItem className={mergeClasses(`post-preview`, className)} data-horizontal={horizontal} action {...rest}>
		<Row className="post">
			<div className={`post-content`}>
				<small className="text-muted hstack flex-wrap gap-1 align-items-center mb-1">
					{publishDate ? formatDate(publishDate.toDate(), "short") : "Unknown publish date"}
					{topics && <> &bull;{topics.map((topic, i) => <Badge key={`${topic}-${i}`} className="text-uppercase text-truncate" bg="secondary">{topic}</Badge>)}</>}
				</small>
				<div className="post-text overflow-hidden">
					{headline && <h3 className="post-headline">{headline}</h3>}
					{subheadline && <p className="post-subheadline">{subheadline}</p>}
					{(!headline && !subheadline) && (markdown ? <ReactMarkdown 
						components={{ "img": NoRender }}
					>
						{markdown}
					</ReactMarkdown> : "This post has no content")}
				</div>
			</div>
			{images?.length ? <ReactMarkdown className="post-cover mb-3" allowedElements={["p", "img"]}>{images[0].element}</ReactMarkdown> : null}
		</Row>
	</LazyListItem>
}

export interface PostPreviewProps extends LazyListItemProps {
	data: BlogPostData,
	horizontal?: boolean,
}

export interface PostPreviewPlaceholderPorps extends LazyListItemProps {
	horizontal?: boolean,
}