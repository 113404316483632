import { customAlphabet } from "nanoid";
import { CHARS_ALPHABET_LOWER, CHARS_DIGITS } from "./constants";

export function makeId(minLen?: number, from?: string | null, hash?: number) {
	const _minLen = minLen || 1;
	let id: string;

	if (from) {
		const hashGenerator = hash && customAlphabet(CHARS_DIGITS, 2 + hash); // atleast 3 digits

		let extractLen = 2;
		const parts = from.toLowerCase().replace(/[^a-z0-9-_ ]/g, "").split(" ");
	
		do {
			id = parts.slice(0, extractLen).join("");
			if (hashGenerator) id += hashGenerator();
		} while (id.length < _minLen && parts.length > extractLen++)
	} else {
		const segmentGenerator = customAlphabet(CHARS_DIGITS + CHARS_ALPHABET_LOWER, 3 + (hash || 0));
		id = segmentGenerator() + "-" + segmentGenerator();
	}

	if (id.length < _minLen) {
		const extend = customAlphabet(CHARS_DIGITS, _minLen - id.length);
		id += extend();
	}

    return id;
}

export function toSimpleFieldId(raw: string) {
	return raw.replace(/[^a-zA-Z_]/g, "");
}