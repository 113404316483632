import { appConfigs } from "@hashimukh/core-js";
import { arrayRemove, deleteDoc, getDoc, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Collapse from "react-bootstrap/Collapse";
import { AppPage, PageMeta } from "./AppPage";
import { Loading } from "./components/common/Loading";
import { getSubscriptionById, SubscriptionField } from "./db/models/Subscription";
import { Backend } from "./utils/backend";
import { useQueryParams } from "./utils/useQueryParams";

const metas: PageMeta = {
	title: "Unsubscribe - Hashimukh Newsletter",
	description: "Unsubscribe from Hashimukh newsletters. We won't send you emails regarding specified topics."
}

export const Unsubscribe: React.FunctionComponent = () => {
	const params = useQueryParams();
	
	const [state, setState] = useState<"unsubscribed" | "invalid-argument" | "404" | "internal-error">();

	const id = params.get("id");
	const strServices = params.get("services")?.toUpperCase();

	useEffect(() => {
		if (!strServices || !id) {
			setState("invalid-argument");
			return;
		}
		const services = strServices?.split(",");

		const ref = getSubscriptionById(Backend.HANDOUT, id);
		getDoc(ref).then(async snapshot => {
			const data = snapshot.data();
			if (!snapshot.exists() || !data) {
				setState("404");
				return;
			}

			const newServices = data[SubscriptionField.SERVICES]?.filter(serv => !services.includes(serv));
			try {
				if (!newServices?.length) {
					await deleteDoc(ref);
				} else {
					await setDoc(ref, {
						[SubscriptionField.SERVICES]: arrayRemove(...services),
					}, { merge: true });
				}

				setState("unsubscribed");
			} catch (error) {
				console.error(`error unsubscribing from newsletters [cause: ${error}]`);
				setState("internal-error");
			}
		})
	}, [id, strServices]);

	return <AppPage
		heading="Unsubscribe"
		subheading="Stop receiving Hashimukh newsletters."
		metas={metas}
	>
		<Collapse in={state === undefined}><div>
			<Loading />
		</div></Collapse>
		<Collapse in={state === "invalid-argument"}><div>
			<Alert variant="danger">
				Invalid subscription ID or services. Please check your url parameters or <Alert.Link href={`mailto:${appConfigs.contacts.email}`}>contact</Alert.Link> us.
			</Alert>
		</div></Collapse>
		<Collapse in={state === "404"}><div>
			<Alert variant="info">
				You were already unsubscribed.
			</Alert>
		</div></Collapse>
		<Collapse in={state === "internal-error"}><div>
			<Alert variant="danger">
				Oops. Something went wrong in our system. Please try again later.
			</Alert>
		</div></Collapse>
		<Collapse in={state === "unsubscribed"}><div>
			<Alert variant="success">
				You are unsubscribed successfully. Thanks for staying with us throughout the times.
			</Alert>
		</div></Collapse>
	</AppPage>;
}