import { LazyList, LazyListProps } from "@hashimukh/stardust";
import { QueryDocumentSnapshot } from "firebase/firestore";
import React, { useCallback } from "react";
import { BlogPostData, BlogPostField } from "../db/models/BlogPost";
import { DEF_QUERY_LIMIT } from "../utils/configs";
import { useRouter } from "../utils/useRouter";
import { PostPreview, PostPreviewPlaceholder, PostPreviewProps } from "./PostPreview";

export const PostListPlaceholder: React.FunctionComponent<PostListPlaceholderProps> = ({ count = DEF_QUERY_LIMIT, ...rest }) => {
	return <LazyList {...rest}>
		{Array.from(Array(count).keys()).map((_, i) => <PostPreviewPlaceholder key={`post-placeholder-${i}`} />)}
	</LazyList>;
}

export const PostList: React.FunctionComponent<PostListProps> = ({ posts, previewHorizontal, onPostClicked, ...rest }) => {
	const router = useRouter();
	
	const handlePostClick = useCallback((evt: React.MouseEvent<Element, MouseEvent>) => {
		const id = evt.currentTarget.id;
		router.push(`/blog/${id}`);

		onPostClicked?.(evt, id);
	}, [onPostClicked, router]);

	return <LazyList {...rest}>
		{posts.map((snapshot) => {
			const data = snapshot.data();
			return <PostPreview 
				key={`blog-post-${snapshot.id}`} 
				id={data[BlogPostField.ID] || snapshot.id}
				horizontal={previewHorizontal}
				onClick={handlePostClick}
				data={data}
			/>
		})}
	</LazyList>
}

export interface PostListProps extends LazyListProps {
	posts: QueryDocumentSnapshot<BlogPostData>[],
	onPostClicked?: (evt: React.MouseEvent<Element, MouseEvent>, pid: string) => unknown,
	previewHorizontal?: PostPreviewProps["horizontal"],
}

export interface PostListPlaceholderProps extends LazyListProps {
	count?: number,
	previewHorizontal?: PostPreviewProps["horizontal"],
}