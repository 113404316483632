import { collection, doc, Firestore, Transaction } from "firebase/firestore";

export const LOCK_COLLECTION_ID = "locks";

export enum OperationScope {
	MEMBER = "member_create",
}

function getLocks(db: Firestore) {
	return collection(db, LOCK_COLLECTION_ID);
}

function getLock(db: Firestore, id: string) {
	return doc(getLocks(db), id);
}

export function lockOperation(uid: string, scope: OperationScope, db: Firestore, append: Transaction) {
	const ref = getLock(db, uid);
	return append.set(ref, { scopes:  { [scope]: true }} as LockData, { merge: true });
}

export interface LockData {
	scopes: Record<OperationScope, boolean>,
}