import { mergeClasses } from "@hashimukh/stardust";
import React from "react";
import { BsPrefixRefForwardingComponent } from "react-bootstrap/helpers";
import FormGroup from "react-bootstrap/FormGroup";
import FormSelect, { FormSelectProps } from "react-bootstrap/FormSelect";
import { PostLevel } from "../../db/objects/post";
import { FormLabel } from "./FormLabel";

export const NONE: None = "__none";

export function onSelectCallback<T extends string>(evt: React.ChangeEvent<HTMLSelectElement>, callback?: (value: T | undefined) => unknown) {
    if (!callback) return;

    const value = evt.currentTarget.value;
    callback(!value || value === NONE ? undefined : value as T);
}

export const PostControl: BsPrefixRefForwardingComponent<"select", PostControlProps> = (props) => {
	const {
		className,
		controlClassName,
        onChange,
        onPostSelect,
		required,
		...rest
	} = props;

	return <FormGroup className={mergeClasses("mb-3", className)}>
		<FormLabel required={required}>Contribution preference</FormLabel>
		<FormSelect 
			className={controlClassName}
			aria-label="How would you like to make contributions"
            onChange={evt => {
                onChange?.(evt);
                onSelectCallback(evt, onPostSelect);
            }}
			required={required}
            defaultValue={NONE}
			{...rest}
		>
			<option value={NONE} disabled={required}>Select your contribution preference</option>
			<option value={PostLevel.PATRON}>I want to contribute arbitrarily</option>
			<option value={PostLevel.CORE_MEMBER}>I want to contribute on a monthly basis of 100 BDT</option>
		</FormSelect>
	</FormGroup>
}

export type None = "__none"

export interface PostControlProps extends FormSelectProps {
	controlClassName?: string,
	defaultValue?: PostLevel | None,
    onPostSelect?: (level: PostLevel | undefined) => unknown,
}