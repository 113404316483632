import { useUser } from "@hashimukh/firebase-js";
import { Button, SimpleForm } from "@hashimukh/stardust";
import { UserCredential } from "firebase/auth";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Collapse from "react-bootstrap/Collapse";
import Form from "react-bootstrap/Form";
import { getAuthApp } from "../utils/authUtils";
import { useRouter } from "../utils/useRouter";
import { SignInControl } from "./SignInControl";

export const RequireSignIn: React.FunctionComponent<RequireSignInProps> = (props) => {
	const {
		mode,
		onStateChanged,
	} = props;

	const user = useUser(getAuthApp());
	const route = useRouter();
	
	const signInRef = useRef<() => Promise<UserCredential>>(() => { throw new Error("no-op") });
	const signInResetRef = useRef<() => unknown>(() => { /* no-op */ });

	const [letSignIn, setLetSignIn] = useState(false);
	const [state, setState] = useState<SignInState>("none");

	const signIn = useCallback(() => {
		setState("in-progress");
		signInRef.current();
	}, []);

	const onSignedIn = useCallback(() => {
		setState("signed-in");
	}, []);

	const handleSignInError = useCallback((err) => {
		console.warn(`sign in error: ${err}`);
		setState("none");
	}, []);

	useEffect(() => {
	  if (!user) {
		setState("none");
		signInResetRef.current();
	  }
	}, [user]);

	useEffect(() => {
		onStateChanged?.(state);
	}, [onStateChanged, state]);
	
	return <><Collapse in={!user}><div>
		<Form className="mb-3">
			<SimpleForm gap={1} hintRequired={false} primaryAction={<>
				<Button 
					onClick={signIn} 
					disabled={!letSignIn || state !== "none"} 
					status={state === "in-progress" ? "loading" : "none"}
				>
					{mode === "sign-up" ? "Sign up" : "Sign in"}
				</Button>
				{mode !== "sign-up" && <Button 
					className="link-secondary" 
					variant="link" 
					disabled={state !== "none"} 
					onClick={() => route.push("/sign-up")}
				>
					Sign-up instead
				</Button>}
			</>}>
				<SignInControl
					onSignInRef={signInRef}
					onSignedIn={onSignedIn}
					onEligibilityChanged={setLetSignIn}
					onError={handleSignInError}
					resetRef={signInResetRef}
				/>
			</SimpleForm>
		</Form>
	</div></Collapse>
	<Collapse in={user !== null}><div>
		<Alert variant="info">You&apos;re signed in as {user?.displayName || user?.phoneNumber}.</Alert>
  	</div></Collapse></>
}

export interface RequireSignInProps {
	mode?: "sign-in" | "sign-up",
	onStateChanged?: (state: SignInState) => unknown,
}

export type SignInState = "in-progress" | "signed-in" | "none";