import { getQueryResolveDelay, queryToHandout, Region } from '@hashimukh/firebase-js';
import { ItemPicker, ItemPickerProps, PickerSuggestionProps, PickItem } from '@hashimukh/stardust';
import React from 'react';
import { BsPrefixRefForwardingComponent } from 'react-bootstrap/helpers';
import { InstitutionField, InstitutionSnapshotData, InstitutionSnapshotField } from '../db/models/Institution';
import { DocumentMatch } from '../db/objects/common';
import { NEW } from '../utils/constants';
import { matchString } from '../utils/strings';

export function toInstitutionPickItem(data: Record<string, any>, id?: string): InstitutionPickItem {
    const _id = id || data[InstitutionSnapshotField.ID];
    const name = data[InstitutionField.NAME] || data[InstitutionSnapshotField.NAME];
    
    return {
        id: _id,
        name: name || _id,
        content: name || "No name",
    }
}

export function toInstitutionSnapshot(src: InstitutionPickItem): InstitutionSnapshotData {
    return {
        [InstitutionSnapshotField.ID]: src.id,
        [InstitutionSnapshotField.NAME]: src.name,
    }
}

const resolveSuggestions = async function(filter: string, selectedItems?: InstitutionPickItem[]): Promise<InstitutionPickItem[]> {
    console.debug(`Resolving institution suggestions`);

    try {
        const response: Response = await queryToHandout(`query-v1_asia/institutions/?contains=${encodeURIComponent(filter)}`, Region.HONG_KONG);
        console.log(`Matched institutions received`);

        const tags: InstitutionPickItem[] = [];
        let exactMatchFound = false;

        const resText = await response.text();
        const matches: DocumentMatch[] = JSON.parse(resText);
        
        matches.forEach(match => {
            const matchName = match.data[InstitutionField.NAME];
            if (typeof matchName === 'string' && matchString(filter, matchName)) exactMatchFound = true;

            if (selectedItems) {
                const selectedSize = selectedItems.length;
                for (let i = 0; i < selectedSize; i++) {
                    if (selectedItems[i].id === match.id) {
                        return;
                    }
                }
            }

            tags.push(toInstitutionPickItem(match.data, match.id));
        })

        if (!exactMatchFound) tags.push({ id: NEW, content: filter, name: filter})
        return tags;
    } catch (error) {
        console.error(`Failed to query handout database [cause: ${error}]`);
        return [];
    }
}

const suggestionProps: PickerSuggestionProps = {
    headingText: "Suggestion institutions",
    noSuggestionText: "No institutions matched your description",
}

export const InstitutionPicker: BsPrefixRefForwardingComponent<"input", InstitutionPickerProps> = (props) => {
    return (
        <ItemPicker 
            suggestions={resolveSuggestions}
            suggestionProps={suggestionProps}
            ignoreEmpty
            preserveCache
            delay={getQueryResolveDelay()}
            {...props}
        />
    );
}

export interface InstitutionPickItem extends PickItem {
    name: string,
}

export interface InstitutionPickerProps extends ItemPickerProps<InstitutionPickItem> {

}