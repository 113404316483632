export const predefinedFieldOfStudies: string[] = [
    "Science",
    "Commerce",
    "Business Studies (Vocational)",
    "Humanities",
    "General",
    "Islamic",
    "Religious",
    "Medicine",
    "Medicine (Diploma)",
    "Dentistry",
    "Law",
    "Teacher training programmes",
    "Leather technology",
    "Leather technology (technical)",
    "Textiles",
    "Agriculture",
    "Social Science",
    "Arts",
    "Philosophy",
    "Computer Science",
]