import { useUser } from '@hashimukh/firebase-js';
import { FormFeedback } from '@hashimukh/stardust';
import React, { useCallback, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Collapse from 'react-bootstrap/Collapse';
import { Prompt } from "react-router-dom";
import { AppPage, PageMeta } from './AppPage';
import { CopyButton } from './components/common/CopyButton';
import { Subscribtion } from './components/common/Subscribe';
import { descriptions, RegistrationControl, Section, titles } from './components/RegistrationControl';
import { RequireSignIn } from './components/RequireSignIn';
import { getAuthApp } from './utils/authUtils';
import "./utils/common";
import { getConfigValue } from './utils/configs';
import { useMember } from './utils/useMember';
import { useUserClaims } from './utils/useUserClaims';

const metas: PageMeta = {
  title: "Membership - Hashimukh",
  description: "Join us and become a part of something more than just yourself.",
  image: "https://hashimukhstorage.blob.core.windows.net/public/cover_web_prod_06_1080.jpg",
}

export const Registration: React.FunctionComponent = () => {
  const user = useUser(getAuthApp());
  const userClaims = useUserClaims(user);
  const member = useMember(userClaims?.mid);

  const [registered, setRegistered] = useState(false);
  const [title, setTitle] = useState(titles[Section.PUBLIC_DATA]);
  const [description, setDescription] = useState(descriptions[Section.PUBLIC_DATA]);

  const onRegistered = useCallback(() => setRegistered(true), []);
  const onError = useCallback(err => console.error(`error registering member [cause: ${err}]`), []);
  const onSectionChange = useCallback((s: Section) => {
    setTitle(titles[s]);
    setDescription(descriptions[s]);
  }, []);
  
  const accept = getConfigValue("accept_new_membership").asBoolean();

  return (
    <AppPage
      heading={title}
      subheading={description}
      metas={metas}
    >
      <Prompt when={!(registered || member.snapshot?.exists())} message="You may have unsaved changes. Click cancel to go back safely." />
      {!accept ? <>
        <Alert variant="warning">
          <Alert.Heading>Registration is closed for now</Alert.Heading>
          Thanks for your interest, but unfortunately we are not accepting new memberships right now. Fill out your email address below so we can let you know once its open again.
        </Alert>
        <Subscribtion services={["MEMBERSHIP_OPEN"]} onSubscribed={onRegistered} />  
      </> : <>
        <RequireSignIn mode="sign-up" />
        <Collapse in={user !== null && (registered || !member.snapshot?.exists())}><div>
          {accept && <RegistrationControl 
            uid={user?.uid} 
            onSectionChange={onSectionChange} 
            onRegistered={onRegistered} 
            onError={onError} />}
        </div></Collapse>
        <Collapse in={user !== null && member.snapshot?.exists() && !registered}><div>
          <FormFeedback 
            heading="You've already registered!"
            message={<>Looks like you&apos;ve already registered for Hashimukh membership. Your member ID was <em>{member.snapshot?.id}</em>.</>}
            actions={[
              <CopyButton key="post-reg-btn-copy-mid" content={member.snapshot?.id || ""} />
            ]}
          />
        </div></Collapse>
      </>}
    </AppPage>
  );
};