import { getApp } from "firebase/app";
import { collection, doc, getFirestore, setDoc } from "firebase/firestore";
import { Backend } from "../../utils/backend";
import { NEW } from "../../utils/constants";

export const INSTITUTION_COLLECTION_ID = "institutions";

export enum InstitutionField {
    NAME = "name",
    IS_VERIFIED = "is_verified",
}

export enum InstitutionSnapshotField {
    ID = "id",
    NAME = "snapshot-name"
}

export class Institution {
    private data: InstitutionData = { };

    public static async ensure(data: InstitutionSnapshotData): Promise<string> {
        const id = data[InstitutionSnapshotField.ID];
        if (id && id !== NEW) return id;

        const instance = new Institution();
        const name = data[InstitutionSnapshotField.NAME];
        if (name) instance.setName(name);

        const newId = await instance.create();
        data[InstitutionSnapshotField.ID] = newId;

        return newId;
    }

    public setName(name: string) {
        this.data[InstitutionField.NAME] = name;
    }

    public async create(): Promise<string> {
        const db = getFirestore(getApp(Backend.HANDOUT));
        const ref = doc(collection(db, INSTITUTION_COLLECTION_ID));

        this.data[InstitutionField.IS_VERIFIED] = false;

        try {
            await setDoc(ref, this.data);
            return ref.id;
        } catch (error) {
            console.error(`Error creating institution [cause: ${error}]`);
            return Promise.reject(error);
        }
    }
}

export interface InstitutionData {
    [InstitutionField.NAME]?: string,
    [InstitutionField.IS_VERIFIED]?: boolean
}

export type InstitutionSnapshotData = {
    [InstitutionSnapshotField.ID]: string
    [InstitutionSnapshotField.NAME]?: string
}