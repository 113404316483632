import React, { ClassAttributes, ImgHTMLAttributes } from "react";
import Figure from "react-bootstrap/Figure";
import FigureCaption from "react-bootstrap/FigureCaption";
import FigureImage from "react-bootstrap/FigureImage";
import { ReactMarkdownProps } from "react-markdown/lib/complex-types";

export const MarkdownImage: React.FunctionComponent<MarkdownImageProps> = (props) => {
	const {
		containerClassName,
		alt,
		src,
		title,
	} = props;

	return <Figure className={containerClassName}>
		<FigureImage src={src} alt={alt}/>
		{title && <FigureCaption>
			{title}
		</FigureCaption>}
	</Figure>
}

export type MarkdownImageProps = ClassAttributes<HTMLImageElement> & ImgHTMLAttributes<HTMLImageElement> & ReactMarkdownProps & {
	containerClassName?: string,
};