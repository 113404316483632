import { InputField, InputFieldProps } from "@hashimukh/stardust";
import React, { useCallback, useMemo, useState } from "react";
import { BsPrefixRefForwardingComponent } from "react-bootstrap/helpers";

function adjustInput(raw: string, min: number | undefined, max?: number): [string | undefined, string | undefined] {
	if ((min && raw.length < min) || (max && raw.length > max)) {
		return [undefined, (min !== undefined && max !== undefined 
			? `Input must be within ${min} and ${max} characters long.` 
			: max ? `Input must be less than ${max} characters long.` : `Input must be greater than ${min} characters long.`) 
				+ ` Current length is ${raw.length} characters.`];
	}

	return [raw, undefined];
}

export const ExpandableTextField: BsPrefixRefForwardingComponent<"input", ExpandableTextFieldProps> = (props) => {
	const {
		value: _value, 
		defaultValue,
		rows,
		max,
		min,
		onChange,
		onChanged,
		...rest
	} = props;

	const [value, setValue] = useState(defaultValue);
	const effectiveValue = (_value !== undefined && _value) || value || "";

	const error = useMemo(() => adjustInput(effectiveValue || "", min, max)[1], [effectiveValue, max, min]);

	const handleChange = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
		const raw = evt.currentTarget.value;
		setValue(raw);

		onChanged?.(adjustInput(raw, min, max)[0]);
		onChange?.(evt);
	}, [max, min, onChange, onChanged]);

	return <InputField 
		as="textarea"
		value={effectiveValue}
		rows={rows}
		onChange={handleChange}
		errorMessage={error}
		{...rest}
	/>
}

export interface ExpandableTextFieldProps extends Omit<InputFieldProps, "as" | "value" | "defaultValue"> {
	value?: string,
	defaultValue?: string,
	rows?: number,
	max?: number,
	min?: number,
	onChanged?: (value: string | undefined) => unknown,
}