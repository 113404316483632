import { queryToHandout, Region } from "@hashimukh/firebase-js";
import { getApp } from "firebase/app";
import { getAuth, signInWithCustomToken, User, UserCredential } from "firebase/auth";
import { Backend } from "./backend";

export function getAuthApp() {
    return getApp(Backend.HANDOUT);
}

export async function ensureSignedIn(): Promise<User | null> {
    const auth = getAuth(getAuthApp());
    if (!auth.currentUser) {
        console.debug(`Claiming authorization`);
        
        try {
            const cred = await claimAuthorization();
            console.info(`Authorization claimed successfully`);

            return cred.user;
        } catch (error) {
            if (auth.currentUser) return auth.currentUser;
            return Promise.reject(error);
        }
    } else {
        console.info(`Already claimed authorization`);
        return auth.currentUser;
    }
}

async function claimAuthorization(): Promise<UserCredential> {
    const token = await retrieveCustomClaimToken();

    if (token) {
        const auth = getAuth(getAuthApp());
        if (!auth.currentUser) return signInWithCustomToken(auth, token);
        else return Promise.reject(`Already received authorization`);
    } else {
        return Promise.reject(`Unable to get authorization. Token not found`);
    }
}
async function retrieveCustomClaimToken(): Promise<string> {
    let response: Response;

    try {
        response = await queryToHandout("authentication-general/member-registrator", Region.HONG_KONG);
    } catch(err) {
        return Promise.reject(`Error claiming authorization: ${err}`);
    }
    
    if (response.ok) {
        return response.text();
    } else {
        return Promise.reject(`Internal server error occured while claiming authorization`);
    }
}