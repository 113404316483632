import { Icon, PrintContext } from "@hashimukh/stardust";
import React, { useCallback, useContext, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import { AppPage, PageMeta } from "./AppPage";
import { Equation } from "./components/common/Equation";
import "./res/styles/policies.scss";

const metas: PageMeta = {
    title: "Policies - Hashimukh Foundation",
    description: "Hashimukh member policies and other Foundation policies.",
}

const Introduction = () => {
    return <p>
        The Hashimukh Member Agreement comprises these Hashimukh <a href="#member-policies">Member Policies</a>, and all other Foundation policies.
    </p>
}

const MemberPolicies = (): JSX.Element => {
    return <Stack>
        <p>
            As a registered Hashimukh Member, you are expected to abide by several rules fall upon to you by the Foundation. By completing and submitting the Hashimukh Registration Form, you understand and agree to the following rules:
        </p>
        <h6 id="member-policies#code-of-conduct">Code of Conduct</h6>
        <div>
            <p>As a Hashimukh member, you are obligated to maintain some code of conducts while performing your duty or representing the Foundation. You agree that</p>
            <ol type="a">
                <li>you shall conduct all volunteer activities with good will, high standards and professionalism.</li>
                <li>you shall show mutual respect and collaborate with your co-workers.</li>
                <li>you shall do nothing to cause detriment to the reputation or goodwill of the Foundation.</li>
            </ol>
        </div>
        <h6 id="member-policies#contact">Contact</h6>
        <div>
            <p>Hashimukh may use the information you provided on your registration form to contact you regarding any of the Foundation&apos;s activities. By providing your</p>
            <ol type="a">
                <li>phone number and/or email address, you allow Hashimukh to send you emails or messages.</li>
                <li>Facebook ID, you allow Hashimukh to add your Facebook account to any of the Hashimukh Foundation&apos;s official Facebook Messenger group.</li>
                <li>postal address, you allow Hashimukh to send mails to your address.</li>
            </ol>
        </div>
        <h6 id="member-policies#contributions">Contributions</h6>
        <div>
            <p>All Hashimukh members should reach their respective amount of minimum monthly contributions (fees) within the 8<sup>th</sup> day of each months. <i>Initially</i> each Hashimukh</p>
            <ol type="a">
                <li>director shall contribute at least 200 Bangladesh Taka per month.</li>
                <li>executive officer shall contribute at least 200 Bangladeshi Taka per month.</li>
                <li>member of the advisory council shall contribute at least 200 Bangladesh Taka per month.</li>
                <li>foreman shall contribute at least 150 Bangladeshi Taka per month.</li>
                <li>mentor shall contribute at least 125 Bangladeshi Taka per month.</li>
                <li>core-member shall contribute at least 100 Bangladeshi Taka per month.</li>
            </ol>
        </div>
        <div>
            <p>For any given month, you agree that you shall contribute at least your respective amount, which may be determined using the following formula: </p>
            {Equation({ rawLatex: `$$C = I + (25 \\times A)$$`})}
            <p>where: </p>
            <ul>
                <li key="definition_c">C = your respective minimum contribution amount</li>
                <li key="definition_i">I = initial amount for your <i>current</i> post</li>
                <li key="definition_a">A = your Hashimukh age</li>
            </ul>
        </div>
        <h6 id="member-policies#prohibited-activities">Prohibited Activities</h6>
        <div>
            <p>As a Hashimukh member, you are prohibited from doing several activities anytime you are representing Hashimukh, or in a meeting/event organized or conducted by Hashimukh. Without limitation, the specific prohibited activity is</p>
            <ol type="a">
                <li>to promote violence against or directly attack or threaten other people based on gender, religious affiliation, or age.</li>
                <li>to praise, express support, or promote leaders, groups, or individuals involved in those activities described at (a).</li>
                <li>to promote any candidate for the public office.</li>
                <li>to sexually harass anyone.</li>
                <li>to join the Hashimukh Foundation with any sole purpose other than contributing to the fulfillment of Hashimukh purposes.</li>
                <li>to abuse the power, fall upon to you by Hashimukh Foundation.</li>
            </ol>
            <p>Along with that during the time of your membership at Hashimukh Foundation as an executive officer, you must not join or become a member of another foundation, club, and/or organization that may fall into the same category as Hashimukh does.</p>
        </div>
        <h6 id="member-policies#leaves">Leaves</h6>
        <div>
            <p>You may request a leave of up to <em>3 (three)</em> months in a calendar year for any reasonable cause. In addition, Hashimukh shall grant you leave during your public exam and admission test periods. During the leave, your monthly fees will occur as usual but your obligations to other duties will be suspended.</p>
        </div>
        <h6 id="member-policies#termination-of-membership">Termination of Membership</h6>
        <div>
            <p>You may terminate your membership with Hashimukh by filing a written or verbal resignation with the secretary, or in his/her absence, any Hashimukh officer. Hashimukh may also, at its sole discretion, terminate your membership (AKA dismissal) on the basis that</p>
            <ol type="a">
                <li>you have breached the Hashimukh Member Agreement.</li>
                <li>you have misconducted.</li>
                <li>you have shown underperformance.</li>
                <li>you have failed to meet minimum monthly contribution requirements for any 3 (three) months in a 12 (twelve) month period.</li>
            </ol>
            <p>Termination of membership shall reset your position at Hashimukh thus also relieving you of all unpaid charged monthly dues. However, you may not be able to register again in the future if you are dismissed (terminated by Hashimukh).</p>
        </div>
    </Stack>
}

const CookiesPolicy = () => {
    return <Stack>
        <p>We may use cookies when you visit our websites to both serve you with better experience and help us make better decisions in future.</p>
        <p>By browsing this website, you&apos;re providing us consent to use and store cookies on your device.</p>
    </Stack>
}

const NewsletterPolicy = () => {
    return <Stack>
        <p>Information you provide for Hashimukh newsletter subscriptions shall only be used to reach you with updates. They shall not be shared with any third parties and shall not be used to spam you.</p>
    </Stack>
}

const ContactPolicy = () => {
    return <Stack>
        <p>We will use the information you provide in the contact form to improve our products, services, and your experience with us and for the betterment of the Foundation. We may use the provided contact information to reach you for further discussion if needed. Your contact information shall not be shared with any third parties or to identify you across services.</p>
    </Stack>
}

export const Policies: React.FunctionComponent = () => {
    const prints = useContext(PrintContext);

    const printDoc = useCallback(() => {
        prints.setEnabled(true);
    }, [prints]);

    useEffect(() => {
        if (prints.enabled) {
            window.print(); // blocks the ui until user dismiss or prints
            prints.setEnabled(false);
        }
    }, [prints]);

    return (
        <AppPage 
            heading="Hashimukh Member Agreement" 
            subheading={<small className="text-muted">Revision date: <time dateTime={"2022-07-25 00:30"}>July 25, 2022</time>.</small>}
            headerProps={{
                expand: false,
                children: !prints.enabled && <Button className="btn-header d-flex align-items-center justify-content-center mb-2" variant="outline-secondary" size="sm" onClick={printDoc}>
                    <Icon className="ic-topic me-1 me-2 fs-5" name="print" />
                    Print
                </Button>
            }}
            data-bs-spy="scroll" 
            data-bs-offset="3"
            metas={metas}
        >
            <Stack id="introduction" className="mb-2">
                <h5>Introduction</h5>
                <Introduction />
            </Stack>
            <Stack id="member-policies" className="mb-2">
                <h5>Member Policies</h5>
                <MemberPolicies />
            </Stack>
            <Stack id="contact-policies" className="mb-2">
                <h5>Contact Policies</h5>
                <ContactPolicy />
            </Stack>
            <Stack id="newsletter-policies" className="mb-2">
                <h5>Newsletter Policies</h5>
                <NewsletterPolicy />
            </Stack>
            <Stack id="cookies-policy" className="mb-2">
                <h5>Website Cookies Policy</h5>
                <CookiesPolicy />
            </Stack>
        </AppPage>
    )
}