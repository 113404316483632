import { collection, CollectionReference, doc, Firestore, getFirestore } from "firebase/firestore";
import { getAuthApp } from "../../utils/authUtils";
import { Name } from "../objects/name";

export const USER_COLLECTION_ID = "users";

export enum UserField {
	USERNAME = "username",
	NAME = "name",
}

export enum UserSnapshotField {
	ID = "id",
	NAME = "name",
}

export function getUsers(db?: Firestore): CollectionReference<UserData> {
	return collection(db || getFirestore(getAuthApp()), USER_COLLECTION_ID);
}

export function getUser(uid: string, db?: Firestore) {
	return doc(getUsers(db), uid);
}

export interface UserData {
	[UserField.NAME]?: Name,
}

export interface UserSnapshot {
	[UserSnapshotField.ID]?: string,
	[UserSnapshotField.NAME]?: Name,
}