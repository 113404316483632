import { useUser } from "@hashimukh/firebase-js";
import { HeaderProps, Link, mergeClasses, StardustPage, StardustPageProps } from "@hashimukh/stardust";
import { getAuth, signOut } from "firebase/auth";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import Image, { ImageProps } from "react-bootstrap/Image";
import Navbar from "react-bootstrap/Navbar";
import Stack, { StackProps } from "react-bootstrap/Stack";
import { Helmet } from "react-helmet";
import { BackgroundContext } from "./App";
import "./res/styles/app-page.scss";
import { getAuthApp } from "./utils/authUtils";
import { useRouter } from "./utils/useRouter";
import { useUserClaims } from "./utils/useUserClaims";

const navs: HeaderProps["navs"] = [
	{
		label: "Join us",
		path: "/registration",
	},
	{
		label: "Policies",
		path: "/policies"
	},
	{
		label: "About",
		path: "/about",
	}
]

function truncateDisplayName(name: string) {
	const [first, ...rest] = name.split(" ");
	let combined = first;
	
	for (let i = 0, size = rest.length; i < size; i++) {
		const proposed = combined + " " + rest[i];
		if (combined.length > 0 && proposed.length > 12) break;

		combined = proposed;
	}

	if (combined.length > 12) {
		combined = combined.substring(0, 10) + "...";
	}

	return combined || "Name";
}

export const AppPageHeading: React.FunctionComponent<AppPageHeadingProps> = (props) => {
	const { className, ...rest } = props;
	return <h4 className={mergeClasses("mb-1", className)} {...rest}>{props.children}</h4>
}

export const AppPageSubheading: React.FunctionComponent<AppPageSubheadingProps> = (props) => {
	const { className, ...rest } = props;
	return <small className={mergeClasses("text-muted", className)} {...rest}>{props.children}</small>;
}

export const AppPageHeadingContainer: React.FunctionComponent<AppPageHeadingContainerProps> = (props) => {
	const { heading, subheading, customImage, customImageProps, children, ...rest } = props;

	const customImageElement = useMemo(() => {
		if (!customImage) return null;
		const { className: imageClass, ...restImage } = customImageProps || { };

		return <Image className={mergeClasses("fit-cover", imageClass)} src={customImage} width={80} height={80} rounded {...restImage} />
	}, [customImage, customImageProps]);

	return <Stack direction="horizontal" className="mb-4" gap={3} {...rest}>
		{customImageElement}
		<Stack className={customImageElement ? "mt-2" : ""}>
			{heading && typeof heading === "string" ? <AppPageHeading>{heading}</AppPageHeading> : heading}
			{subheading && typeof subheading === "string" ? <AppPageSubheading>{subheading}</AppPageSubheading> : subheading}
			{children}
		</Stack>
	</Stack>
}

export const AppPage: React.FunctionComponent<AppPageProps> = (props) => {
	const { heading, subheading, customImage, customImageProps, headerProps, metas, children, ...rest } = props;
	const bg = useContext(BackgroundContext);

	const authApp = getAuthApp();

	const route = useRouter();
	const user = useUser(authApp);
	const { mid } = useUserClaims(user) || { };

	const logout = useCallback(() => {
		signOut(getAuth(authApp));
	}, [authApp]);

	const headers = useMemo<StardustPageProps["headerProps"]>(() => {
		const { children: headerChildren, ...restHeader } = headerProps || { };

		const memberName = user?.displayName;
		return {
			navs: navs,
			children: <div className="mt-2 mt-md-0">
				{headerChildren}
				{user
					? memberName
						? <Dropdown className="btn-header" as={ButtonGroup} align="end" size="sm">
							<Button className="w-100 text-truncate" variant="outline-secondary" onClick={() => route.push(`/profile/${mid}`)}>{truncateDisplayName(memberName)}</Button>
							<Dropdown.Toggle variant="outline-secondary" split id="header-profile-option"/>
							<Dropdown.Menu>
								<Dropdown.Item onClick={logout}>Sign out</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
						: <Navbar.Text className="btn-header">
							<small className="me-1"><Link href="/registration">Continue</Link></small> | <Button className="ms-1" variant="outline-secondary" size="sm" onClick={logout}>Logout</Button>
						</Navbar.Text> 
					: <Button className="btn-header" variant="outline-light" size="sm" onClick={() => route.push("/sign-in")}>
						Sign in
					</Button>}
			</div>,
			...restHeader
		}
	}, [headerProps, logout, mid, route, user]);

	useEffect(() => {
		document.title = metas?.title || "Hashimukh Foundation";
	}, [metas?.title]);

	return <StardustPage
		id="app-content"
		title={<strong>Hashimukh</strong>}
		background={bg.displayImage ? bg.image : undefined}
		headerProps={headers}
		{...rest}
	>
		{metas && <Helmet>
			<meta charSet="utf-8" />
			<title>{metas.title}</title>
            <meta name="description" content={metas.description} />
            {/* for google search results */}
            <meta itemProp="name" content={metas.title} />
            <meta itemProp="description" content={metas.description} />
            {metas.image && <meta itemProp="image" content={metas.image}/>}
            {/* for facebook snippets */}
            <meta property="og:url" content={location.href} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={metas.title} />
            <meta property="og:description" content={metas.description} />
            {metas.image && <meta property="og:image" content={metas.image} />}
            {/* for twitter snippets */}
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:title" content={metas.title} />
            <meta name="twitter:description" content={metas.description} />
            {metas.image && <meta name="twitter:image" content={metas.image}/>}
		</Helmet>}
		{(heading || subheading) && <AppPageHeadingContainer 
			heading={heading} 
			subheading={subheading} 
			customImage={customImage} 
			customImageProps={customImageProps} />}
		{children}
	</StardustPage>
}

export interface AppPageProps extends Omit<StardustPageProps, "title"> {
	heading?: React.ReactNode,
	subheading?: React.ReactNode,
	customImage?: string,
	customImageProps?: ImageProps,
	metas?: PageMeta,
	// headingContainerProps?: AppPageHeadingProps,
}

export interface AppPageHeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {

}

export interface AppPageSubheadingProps extends React.HTMLAttributes<HTMLElement> {

}

export interface AppPageHeadingContainerProps extends StackProps {
	heading?: React.ReactNode,
	subheading?: React.ReactNode,
	customImage?: string,
	customImageProps?: ImageProps,
}

export interface PageMeta {
	title?: string,
	description?: string,
	image?: string,
}