import { Grade } from "../../objects/grade";
import { InstitutionSnapshotData } from "../Institution";

export const MEMBER_ACADEMIC_DOC_ID = "academic";

export enum MemberAcademicField {
    INSTITUTION = "institution",
    GRADE = "grade",
    FIELDS = "fields_of_study",
    UID = "uid",
}

export interface MemberAcademicData {
    [MemberAcademicField.INSTITUTION]?: InstitutionSnapshotData,
    [MemberAcademicField.GRADE]?: Grade,
    [MemberAcademicField.FIELDS]?: string[],
    [MemberAcademicField.UID]?: string,
}