import { InputField, InputFieldProps } from "@hashimukh/stardust";
import React, { useCallback, useMemo, useState } from "react";
import { BsPrefixRefForwardingComponent } from "react-bootstrap/helpers";
import { FacebookData, FacebookField, friendlyFacebookId, MAX_LEN_FACEBOOK_USERNAME } from "../../db/objects/facebook";

function facebookDataToString(data: FacebookData | null | undefined): string | undefined {
	return data ? friendlyFacebookId(data) : undefined;
}

function computeFacebookData(input: string, required?: boolean): [FacebookData | undefined, string | undefined] {
	const accept = input.trim();
	let data: FacebookData | undefined;
	let feedback: string | undefined;

	if (accept.length) {
		let id: string;
		if (accept.startsWith("https://")) {
			const parts = accept.split("/");
			id = parts[parts.length - 1];
		} else {
			id = accept;
		}

		if (id.length > MAX_LEN_FACEBOOK_USERNAME || id.includes(" ")) {
			feedback = "Invalid username";
		} else {
			data = {
				[FacebookField.IDENTIFIER]: accept,
			};
		}
	} else {
		if (required) feedback = "Username is required"
		else feedback = undefined;
	}

	// if (data) {
	// 	// shreds other fields
	// 	for (let i = 0, fields = Object.values(FacebookField), len = fields.length; i < len; i++) {
	// 		const field = fields[i];
	// 		if (!data[field]) data[field] = deleteField();
	// 	}
	// }

	return [data, feedback];
}

export const FacebookLinkField: BsPrefixRefForwardingComponent<"input", FacebookUsernameFieldProps> = (props) => {
	const {
		value: _value,
		defaultValue,
		onChange,
		onDataChanged,
		...rest
	} = props;

	const [value, setValue] = useState(facebookDataToString(defaultValue) || "");

	const effectiveValue = (_value !== undefined ? facebookDataToString(_value) : value) || "";
	const error = useMemo(() => computeFacebookData(effectiveValue, props.required)[1], [effectiveValue, props.required]);

	const handleChange = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
		const input = evt.currentTarget.value;
		setValue(input);

		onDataChanged?.(computeFacebookData(input)[0]);
		onChange?.(evt);
	}, [onChange, onDataChanged]);

	return <InputField 
		type="text"
		label="Facebook username"
		aria-label="Your facebook username or ID"
		description="Try providing a link to your Facebook profile. That way we can reach you more easily."
		prepend="facebook.com/"
		value={effectiveValue}
		onChange={handleChange}
		errorMessage={error}
		{...rest}
	/>
}

export interface FacebookUsernameFieldProps extends Omit<InputFieldProps, "value" | "defaultValue"> {
	value?: FacebookData,
	defaultValue?: FacebookData | null,
	onDataChanged?: (data: FacebookData | undefined) => unknown,
}