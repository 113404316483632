export enum BloodType {
    A_POS = 'A_POSITIVE', 
    A_NEG = 'A_NEGATIVE',
    B_POS = 'B_POSITIVE', 
    B_NEG = 'B_NEGATIVE',
    AB_POS = 'AB_POSITIVE', 
    AB_NEG = 'AB_NEGATIVE',
    O_POS = 'O_POSITIVE', 
    O_NEG = 'O_NEGATIVE',
}

export const friendlyBloodTypes: Record<BloodType, string> = {
    [BloodType.A_POS]: "A+",
    [BloodType.B_POS]: "B+",
    [BloodType.AB_POS]: "AB+",
    [BloodType.O_POS]: "O+",
    [BloodType.A_NEG]: "A-",
    [BloodType.B_NEG]: "B-",
    [BloodType.AB_NEG]: "AB-",
    [BloodType.O_NEG]: "O-",
}