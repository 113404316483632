import { Required } from "@hashimukh/stardust";
import React from "react";
import BaseLabel, { FormLabelProps as BaseProps } from "react-bootstrap/FormLabel";

export const FormLabel: React.FunctionComponent<FormLabelProps> = (props) => {
	const { required, children, ...rest } = props;

	return <BaseLabel {...rest}>
		{children}
		{required && <Required />}
	</BaseLabel>
}

export type FormLabelProps = BaseProps & {
	required?: boolean
}