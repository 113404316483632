import { mergeClasses } from "@hashimukh/stardust"
import React from "react"
import Spinner, { SpinnerProps } from "react-bootstrap/Spinner"
import Stack, { StackProps } from "react-bootstrap/Stack"

export const Loading: React.FunctionComponent<LoadingProps> = ({
    loadingText = "Please wait...",
    loadingTextProps,
    spinnerProps,
    ...rest
}) => {
	return <Stack {...rest}>
		<Spinner 
            animation="border" 
            {...spinnerProps} 
            className={mergeClasses("mx-auto", spinnerProps?.className)} 
        />
		<p {...loadingTextProps} 
            className={mergeClasses("text-center mt-2", loadingTextProps?.className)}
        >
            {loadingText}
        </p>
	</Stack>
}

export interface LoadingProps extends StackProps {
	loadingText?: string,
	loadingTextProps?: JSX.IntrinsicElements["p"],
	spinnerProps?: SpinnerProps,
}