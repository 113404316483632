import { InputField, InputFieldProps } from "@hashimukh/stardust";
import React, { useCallback, useMemo, useState } from "react";
import { BsPrefixRefForwardingComponent } from "react-bootstrap/helpers";
import { MAX_LEN_NAME, MIN_LEN_NAME } from "../../utils/constants";

function getNameError(name: string, required?: boolean) {
	const len = name.length;
	if (len === 0) {
		if (!required) return undefined;
		return "Name is required."
	}
	if (len > MAX_LEN_NAME) return `Your name can not contain more than ${MAX_LEN_NAME} characters.`;
	if (len < MIN_LEN_NAME) return `Your name can not be less than ${MIN_LEN_NAME} characters long`;
	if (!name.toLowerCase().match(/^[a-z \-.,'/]+$/g)) return `Your name should be in English and can not contain any numbers or unnecessary punctuations.`;
    return "";
}

export const NameField: BsPrefixRefForwardingComponent<"input", NameFieldProps> = (props) => {
	const {
		className,
		value: _value,
		defaultValue,
		onChange,
		onNameChanged,
		...rest
	} = props;

	const [name, setName] = useState(defaultValue || "");
	const effectiveName = (_value !== undefined ? _value : name) || "";

	const error = useMemo(() => getNameError(effectiveName, props.required), [effectiveName, props.required]);

	const handleNameChange = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
		onChange?.(evt);

		const value = evt.currentTarget.value;
		setName(value);
		onNameChanged?.(getNameError(value) ? undefined : value);
	}, [onChange, onNameChanged]);

	return <InputField 
		className={className} 
		type="text"
		label="Full name"
		aria-label="Your full name"
		value={effectiveName}
		errorMessage={error}
		onChange={handleNameChange}
		data-clarity-unmask="true"
		{...rest} />
}

export interface NameFieldProps extends Omit<InputFieldProps, "type"> {
	value?: string,
	defaultValue?: string,
	onNameChanged?: (name: string | undefined) => unknown,
}