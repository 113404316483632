import { SimpleForm } from "@hashimukh/stardust";
import { deleteField, Timestamp } from "firebase/firestore";
import React, { useCallback, useMemo } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import Row from "react-bootstrap/Row";
import { MemberAcademicData, MemberAcademicField } from "../db/models/member/Academic";
import { MemberContactData, MemberContactField } from "../db/models/member/Contact";
import { Member } from "../db/models/member/Member";
import { MemberPersonalData, MemberPersonalField } from "../db/models/member/Personal";
import { MemberPublicData } from "../db/models/member/Public";
import { AddressData } from "../db/objects/address";
import { BloodType } from "../db/objects/bloodtype";
import { FacebookData } from "../db/objects/facebook";
import { Grade } from "../db/objects/grade";
import { Sex } from "../db/objects/sex";
import { AddressField } from "./common/AddressField";
import { BloodTypeControl } from "./common/BloodTypeControl";
import { DobControl } from "./common/DobControl";
import { FacebookLinkField } from "./common/FacebookLinkField";
import { FormLabel } from "./common/FormLabel";
import GradeControl from "./common/GradeControl";
import { SexControl } from "./common/SexControl";
import StudyFieldControl from "./common/StudyFieldControl";
import { InstitutionPicker, InstitutionPickItem, toInstitutionPickItem, toInstitutionSnapshot } from "./InstitutionPicker";
import { requires } from "./RegistrationControl";

export const MemberEditor: React.FunctionComponent<MemberEditorProps> = (props) => {
	const {
		data,
		syncData: stage,
		disabled,
	} = props;

	const { personalData, contactData, academicData } = data || { };
    const {
        [MemberPersonalField.BLOOD_TYPE]: bloodType,
        [MemberPersonalField.DOB]: dob,
        [MemberPersonalField.SEX]: sex,
    } = personalData || { };
    const {
        [MemberContactField.ADDRESS]: address,
        [MemberContactField.FACEBOOK]: facebook,
    } = contactData || { };
    const {
        [MemberAcademicField.FIELDS]: studyFields,
        [MemberAcademicField.GRADE]: grade,
        [MemberAcademicField.INSTITUTION]: school,
    } = academicData || { };

	const defSchool = useMemo(() => school ? [toInstitutionPickItem(school)] : undefined, [school]);

	const setFacebook = useCallback((d: FacebookData | undefined) => stage.set(MemberContactField.FACEBOOK, d || deleteField()), [stage]);
	const setAddress = useCallback((value: AddressData | undefined) => stage.set(MemberContactField.ADDRESS, value || deleteField()), [stage]);
	const setSchool = useCallback((currentPicks: InstitutionPickItem[]) => {
		stage.set(MemberAcademicField.INSTITUTION, currentPicks[0] ? toInstitutionSnapshot(currentPicks[0]) : deleteField());
	}, [stage]);

	return <Form><SimpleForm gap={1}>
		<Row xs={1} md={2}>
			<Col>
				<DobControl 
					defaultSelected={dob?.toDate() || null}
					onSelect={(date) => stage.set(MemberPersonalField.DOB, Timestamp.fromDate(date))}
					disabled={disabled}
					required={requires.includes("dob")}
				/>
				<BloodTypeControl 
					defaultValue={bloodType || "__none"}
					onChange={(evt) => stage.set(MemberPersonalField.BLOOD_TYPE, (evt.target.value as BloodType) || deleteField())}
					disabled={disabled}
					required={requires.includes("bloodType")}
				/>
			</Col>
			<Col>
				<SexControl
					defaultValue={sex || "__none"}
					onChange={(evt) => stage.set(MemberPersonalField.SEX, (evt.target.value as Sex) || deleteField())}
					disabled={disabled}
					required={requires.includes("sex")}
				/>
				<FacebookLinkField 
					defaultValue={facebook}
					onDataChanged={setFacebook}
					disabled={disabled}
					required={requires.includes("facebook")}
				/>
			</Col>
		</Row>
		<FormGroup className="mb-3">
			<FormLabel required={requires.includes("institution")}>Institution</FormLabel>
			<InstitutionPicker 
				max={1}
				defaultSelectedItems={defSchool}
				onPicked={setSchool}
				disabled={disabled}
				required={requires.includes("institution")}
			/>
		</FormGroup>
        <Row xs={1} md={2}>
            <Col>
                <GradeControl 
                    defaultValue={grade || "__none"}
                    onChange={evt => stage.set(MemberAcademicField.GRADE, (evt.target.value as Grade) || deleteField())}
                    disabled={disabled}
                    required={requires.includes("grade")}
                />
            </Col>
            <Col>
                <StudyFieldControl 
                    defaultValue={studyFields?.join(", ")}
                    onChange={evt => {
                        const value = evt.currentTarget.value;
                        stage.set(MemberAcademicField.FIELDS, value ? [value] : deleteField())
                    }}
                    disabled={disabled}
                    required={requires.includes("field_of_study")}
                />
            </Col>
        </Row>
		<AddressField
			defaultValue={address}
			onAddressChanged={setAddress}
			disabled={disabled}
			required={requires.includes("address")}
		/>
	</SimpleForm></Form>
}

export interface MemberEditorProps {
	data?: {
        publicData?: MemberPublicData,
        personalData?: MemberPersonalData,
        contactData?: MemberContactData,
        academicData?: MemberAcademicData
    },
	syncData: Member,
	disabled?: boolean,
}