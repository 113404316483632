import { Falsy, formatDate } from "@hashimukh/core-js";
import { useDoc, useUser } from "@hashimukh/firebase-js";
import { Button } from "@hashimukh/stardust";
import { collection, doc } from "firebase/firestore";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Placeholder from "react-bootstrap/Placeholder";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Prompt, Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import { AppPage, PageMeta } from "./AppPage";
import { FancyItem, FancyItemPlaceholder } from "./components/common/FancyItem";
import { FancyList } from "./components/common/FancyList";
import { NameField } from "./components/common/NameField";
import { Shimmer } from "./components/common/Shimmer";
import Avatar, { AvatarPlaceholder } from "./components/member/Avatar";
import { MemberEditor } from "./components/MemberEditor";
import { requires } from "./components/RegistrationControl";
import { RequireSignIn } from "./components/RequireSignIn";
import { InstitutionSnapshotField } from "./db/models/Institution";
import { MemberAcademicData, MemberAcademicField, MEMBER_ACADEMIC_DOC_ID } from "./db/models/member/Academic";
import { MemberContactData, MemberContactField, MEMBER_CONTACT_DOC_ID } from "./db/models/member/Contact";
import { Member, MEMBERS_RESTRICTED_INFO_COLLECTION_GROUP_ID } from "./db/models/member/Member";
import { MemberPersonalData, MemberPersonalField, MEMBER_PERSONAL_DOC_ID } from "./db/models/member/Personal";
import { MemberPublicData, MemberPublicField } from "./db/models/member/Public";
import { friendlyAddress } from "./db/objects/address";
import { AvatarField, generateAvatarUploadFeedback } from "./db/objects/avatar";
import { friendlyBloodTypes } from "./db/objects/bloodtype";
import { EmailField } from "./db/objects/email";
import { friendlyFacebookId } from "./db/objects/facebook";
import { FormOfDonation } from "./db/objects/fundReports";
import { friendlyNumber } from "./db/objects/phoneNumber";
import { getFriendlyPost } from "./db/objects/post";
import { friendlySex } from "./db/objects/sex";
import "./res/styles/profile.scss";
import { getAuthApp } from "./utils/authUtils";
import { getQuantitatives } from "./utils/numbers";
import { useMember } from "./utils/useMember";
import { useRouter } from "./utils/useRouter";
import { useUserClaims } from "./utils/useUserClaims";

const nextMode: Record<ProfileMode, ProfileMode> = {
	view: "edit",
	edit: "applying",
	applying: "view",
}

const OverviewPlaceholder: React.FunctionComponent = () => {
	return <Container className="section"><Row xs={1} md={2}>
		<Col>
			<FancyList>
				<FancyItemPlaceholder />
				<FancyItemPlaceholder />
			</FancyList>
			<hr className="d-md-none" />
		</Col>
		<Col>
			<FancyList heading={<Placeholder className="list-heading" animation="glow">
				<Placeholder xs={3} /> <Placeholder xs={4} />
			</Placeholder>}>
				<FancyItemPlaceholder />
				<FancyItemPlaceholder />
				<FancyItemPlaceholder />
			</FancyList>
		</Col>
	</Row></Container>
}

const Overview: React.FunctionComponent<{
	mid: string | undefined,
	data: MemberPublicData | undefined,
}> = (props) => {
	const { mid, data } = props;

	const joiningDate = data?.[MemberPublicField.JOIN_TIME];
	const refers = data?.[MemberPublicField.REFER_COUNT] ?? 0;
	const funds = data?.[MemberPublicField.FUND_RAISED];
	const cash = (funds?.[FormOfDonation.CASH]?.amount_millis ?? 0) / 1000;
	const clothes = (funds?.[FormOfDonation.CLOTHES]?.amount_millis ?? 0) / 1000;

	return <Container className="section"><Row xs={1} md={2}>
		<Col>
			<FancyList>
				<FancyItem icon="info" primaryText={mid || "---"} secondaryText="Member ID" />
				<FancyItem icon="event" primaryText={joiningDate ? formatDate(joiningDate?.toDate(), "short") : "---"} secondaryText="Joining date" />
			</FancyList>
			<hr className="d-md-none" />
		</Col>
		<Col>
			<FancyList heading="Contribution report">
				<FancyItem icon="group_add" primaryText={`${refers} ${getQuantitatives(refers, "member", "members")}`} secondaryText="Referred" />
				<FancyItem icon="payments" primaryText={`${cash} BDT`} secondaryText="Donated" />
				<FancyItem icon="checkroom" primaryText={`${clothes} ${getQuantitatives(clothes, "cloth", "clothes")}`} secondaryText={"Donated"} />
			</FancyList>
		</Col>
	</Row></Container>;
}

const PersonalInfo: React.FunctionComponent<{ data: MemberPersonalData | undefined }> = ({ data }) => {
	const {
		[MemberPersonalField.DOB]: dob,
		[MemberPersonalField.BLOOD_TYPE]: bloodType,
		[MemberPersonalField.SEX]: sex,
	} = data || {};

	const strSex = sex && friendlySex[sex];
	const strBloodType = bloodType && friendlyBloodTypes[bloodType];

	return <Container className="section"><Row>
		<Col>
			<FancyList>
				{dob && <FancyItem icon="celebration" primaryText={formatDate(dob.toDate())} secondaryText="Date of birth" />}
				{strSex && <FancyItem icon="wc" primaryText={strSex} secondaryText="Sex" />}
				{strBloodType && <FancyItem icon="bloodtype" primaryText={strBloodType} secondaryText="Blood type" />}
			</FancyList>
		</Col>
	</Row>
	</Container>;
}

const ContactInfo: React.FunctionComponent<{ data: MemberContactData | undefined }> = ({ data }) => {
	const {
		[MemberContactField.EMAIL]: email,
		[MemberContactField.PHONE]: phone,
		[MemberContactField.FACEBOOK]: facebook,
		[MemberContactField.ADDRESS]: address,
	} = data || {};

	const strPhone = phone && friendlyNumber(phone);
	const strEmail = email && email[EmailField.ADDRESS];
	const strFacebook = facebook && friendlyFacebookId(facebook);
	const strAddress = address && friendlyAddress(address);

	return <Container className="section"><Row>
		<Col>
			<FancyList>
				<FancyItem icon="phone" primaryText={strPhone || "---"} secondaryText="Phone number" />
				{strEmail && <FancyItem icon="email" primaryText={strEmail} secondaryText="Email address" />}
				{strFacebook && <FancyItem icon="alternate_email" primaryText={strFacebook} secondaryText="Facebook ID" />}
				{strAddress && <FancyItem icon="home" primaryText={strAddress} secondaryText="Home address" />}
			</FancyList>
		</Col>
	</Row>
	</Container>
}

const AcademicInfo: React.FunctionComponent<{ data: MemberAcademicData | undefined }> = ({ data }) => {
	const {
		[MemberAcademicField.INSTITUTION]: school,
		[MemberAcademicField.GRADE]: grade,
		[MemberAcademicField.FIELDS]: studyFields,
	} = data || {};

	const schoolName = school?.[InstitutionSnapshotField.NAME];
	const strStudyFields = studyFields?.join(", ");

	return <Container className="section"><Row>
		<Col>
			<FancyList>
				<FancyItem icon="school" primaryText={schoolName || "---"} secondaryText="Institution" />
				{grade && <FancyItem icon="class" primaryText={grade} secondaryText="Grade" />}
				{strStudyFields && <FancyItem icon="psychology_alt" primaryText={strStudyFields} secondaryText="Field of study" />}
			</FancyList>
		</Col>
	</Row>
	</Container>
}

const ProfileViewPlaceholder: React.FunctionComponent = () => {
	return <div>
        <Container className="px-0">
			<Row xs={1} md={2}>
				<Col xs={12} md={4}>
					<AvatarPlaceholder />
				</Col>
				<Col className="my-md-auto" xs={12} md={8}>
					<Stack className="mt-4 mb-md-4 mt-md-0">
                        <h1 className="mb-2"><Shimmer pattern={[9]} size="lg" /></h1>
                        <p className="mb-2"><Shimmer pattern={[5]} /></p>
					</Stack>
				</Col>
			</Row>
		</Container>
		<Tabs className="mt-4 overflow-x-auto overflow-y-hidden flex-nowrap" defaultActiveKey="overview">
			<Tab eventKey="overview" title="Overview"><OverviewPlaceholder /></Tab>
			<Tab eventKey="contact" title="Contact" disabled><OverviewPlaceholder /></Tab>
			<Tab eventKey="personal" title="Personal" disabled><OverviewPlaceholder /></Tab>
			<Tab eventKey="academic" title="Academic" disabled><OverviewPlaceholder /></Tab>
		</Tabs>
	</div>
}

const CurrentProfile: React.FunctionComponent = () => {
	const router = useRouter();
	const user = useUser(getAuthApp());
	const claims = useUserClaims(user);

	useEffect(() => {
		if (claims?.mid) router.push(`profile/${claims.mid}`);
		else if (claims) router.push("/registration");
	}, [claims, router, user]);

	return <AppPage>
		{user === null ? <RequireSignIn /> : <ProfileViewPlaceholder />}
	</AppPage>
}

export const ProfileView: React.FunctionComponent = () => {
	const stage = useRef(new Member());

	const user = useUser(getAuthApp());
	const userClaims = useUserClaims(user) || {};

	const { mid: _mid } = useParams<{ mid: string | undefined }>();
	const mid = _mid || userClaims.mid;

	const isUser = userClaims.mid === mid;
	const restrictAccess = !userClaims.scopes && !isUser;

	const member = useMember(mid);
	const publicData = member.snapshot?.data();

	const [personalDocRef, contactDocRef, academicDocRef] = useMemo(() => {
		if (!mid) return [undefined, undefined, undefined];

		const memberRef = Member.createRef(mid);
		const infoCollection = collection(memberRef, MEMBERS_RESTRICTED_INFO_COLLECTION_GROUP_ID);
		return [
			doc(infoCollection, MEMBER_PERSONAL_DOC_ID),
			doc(infoCollection, MEMBER_CONTACT_DOC_ID),
			doc(infoCollection, MEMBER_ACADEMIC_DOC_ID),
		];
	}, [mid]);

	const personalDoc = useDoc<MemberPersonalData>(!restrictAccess ? personalDocRef : undefined);
	const contactDoc = useDoc<MemberContactData>(!restrictAccess ? contactDocRef : undefined);
	const academicDoc = useDoc<MemberAcademicData>(!restrictAccess ? academicDocRef : undefined);

	const personalData = personalDoc.snapshot?.data();
	const contactData = contactDoc.snapshot?.data();
	const academicData = academicDoc.snapshot?.data();

	const hasAvatar = publicData?.[MemberPublicField.AVATAR]?.[AvatarField.SHOW];
	const avatarRef = useMemo(() => mid ? Member.getAvatar(mid, "256x256") : undefined, [mid]);

	const [mode, setMode] = useState<"view" | "edit" | "applying">("view");
	const [avatarUrl, setAvatarUrl] = useState<string>();
	const [applyFeedback, setApplyFeedback] = useState<{ message: string | Falsy, variant: string }>();

	const name = publicData?.[MemberPublicField.NAME];
	const rank = getFriendlyPost(publicData?.[MemberPublicField.POST]);

	const handleModeClick = useCallback(() => {
		if (mode === "edit") {
			if (!mid) {
				console.error(`error applying personal data change [cause: mid undefined]`);

				setMode("edit");
				setApplyFeedback({ message: "We ran into a problem.", variant: "danger" });
				return;
			}

			stage.current.apply(mid).then(() => {
				setApplyFeedback({ message: "Profile updated successfully.", variant: "success" });
				setMode("view");
			}).catch((err) => {
				console.error(`error updaing profile [cause: ${err}]`)

				setMode("edit");
				setApplyFeedback({ message: "We ran into an error while updating your profile. Please try again later.", variant: "danger" });
			})
		}

		setApplyFeedback(undefined);
		setMode(c => nextMode[c]);
	}, [mid, mode]);

	const cancelEdit = useCallback(() => {
		stage.current.reset();
		setMode("view");
		setApplyFeedback(undefined);
	}, []);

	const disableEdit = mode === "applying";
	const metas: PageMeta = {
		title: `${name || "Member profile"} - Hashimukh`,
		description: "See your state, and contribution reports.",
		image: avatarUrl,
	}

	return <AppPage metas={metas}>
		<Prompt when={mode === "edit" || mode === "applying"} message="You may have unsaved changes. Click cancel to go back safely." />
		{publicData && <Container className="px-0">
			<Row xs={1} md={2}>
				<Col xs={12} md={4}>
					<Avatar
						reference={avatarRef}
						onLoaded={setAvatarUrl}
						onStatus={(status) => {
							const [variant, message] = generateAvatarUploadFeedback(status);
							if (variant && message) setApplyFeedback({ variant, message });
						}}
						hasAvatar={hasAvatar}
						editable={!restrictAccess}
						required={requires.includes("avatar")}
					/>
				</Col>
				<Col className="my-md-auto" xs={12} md={8}>
					<Stack className="mt-4 mb-md-4 mt-md-0">
                        {mode === "view" ? <>
                            <h1 className="mb-2">{name}</h1>
                            {rank && <p className="mb-2">{rank}</p>}
                        </> : <NameField
                            defaultValue={name}
                            onNameChanged={stage.current.setName}
                            required={requires.includes(MemberPublicField.NAME)}
                            disabled={disableEdit}
                        />}
					</Stack>
				</Col>
			</Row>
			{isUser && <Row className="mt-3" xs={1} md={2}>
				<Col className="order-1 order-md-0 d-none d-md-block" xs={12} md={4}>
					<hr />
				</Col>
				<Col className="hstack gap-3" xs={12} md={8}>
					{mode === "edit" && <Button
						className="w-100"
						variant="secondary"
						onClick={cancelEdit}
					>
						Cancel
					</Button>}
					<Button
						className="w-100"
						variant={mode === "view" ? "secondary" : "primary"}
						onClick={handleModeClick}
						status={mode === "applying" ? "loading" : "none"}
					>
						{mode === "view" ? "Edit" : "Done"}
					</Button>
				</Col>
			</Row>}
		</Container>}
		{applyFeedback && <Alert 
            className="mt-3" 
            variant={applyFeedback.variant}
            onClose={() => setApplyFeedback(undefined)}
            dismissible
        >
            {applyFeedback.message}
        </Alert>}
		{mode === "view" ? (publicData && <Tabs className="mt-4 overflow-x-auto overflow-y-hidden flex-nowrap" defaultActiveKey="overview" variant="tabs">
			<Tab eventKey="overview" title="Overview">
				<Overview mid={mid} data={publicData} />
			</Tab>
			<Tab eventKey="contact" title="Contact" disabled={!contactData}>
				<ContactInfo data={contactData} />
			</Tab>
			<Tab eventKey="personal" title="Personal" disabled={!personalData}>
				<PersonalInfo data={personalData} />
			</Tab>
			<Tab eventKey="academic" title="Academic" disabled={!academicData}>
				<AcademicInfo data={academicData} />
			</Tab>
			{/* todo: add donation list and refer list tabs */}
		</Tabs>) || (member.status === "fetching" && <ProfileViewPlaceholder />) || (member.snapshot && !member.snapshot.exists() && <Alert variant="warning">
			Profile does&apos;nt exist. Please check for typos in your URL.
		</Alert>) || <Alert variant="danger">
				We ran into a problem. Please try again later.
			</Alert> : <><hr className="mt-4" /><MemberEditor
				data={{ academicData, contactData, personalData, publicData }}
				syncData={stage.current}
				disabled={disableEdit}
			/></>}
	</AppPage>
}

export const Profile: React.FunctionComponent = () => {
	const { path } = useRouteMatch();

	return <Switch>
		<Route exact path={path} render={() => <CurrentProfile />} />
		<Route path={`${path}/:mid`} render={() => <ProfileView />} />
	</Switch>
}

type ProfileMode = "view" | "edit" | "applying";