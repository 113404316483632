import { copyToClipboard } from "@hashimukh/core-js";
import React, { useCallback, useState } from "react";
import Button, { ButtonProps } from "react-bootstrap/Button";

const TEXT_COPY = "Copy ID";
const TEXT_COPIED = "Copied";
const TEXT_COPY_FAILED = "Copy failed";

export const CopyButton: React.FunctionComponent<CopyButtonProps> = (props) => {
	const { content, onClick } = props;

	const [text, setText] = useState(TEXT_COPY);
	const copy = useCallback(async (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		onClick?.(evt);
		if (await copyToClipboard(content)) setText(TEXT_COPIED);
		else setText(TEXT_COPY_FAILED);

		await new Promise(res => setTimeout(res, 5000));
		setText(TEXT_COPY);
	}, [content, onClick]);

	return <Button variant="outline-secondary" onClick={copy}>{text}</Button>
}

interface CopyButtonProps extends ButtonProps {
	content: string
}